import { nanoid } from 'nanoid';

import { SubmissionStiFullPrefillOutDto, NaicsOutDto, NcciClassificationOutDto } from '@app/swagger-types';
import { AwsCalcValidationSchema, FullCalcValidationSchemaType } from '../forms/calc.form';
import { OverrideSubmissionStiAwsPrefillOutDto } from '@app/swagger-override-types';

export const formatStiFullPrefilledValues = (
  prefilledValues: SubmissionStiFullPrefillOutDto,
  naics: NaicsOutDto[],
  ncci: NcciClassificationOutDto[]
): FullCalcValidationSchemaType => {
  const naicsItem = naics.find((item) => item.naics === prefilledValues.naics) || null;

  const formatValue = (value: number | null | undefined, decimalScale = 0): string => {
    return value === null || value === undefined ? '' : String(value.toFixed(decimalScale));
  };

  return {
    companyName: '',
    naics: naicsItem,
    emr: prefilledValues?.emr ? formatValue(prefilledValues.emr, 2) : '',
    trir: formatValue(prefilledValues.trir, 2),
    da: formatValue(prefilledValues.dart, 2),
    safety: prefilledValues.safetyPrograms || false,
    health: prefilledValues.healthCoverage || false,
    supplemental: prefilledValues.supplementalCoverage || false,
    stateInputs: prefilledValues.payrollEntries.length
      ? prefilledValues.payrollEntries.map((entry) => ({
          ncci: ncci.find((item) => item.uniqueIdentifier === entry.ncciUniqueId) || null,
          payroll: formatValue(entry.amount, 2),
          stateCode: entry.state || '',
          rowLocalId: nanoid(),
          uniqueIdentifier: ncci.find((item) => item.uniqueIdentifier === entry.ncciUniqueId)?.uniqueIdentifier || null,
        }))
      : [
          {
            ncci: null,
            payroll: '',
            stateCode: '',
            rowLocalId: nanoid(),
            uniqueIdentifier: null,
          },
        ],
  };
};

export const formatStiAwsPrefilledValues = (
  prefilledValues: OverrideSubmissionStiAwsPrefillOutDto,
  ncci: NcciClassificationOutDto[]
): AwsCalcValidationSchema => {
  const formatValue = (value: number | null | undefined, decimalScale = 0): string => {
    return value === null || value === undefined ? '' : String(value.toFixed(decimalScale));
  };

  return {
    companyName: '',
    emr: prefilledValues.emr ? formatValue(prefilledValues.emr, 2) : '',
    yearsInBusiness: formatValue(prefilledValues.yearsInBusiness),
    recentMedicalClaims: formatValue(prefilledValues.recentYear?.medicalClaims),
    recentTotalMedicalLosses: formatValue(prefilledValues.recentYear?.totalMedicalLosses),
    recentIndemnityClaims: formatValue(prefilledValues.recentYear?.indemnityClaims),
    recentTotalIndemnityLosses: formatValue(prefilledValues.recentYear?.totalIndemnityLosses),
    previousMedicalClaims: formatValue(prefilledValues.previousYear?.medicalClaims),
    previousTotalMedicalLosses: formatValue(prefilledValues.previousYear?.totalMedicalLosses),
    previousIndemnityClaims: formatValue(prefilledValues.previousYear?.indemnityClaims),
    previousTotalIndemnityLosses: formatValue(prefilledValues.previousYear?.totalIndemnityLosses),
    olderMedicalClaims: formatValue(prefilledValues.twoYearsAgo?.medicalClaims),
    olderTotalMedicalLosses: formatValue(prefilledValues.twoYearsAgo?.totalMedicalLosses),
    olderIndemnityClaims: formatValue(prefilledValues.twoYearsAgo?.indemnityClaims),
    olderTotalIndemnityLosses: formatValue(prefilledValues.twoYearsAgo?.totalIndemnityLosses),
    stateInputs: prefilledValues.payrollEntries.length
      ? prefilledValues.payrollEntries.map((entry) => ({
          ncci: ncci.find((item) => item.uniqueIdentifier === entry.ncciUniqueId) || null,
          zipCode: entry.zipCode || '',
          payroll: formatValue(entry.amount, 2),
          stateCode: entry.state || '',
          rowLocalId: nanoid(),
          uniqueIdentifier: ncci.find((item) => item.uniqueIdentifier === entry.ncciUniqueId)?.uniqueIdentifier || null,
        }))
      : [
          {
            ncci: null,
            zipCode: '',
            payroll: '',
            stateCode: '',
            rowLocalId: nanoid(),
            uniqueIdentifier: null,
          },
        ],
  };
};
