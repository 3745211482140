import { apiService, validateAndAssert } from '@app/services/api-service';
import {
  PageResponseSubmissionNamedInsuredOutDto,
  SubmissionStiFullPrefillOutDto,
  SubmissionStiFullPrefillInDto,
  PageResponseSubmissionOutDto,
  SubmissionNamedInsuredOutDto,
  SubmissionStiAwsPrefillInDto,
  SubmissionVerificationOutDto,
  SubmissionNamedInsuredInDto,
  SubmissionLossSummaryOutDto,
  SubmissionLossReviewOutDto,
  SubmissionInsightOutDto,
  SubmissionGiCalcOutDto,
  SubmissionGiCalcInDto,
  SubmissionFileOutDto,
  ESubmissionFileType,
  PresignedUrlOutDto,
  SubmissionPatchDto,
  AbstractCalcOutDto,
  SubmissionOutDto,
  SubmissionInDto,
  EOutcomeType,
  SubmissionRiskFactorsOutDto,
  SubmissionRiskFactorInDto,
  RiskFactorOutDto,
  SubmissionOverviewOutDto,
  SubmissionOverviewInDto,
  SubmissionLossSummaryInDto,
} from '@app/swagger-types';
import { PaginationQueryParams } from '@app/types/query-params.types';
import { pageableData } from '@app/utils/zod.utils';
import {
  SubmissionStiFullPrefillOutDtoSchema,
  SubmissionStiAwsPrefillOutDtoSchema,
  SubmissionVerificationOutDtoSchema,
  SubmissionLossSummaryOutDtoSchema,
  SubmissionRiskFactorsOutDtoSchema,
  SubmissionLossReviewOutDtoSchema,
  SubmissionNamedInsuredDtoSchema,
  SubmissionInsightsOutDtoSchema,
  SubmissionGiCalcOutDtoSchema,
  SubmissionFileOutDtoSchema,
  PresignedUrlOutDtoSchema,
  SubmissionOutDtoSchema,
  RiskFactorsOutDtoSchema,
  SubmissionOverviewOutDtoSchema,
  SubmissionNotesInDtoSchema,
  SubmissionNotesOutDtoSchema,
  SubmissionFileOutDtoNullableStatusAndResultFilesSchema,
} from '../schemas/submission.schema';
import {
  FullCalcOutDtoResponseSchema,
  AbstractCalcOutDtoSchema,
  AwsCalcOutDtoResSchema,
} from '@app/domain/sti/schemes/sti.schemes';
import {
  FullCalcOutDtoResponse,
  AwsCalcOutDtoResponse,
  FullCalcInDto,
  AwsCalcInDto,
} from '@app/domain/sti/types/calc.types';
import { OverrideSubmissionFileOutDto, OverrideSubmissionStiAwsPrefillOutDto } from '@app/swagger-override-types';
import { z } from 'zod';
import { StiParams } from '@app/domain/sti/api/sti-api';
import { Block } from '@blocknote/core';

export interface UploadSubmissionWorkbookInDto {
  submissionId: string;
  formData: FormData;
}

export interface SubmissionFileInDto {
  submissionId: string | number;
  fileType: ESubmissionFileType;
  formData: FormData;
}
export interface ReplaceSubmissionFileInDto {
  submissionId: string | number;
  fileId: string | number;
  formData: FormData;
}

export interface BaseSubmissionFileInDto {
  submissionId: string | number;
  fileId: number;
}

export interface GetSubmissionFileParams {
  fileId: number;
  filename: string;
}

export interface SubmissionGiCalcParams {
  id?: number;
  effectiveDate?: string;
}

export interface SubmissionOutcomeParams {
  outcomeType: EOutcomeType;
}

export interface SubmissionsParams extends PaginationQueryParams {
  assignee?: string;
}

export type CheckSubmissionDuplicateParams = Pick<SubmissionInDto, 'namedInsuredId' | 'effectiveDate'>;

export abstract class SubmissionApi {
  static async getSubmissions(params: SubmissionsParams) {
    return validateAndAssert(
      apiService.get<PageResponseSubmissionOutDto>('/private/submissions', {
        params,
      }),
      pageableData(SubmissionOutDtoSchema)
    );
  }
  static async editSubmission(submissionId: string, dto: SubmissionPatchDto) {
    return validateAndAssert(
      apiService.patch<SubmissionOutDto>(`/private/submissions/${submissionId}`, dto),
      SubmissionOutDtoSchema
    );
  }
  static async editSubmissionLossSummary(submissionId: string, dto: SubmissionLossSummaryInDto) {
    return validateAndAssert(
      apiService.put<SubmissionLossSummaryOutDto>(`/private/submissions/${submissionId}/loss-summary`, dto),
      SubmissionLossSummaryOutDtoSchema
    );
  }
  static async getNamedInsureds(params: PaginationQueryParams) {
    return validateAndAssert(
      apiService.get<PageResponseSubmissionNamedInsuredOutDto>('/private/submissions/named-insured', {
        params,
      }),
      pageableData(SubmissionNamedInsuredDtoSchema)
    );
  }
  static async createSubmission(dto: SubmissionInDto) {
    return validateAndAssert(apiService.post<SubmissionOutDto>('/private/submissions', dto), SubmissionOutDtoSchema);
  }
  static async getSubmissionById(id: string) {
    return validateAndAssert(apiService.get<SubmissionOutDto>(`/private/submissions/${id}`), SubmissionOutDtoSchema);
  }
  static async createNamedInsured(dto: SubmissionNamedInsuredInDto) {
    return validateAndAssert(
      apiService.post<SubmissionNamedInsuredOutDto>('/private/submissions/named-insured', dto),
      SubmissionNamedInsuredDtoSchema
    );
  }
  static async uploadFileToSubmission({ submissionId, fileType, formData }: SubmissionFileInDto) {
    return validateAndAssert(
      apiService.post<OverrideSubmissionFileOutDto, FormData>(`/private/submissions/${submissionId}/upload`, formData, {
        params: { fileType },
      }),
      SubmissionFileOutDtoNullableStatusAndResultFilesSchema
    );
  }
  static async reuploadFileToSubmission({ submissionId, fileId, formData }: ReplaceSubmissionFileInDto) {
    return await apiService.put(`/private/submissions/${submissionId}/replace-file`, formData, { params: { fileId } });
  }
  static async getSubmissionFileById({ submissionId, fileId }: BaseSubmissionFileInDto) {
    return await validateAndAssert(
      apiService.get<SubmissionFileOutDto>(`/private/submissions/${submissionId}/file`, {
        params: { fileId },
      }),
      SubmissionFileOutDtoSchema
    );
  }
  static async deleteFileToSubmission({ submissionId, fileId }: BaseSubmissionFileInDto) {
    return await apiService.delete(`/private/submissions/${submissionId}/file`, { params: { fileId } });
  }
  static async getSubmissionLossReview(id: string) {
    return validateAndAssert(
      apiService.get<SubmissionLossReviewOutDto>(`/private/submissions/${id}/loss-review`, {
        params: { skipNotFoundToast: true },
      }),
      SubmissionLossReviewOutDtoSchema
    );
  }
  static async getSubmissionLossSummary(id: string) {
    return validateAndAssert(
      apiService.get<SubmissionLossSummaryOutDto>(`/private/submissions/${id}/loss-summary`, {
        params: { skipNotFoundToast: true },
      }),
      SubmissionLossSummaryOutDtoSchema
    );
  }
  static async getSubmissionDownloadUrl(submissionId: string | number) {
    return validateAndAssert(
      apiService.get<PresignedUrlOutDto>(`/private/submissions/${submissionId}/data`),
      PresignedUrlOutDtoSchema
    );
  }
  static async uploadSubmissionWorkbook({ submissionId, formData }: UploadSubmissionWorkbookInDto) {
    return await apiService.put(`/private/submissions/${submissionId}/data`, formData);
  }
  static async getFileDownloadUrl(params: GetSubmissionFileParams) {
    return validateAndAssert(
      apiService.get<PresignedUrlOutDto>(`/private/submissions/download-file`, { params }),
      PresignedUrlOutDtoSchema
    );
  }
  static async calculateSubmissionBySti(
    submissionId: string,
    Dto: FullCalcInDto,
    { originalStates, ...params }: StiParams = {}
  ) {
    return validateAndAssert(
      apiService.patch<FullCalcOutDtoResponse, FullCalcInDto>(
        `/private/submissions/${submissionId}/full_calc`,
        { ...Dto, originalStates },
        {
          params,
        }
      ),
      FullCalcOutDtoResponseSchema
    );
  }
  static async calculateSubmissionByAws(submissionId: string, Dto: AwsCalcInDto, version?: string) {
    return validateAndAssert(
      apiService.patch<AwsCalcOutDtoResponse, AwsCalcInDto>(`/private/submissions/${submissionId}/aws_calc`, Dto, {
        params: { version },
      }),
      AwsCalcOutDtoResSchema
    );
  }
  static async getSubmissionStiFullPrefill(id: string) {
    return validateAndAssert(
      apiService.get<SubmissionStiFullPrefillOutDto>(`/private/submissions/${id}/prefill/full`),
      z.union([SubmissionStiFullPrefillOutDtoSchema, z.string()])
    );
  }
  static async getSubmissionStiAwsPrefill(id: string) {
    return validateAndAssert(
      apiService.get<OverrideSubmissionStiAwsPrefillOutDto>(`/private/submissions/${id}/prefill/aws`),
      z.union([SubmissionStiAwsPrefillOutDtoSchema, z.string()])
    );
  }
  static async updateSubmissionStiFullPrefill(id: string, dto: SubmissionStiFullPrefillInDto) {
    return validateAndAssert(
      apiService.put<SubmissionStiFullPrefillOutDto, SubmissionStiFullPrefillInDto>(
        `/private/submissions/${id}/prefill/full`,
        dto
      ),
      SubmissionStiFullPrefillOutDtoSchema
    );
  }
  static async updateSubmissionStiAwsPrefill(id: string, dto: SubmissionStiAwsPrefillInDto) {
    return validateAndAssert(
      apiService.put<OverrideSubmissionStiAwsPrefillOutDto, SubmissionStiAwsPrefillInDto>(
        `/private/submissions/${id}/prefill/aws`,
        dto
      ),
      SubmissionStiAwsPrefillOutDtoSchema
    );
  }
  static async getSubmissionStiOutcome(id: string, params: SubmissionOutcomeParams) {
    return validateAndAssert(
      apiService.get<AbstractCalcOutDto>(`/private/submissions/${id}/outcome`, { params }),
      AbstractCalcOutDtoSchema
    );
  }
  static async calculateGi(dto: SubmissionGiCalcInDto, params?: SubmissionGiCalcParams) {
    return validateAndAssert(
      apiService.patch<SubmissionGiCalcOutDto, SubmissionGiCalcInDto>('/private/submissions/gi_calc', dto, { params }),
      SubmissionGiCalcOutDtoSchema
    );
  }
  static async generateSubmissionInsights(id: string) {
    return validateAndAssert(
      apiService.post<SubmissionInsightOutDto>(`/private/submissions/${id}/insights`),
      SubmissionInsightsOutDtoSchema
    );
  }
  static async getSubmissionInsights(id: string) {
    return validateAndAssert(
      apiService.get<SubmissionInsightOutDto>(`/private/submissions/${id}/insights`, {
        params: { skipNotFoundToast: true },
      }),
      SubmissionInsightsOutDtoSchema
    );
  }
  static async getSubmissionVerified(id: string) {
    return validateAndAssert(
      apiService.get<SubmissionVerificationOutDto>(`/private/submissions/${id}/verified`),
      SubmissionVerificationOutDtoSchema
    );
  }
  static async checkSubmissionDuplicate(params: CheckSubmissionDuplicateParams) {
    return validateAndAssert(apiService.get<boolean>(`/private/submissions/duplicate`, { params }), z.boolean());
  }
  static async getRiskFactorsBySubmissionId(submissionId: string) {
    return validateAndAssert(
      apiService.get<SubmissionRiskFactorsOutDto>(`/private/submissions/${submissionId}/risk-factors`),
      SubmissionRiskFactorsOutDtoSchema
    );
  }
  static async updateSubmissionRiskFactors(id: string, dto: SubmissionRiskFactorInDto[]) {
    return validateAndAssert(
      apiService.patch<SubmissionRiskFactorsOutDto, SubmissionRiskFactorInDto[]>(
        `/private/submissions/${id}/risk-factors`,
        dto
      ),
      SubmissionRiskFactorsOutDtoSchema
    );
  }
  static async getAllSubmissionRiskFactors() {
    return validateAndAssert(
      apiService.get<RiskFactorOutDto[]>('/private/submissions/risk-factors'),
      RiskFactorsOutDtoSchema
    );
  }
  static async getSubmissionNotes(id: string) {
    return validateAndAssert(
      apiService.get<Block[] | string>(`/private/submissions/${id}/notes`),
      SubmissionNotesOutDtoSchema
    );
  }
  static async updateSubmissionNotes(id: string, dto: string) {
    return validateAndAssert(
      apiService.put<Block[], string>(`/private/submissions/${id}/notes`, dto, {
        headers: { 'Content-Type': 'application/json' },
      }),
      SubmissionNotesInDtoSchema
    );
  }
  static async getSubmissionOverview(submissionId: string) {
    return validateAndAssert(
      apiService.get<SubmissionOverviewOutDto>(`/private/submissions/${submissionId}/overview`),
      SubmissionOverviewOutDtoSchema
    );
  }
  static async updateSubmissionOverview(submissionId: string, dto: SubmissionOverviewInDto) {
    return validateAndAssert(
      apiService.patch<SubmissionOverviewOutDto, SubmissionOverviewInDto>(
        `/private/submissions/${submissionId}/overview`,
        dto
      ),
      SubmissionOverviewOutDtoSchema
    );
  }
}
