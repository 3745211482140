import { FC } from 'react';
import { Skeleton } from '@mui/material';

import { SubmissionOverviewEstimatedAnnualPayroll } from '../components/SubmissionOverviewEstimatedAnnualPayroll';
import { useGetSubmissionByIdQuery, useGetSubmissionOverviewQuery } from '../api/submission.api.hooks';
import { useSubmissionCalculatorFormsContext } from '../contexts/SubmissionCalculatorFormsContext';
import { SubmissionOverviewDetails } from '../components/SubmissionOverviewDetails';
import { SubmissionOverviewProfile } from '../components/SubmissionOverviewProfile';
import { SubmissionStiAwsPrefillInDto, SubmissionStiFullPrefillInDto } from '@app/swagger-types';

interface Props {
  submissionId: string;
  awsPrefillValues: Omit<SubmissionStiAwsPrefillInDto, 'payrollEntries'>;
  fullPrefillValues: Omit<SubmissionStiFullPrefillInDto, 'payrollEntries'>;
}

export const SubmissionOverviewTab: FC<Props> = ({ submissionId, awsPrefillValues, fullPrefillValues }) => {
  const { data, isLoading: isSubmissionLoading } = useGetSubmissionByIdQuery(submissionId);

  const { data: overviewData, isLoading: isOverviewLoading } = useGetSubmissionOverviewQuery(submissionId);

  const { isNaicsLoading } = useSubmissionCalculatorFormsContext();

  const isLoading = isOverviewLoading || isSubmissionLoading || isNaicsLoading;

  return (
    <div className="grid grid-cols-[295px_1fr] gap-6">
      {isLoading && !overviewData ? (
        <>
          <Skeleton variant="rounded" width="100%" height={638} />
          <div className="flex flex-col gap-6">
            <Skeleton variant="rounded" width="100%" height={430} />
            <Skeleton variant="rounded" width="100%" height={200} />
          </div>
        </>
      ) : (
        <>
          <SubmissionOverviewDetails overviewData={overviewData} data={data} submissionId={submissionId} />
          <div className="flex flex-col gap-6">
            <SubmissionOverviewProfile overviewData={overviewData} submissionId={submissionId} />
            <SubmissionOverviewEstimatedAnnualPayroll
              fullPrefillValues={fullPrefillValues}
              isStandard={Boolean(data?.isStandard)}
              awsPrefillValues={awsPrefillValues}
              submissionId={submissionId}
              overviewData={overviewData}
            />
          </div>
        </>
      )}
    </div>
  );
};
