import { PropsWithChildren, ReactNode, FC, useMemo, useState } from 'react';
import { Typography } from '@mui/material';

import { useSubmissionCalculatorFormsContext } from '@app/domain/submission/contexts/SubmissionCalculatorFormsContext';
import { BackButtonProps } from '@app/components/buttons/BackButton';
import { Crumb } from '@app/components/breadcrumbs/Breadcrumbs';
import { useDimensions } from '@app/hooks/useDimensions';
import { Header } from '@app/layout/header/Header';
import { isString } from '@app/utils/guard.util';
import { clsxm } from '@app/styles/clsxm';
import styles from './Page.module.scss';

interface Props {
  title?: ReactNode;
  className?: string;
  headerClassName?: string;
  contentClassName?: string;
  wrapperClassName?: string;
  titleClassName?: string;
  breadcrumbs?: Crumb[];
  disableOverflow?: boolean;
  whiteBg?: boolean;
  subHeaderClassName?: string;
  subHeaderContent?: ReactNode;
  withOverflow?: boolean;
  withHeader?: boolean;
  responsiveExpansion?: boolean;
  backButton?: BackButtonProps;
}

export const Page: FC<PropsWithChildren<Props>> = ({
  title,
  children,
  className,
  headerClassName,
  contentClassName,
  wrapperClassName,
  disableOverflow,
  titleClassName,
  breadcrumbs,
  whiteBg,
  subHeaderClassName,
  subHeaderContent,
  withHeader = true,
  withOverflow = true,
  responsiveExpansion,
  backButton,
}) => {
  const [pageNode, setPageNode] = useState<null | HTMLDivElement>(null);
  const { setPageNode: setPageNodeElement } = useSubmissionCalculatorFormsContext();

  const { width } = useDimensions(pageNode);

  const horizontalPadding = useMemo(() => {
    if (width < 1400) {
      return 'px-10';
    }

    return 'px-[4.5rem]';
  }, [width]);

  const isFullMaxWidth = useMemo(() => width > 1768 && responsiveExpansion, [responsiveExpansion, width]);

  return (
    <div
      className={clsxm(
        'relative flex size-full flex-col bg-neutral-50',
        disableOverflow && styles['disable-overflow'],
        withOverflow && 'overflow-auto',
        whiteBg && 'bg-white',
        wrapperClassName
      )}
      ref={(node) => {
        if (node) {
          setPageNode(node);
          setPageNodeElement?.(node);
        }
      }}
      id="page"
    >
      <div className={clsxm(styles['page-root'], isFullMaxWidth && 'max-w-full', className)}>
        {withHeader && (
          <div className="sticky top-0 z-[11]">
            <Header
              backButton={backButton}
              breadcrumbs={breadcrumbs}
              className={clsxm(whiteBg && 'bg-white', horizontalPadding, headerClassName)}
            />
          </div>
        )}
        {subHeaderContent && (
          <div
            className={clsxm(styles['subheader-content'], whiteBg && 'bg-white', horizontalPadding, subHeaderClassName)}
          >
            {subHeaderContent}
          </div>
        )}
        <div className={clsxm(styles['page-content'], whiteBg && 'bg-white', horizontalPadding, contentClassName)}>
          {title ? (
            isString(title) ? (
              <Typography variant="h1" className={clsxm('text-5xl font-normal', titleClassName)}>
                {title}
              </Typography>
            ) : (
              title
            )
          ) : null}
          {children}
        </div>
      </div>
    </div>
  );
};
