import { Typography } from '@mui/material';
import { FC } from 'react';

import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { SearchIcon, FileIcon, DatePickerIcon, PdfIcon, CsvIcon, NotAssignedIcon } from '@app/assets';
import { UIStyledSection } from '../components/UIStyledSection';

export const UISectionIcons: FC = () => {
  return (
    <UIStyledSection title="Icons">
      <div className="flex flex-col gap-6">
        <div className="grid w-96 grid-cols-2 gap-2">
          <Typography>Search Icon:</Typography>
          <SearchIcon />
        </div>
        <div className="grid w-96 grid-cols-2 gap-2">
          <Typography>File Icon:</Typography>
          <FileIcon />
        </div>
        <div className="grid w-96 grid-cols-2 gap-2">
          <Typography>DatePicker Icon:</Typography>
          <DatePickerIcon />
        </div>
        <div className="grid w-96 grid-cols-2 gap-2">
          <Typography>Pdf Icon:</Typography>
          <PdfIcon />
        </div>
        <div className="grid w-96 grid-cols-2 gap-2">
          <Typography>Csv Icon:</Typography>
          <CsvIcon />
        </div>
        <div className="grid w-96 grid-cols-2 gap-2">
          <Typography>Not Assigned Icon:</Typography>
          <NotAssignedIcon />
        </div>
        <div className="grid w-96 grid-cols-2 gap-2">
          <Typography>AI Icon:</Typography>
          <AutoAwesomeIcon />
        </div>
      </div>
    </UIStyledSection>
  );
};
