import { FC, memo } from 'react';
import { CircularProgress } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

import { clsxm } from '@app/styles/clsxm';

interface Props {
  className?: string;
}

export const AiLoader: FC<Props> = memo(({ className }) => {
  return (
    <div className={clsxm('relative mx-auto flex size-10 items-center justify-center p-4', className)}>
      <CircularProgress className="absolute inset-0 text-secondary" />
      <AutoAwesomeIcon />
    </div>
  );
});
