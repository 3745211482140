export function reorderArrayItem<T>(array: T[], currentIndex: number, newIndex: number): T[] {
  if (currentIndex < 0 || currentIndex >= array.length || newIndex < 0 || newIndex >= array.length) {
    // Invalid indices, return the original array
    return array;
  }

  const updatedArray = [...array];
  const [movedItem] = updatedArray.splice(currentIndex, 1);
  updatedArray.splice(newIndex, 0, movedItem);

  return updatedArray;
}

export function splitArray<T>(array: T[], firstLength: number, restLength: number): T[][] {
  const firstArray = array.slice(0, firstLength);
  const restArrays = Array.from({ length: Math.ceil((array.length - firstLength) / restLength) }, (_, index) =>
    array.slice(index * restLength + firstLength, (index + 1) * restLength + firstLength)
  );
  return [firstArray, ...restArrays];
}
