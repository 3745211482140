import AlignHorizontalLeft from '@mui/icons-material/AlignHorizontalLeft';
import EventNote from '@mui/icons-material/EventNote';
import { ButtonBase, Card, Skeleton, Typography } from '@mui/material';
import { format } from 'date-fns';
import { add, orderBy } from 'lodash';
import { FC, useMemo, useRef, useState } from 'react';

import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import { Table, TableColumn } from '@app/components/table';
import { FORMAT } from '@app/constants/formats';
import { clsxm } from '@app/styles/clsxm';
import { SubmissionLossSummaryOutDto, SubmissionLossSummaryTotalYearOutDto } from '@app/swagger-types';
import { buildSortParam, parseSortParam } from '@app/utils/api.util';
import { renderDollarAmount } from '@app/utils/currency/currency.utils';
import { convertUTCToLocalDate } from '@app/utils/date.utils';
import { LossSummaryPopover } from './LossSummaryPopover';
import { SubmissionLossSummarySwitcher } from './SubmissionLossSummarySwitcher';
import { useTrirDartCalculations } from '../hooks/useTrirDartCalculations';
import { Tooltip } from '@app/components/tooltip/Tooltip';

type VerifyLossRunDataRowType = SubmissionLossSummaryTotalYearOutDto & {
  isTotal?: boolean;
  hasOpenClaims?: boolean;
};

const hasOpenClaims = ({
  medicalClaimsOpenNum,
  indemnityClaimsOpenNum,
  reportOnlyClaimsOpenNum,
  numberOfClaimsOpen,
}: Pick<
  VerifyLossRunDataRowType,
  'medicalClaimsOpenNum' | 'indemnityClaimsOpenNum' | 'reportOnlyClaimsOpenNum' | 'numberOfClaimsOpen'
>) => Boolean(medicalClaimsOpenNum || indemnityClaimsOpenNum || reportOnlyClaimsOpenNum || numberOfClaimsOpen);

const showOpenClaims = ({
  isTotal,
  medicalClaimsOpenNum,
  indemnityClaimsOpenNum,
  reportOnlyClaimsOpenNum,
  numberOfClaimsOpen,
}: Pick<
  VerifyLossRunDataRowType,
  'isTotal' | 'medicalClaimsOpenNum' | 'indemnityClaimsOpenNum' | 'reportOnlyClaimsOpenNum' | 'numberOfClaimsOpen'
>) => {
  return (
    isTotal ||
    hasOpenClaims({ medicalClaimsOpenNum, indemnityClaimsOpenNum, reportOnlyClaimsOpenNum, numberOfClaimsOpen })
  );
};

const DEFAULT_SORT = buildSortParam<VerifyLossRunDataRowType>('policyTermStart', 'desc');

interface Props {
  submissionId: string;
  lossSummaryData: SubmissionLossSummaryOutDto | undefined;
  isFetching?: boolean;
}

export const LossSummaryTable: FC<Props> = ({ lossSummaryData, isFetching, submissionId }) => {
  const [sort, setSort] = useState(DEFAULT_SORT);
  const [open, setOpen] = useState(false);

  const popoverAnchorRef = useRef<HTMLDivElement | null>(null);

  const { averageDart, averageTrir } = useTrirDartCalculations(lossSummaryData);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const cols: TableColumn<VerifyLossRunDataRowType>[] = useMemo(
    () => [
      {
        headerName: 'Policy Term',
        field: 'policyTermStart',
        align: 'left',
        width: 150,
        isExpandable: (entity) => showOpenClaims(entity),
        render: ({ policyTermStart, policyTermEnd, isTotal }) => {
          return (
            <div className="flex items-start">
              <div className="flex min-w-[120px] flex-col gap-1 text-right text-xxs font-normal">
                <span className={clsxm('text-xs', isTotal && 'font-semibold')}>
                  {isTotal
                    ? 'Totals'
                    : `${format(convertUTCToLocalDate(policyTermStart), FORMAT.YEAR)} - 
                     ${format(convertUTCToLocalDate(policyTermEnd), FORMAT.YEAR)}`}
                </span>
              </div>
            </div>
          );
        },
        renderCollapsedContent: ({ isTotal, hasOpenClaims, ...rest }) => {
          const showClaimsInfo = showOpenClaims({ isTotal, ...rest });

          return showClaimsInfo ? (
            <div className="flex items-start">
              <div className="flex min-w-[120px] flex-col gap-1 text-right text-xxs font-normal">
                <span className="text-black/40">{isTotal ? 'Total Closed Claims' : 'Closed Claims'}</span>
                <span className={clsxm(hasOpenClaims ? 'text-[#D32F2F]' : 'text-black/40')}>
                  {isTotal ? 'Total Open Claims' : 'Open Claims'}
                </span>
              </div>
            </div>
          ) : null;
        },
      },
      {
        headerName: 'Medical Claims',
        field: 'medicalClaimsOpenNum',
        align: 'center',
        disableSort: true,
        headCellClassName: 'px-10',
        bodyCellClassName: 'px-10',
        width: 180,
        headerJSX: (
          <div className="flex flex-col gap-2 p-1">
            <Typography className="text-center text-m font-medium">Medical Claims</Typography>
            <div className="flex items-center justify-between gap-2 text-xs">
              <Typography className="text-left">#</Typography>
              <Typography className="text-right text-xxs">Total Incurred</Typography>
            </div>
          </div>
        ),
        isExpandable: (entity) => showOpenClaims(entity),
        render: ({
          medicalClaimsOpenNum,
          medicalClaimsClosedNum,
          medicalClaimsOpenValue,
          medicalClaimsClosedValue,
        }) => {
          return (
            <div className="flex justify-between gap-2 p-1">
              <div className="flex flex-col gap-1 text-left text-xxs">
                <span className="text-xs font-semibold">{add(medicalClaimsOpenNum, medicalClaimsClosedNum)}</span>
              </div>
              <div className="flex flex-col gap-1 text-right text-xxs">
                <span className="text-xs">
                  {renderDollarAmount(add(medicalClaimsOpenValue, medicalClaimsClosedValue))}
                </span>
              </div>
            </div>
          );
        },
        renderCollapsedContent: ({
          medicalClaimsOpenNum,
          medicalClaimsClosedNum,
          medicalClaimsOpenValue,
          medicalClaimsClosedValue,
          ...rest
        }) => {
          const showClaimsInfo = showOpenClaims({ medicalClaimsOpenNum, ...rest });

          return showClaimsInfo ? (
            <div className="flex justify-between gap-2 p-1">
              <div className="flex flex-col gap-1 text-left text-xxs">
                <span className="text-black/40">{medicalClaimsClosedNum}</span>
                <span className={clsxm(medicalClaimsOpenNum ? 'text-[#D32F2F]' : 'text-black/40')}>
                  {medicalClaimsOpenNum}
                </span>
              </div>
              <div className="flex flex-col gap-1 text-right text-xxs">
                <span className="text-black/40">{renderDollarAmount(medicalClaimsClosedValue)}</span>
                <span className={clsxm(medicalClaimsOpenValue ? 'text-[#D32F2F]' : 'text-black/40')}>
                  {renderDollarAmount(medicalClaimsOpenValue)}
                </span>
              </div>
            </div>
          ) : null;
        },
      },
      {
        headerName: 'Indemnity Claims',
        field: 'medicalClaimsOpenNum',
        align: 'center',
        disableSort: true,
        headCellClassName: 'px-10',
        bodyCellClassName: 'px-10',
        width: 180,
        headerJSX: (
          <div className="flex flex-col gap-2 p-1">
            <Typography className="text-center text-m font-medium">Indemnity Claims</Typography>
            <div className="flex items-center justify-between gap-2 text-xs">
              <Typography className="text-left">#</Typography>
              <Typography className="text-right text-xxs">Total Incurred</Typography>
            </div>
          </div>
        ),
        isExpandable: (entity) => showOpenClaims(entity),
        render: ({
          indemnityClaimsOpenNum,
          indemnityClaimsClosedNum,
          indemnityClaimsOpenValue,
          indemnityClaimsClosedValue,
        }) => {
          return (
            <div className="flex justify-between gap-2 p-1">
              <div className="flex flex-col gap-1 text-left text-xxs">
                <span className="text-xs font-semibold">{add(indemnityClaimsOpenNum, indemnityClaimsClosedNum)}</span>
              </div>
              <div className="flex flex-col gap-1 text-right text-xxs">
                <span className="text-xs">
                  {renderDollarAmount(add(indemnityClaimsOpenValue, indemnityClaimsClosedValue))}
                </span>
              </div>
            </div>
          );
        },
        renderCollapsedContent: ({
          indemnityClaimsOpenNum,
          indemnityClaimsClosedNum,
          indemnityClaimsOpenValue,
          indemnityClaimsClosedValue,
          ...rest
        }) => {
          const showClaimsInfo = showOpenClaims({ indemnityClaimsOpenNum, ...rest });

          return showClaimsInfo ? (
            <div className="flex justify-between gap-2 p-1">
              <div className="flex flex-col gap-1 text-left text-xxs">
                <span className="text-black/40">{indemnityClaimsClosedNum}</span>
                <span className={clsxm(indemnityClaimsOpenNum ? 'text-[#D32F2F]' : 'text-black/40')}>
                  {indemnityClaimsOpenNum}
                </span>
              </div>
              <div className="flex flex-col gap-1 text-right text-xxs">
                <span className="text-black/40">{renderDollarAmount(indemnityClaimsClosedValue)}</span>
                <span className={clsxm(indemnityClaimsOpenValue ? 'text-[#D32F2F]' : 'text-black/40')}>
                  {renderDollarAmount(indemnityClaimsOpenValue)}
                </span>
              </div>
            </div>
          ) : null;
        },
      },

      {
        headerName: 'Report Only Claims',
        field: 'reportOnlyClaimsOpenNum',
        align: 'center',
        disableSort: true,
        width: 100,
        headCellClassName: 'px-0',
        bodyCellClassName: 'px-0',
        headerJSX: (
          <div className="flex flex-col gap-2 p-1">
            <Typography className="text-center text-m font-medium">Report Only Claims</Typography>
            <Typography className="text-center text-xs">#</Typography>
          </div>
        ),
        isExpandable: (entity) => showOpenClaims(entity),
        render: ({ reportOnlyClaimsOpenNum, reportOnlyClaimsClosedNum }) => {
          return (
            <div className="flex flex-col gap-1 text-center text-xxs">
              <span className="text-xs font-semibold">{add(reportOnlyClaimsOpenNum, reportOnlyClaimsClosedNum)}</span>
            </div>
          );
        },
        renderCollapsedContent: ({ reportOnlyClaimsOpenNum, reportOnlyClaimsClosedNum, ...rest }) => {
          const showClaimsInfo = showOpenClaims({ reportOnlyClaimsOpenNum, ...rest });

          return showClaimsInfo ? (
            <div className="flex flex-col gap-1 text-center text-xxs">
              <span className="text-black/40">{reportOnlyClaimsClosedNum}</span>
              <span className={clsxm(reportOnlyClaimsOpenNum ? 'text-[#D32F2F]' : 'text-black/40')}>
                {reportOnlyClaimsOpenNum}
              </span>
            </div>
          ) : null;
        },
      },
      {
        headerName: 'Total Claims',
        field: 'numberOfClaimsOpen',
        align: 'center',
        disableSort: true,
        headCellClassName: 'pl-10',
        bodyCellClassName: 'pl-10',
        width: 180,
        headerJSX: (
          <div className="flex flex-col gap-2 p-1">
            <Typography className="text-center text-m font-medium">Total Claims</Typography>
            <div className="flex items-center justify-between gap-2 text-xs">
              <Typography className="text-left">#</Typography>
              <Typography className="min-w-24 text-right text-xxs">Total Incurred</Typography>
              <Typography className="min-w-24 text-right text-xxs">Total Reserves</Typography>
            </div>
          </div>
        ),
        isExpandable: (entity) => showOpenClaims(entity),
        render: ({
          numberOfClaimsOpen,
          numberOfClaimsClosed,
          totalIncurredOpen,
          totalIncurredClosed,
          totalReservesOpen,
          totalReservesClosed,
        }) => {
          return (
            <div className="flex justify-between gap-2 p-1">
              <div className="flex flex-col gap-1 text-left text-xxs">
                <span className="text-xs font-semibold">{add(numberOfClaimsOpen, numberOfClaimsClosed)}</span>
              </div>
              <div className="flex min-w-24 flex-col gap-1 text-right text-xxs">
                <span className="text-xs">{renderDollarAmount(add(totalIncurredOpen, totalIncurredClosed))}</span>
              </div>
              <div className="flex min-w-24 flex-col gap-1 text-right text-xxs">
                <span className="text-xs">{renderDollarAmount(add(totalReservesOpen, totalReservesClosed))}</span>
              </div>
            </div>
          );
        },
        renderCollapsedContent: ({
          numberOfClaimsOpen,
          numberOfClaimsClosed,
          totalIncurredOpen,
          totalIncurredClosed,
          totalReservesOpen,
          totalReservesClosed,
          ...rest
        }) => {
          const showClaimsInfo = showOpenClaims({ numberOfClaimsOpen, ...rest });

          return showClaimsInfo ? (
            <div className="flex justify-between gap-2 p-1">
              <div className="flex flex-col gap-1 text-left text-xxs">
                <span className="text-black/40">{numberOfClaimsClosed}</span>
                <span className={clsxm(numberOfClaimsOpen ? 'text-[#D32F2F]' : 'text-black/40')}>
                  {numberOfClaimsOpen}
                </span>
              </div>
              <div className="flex min-w-24 flex-col gap-1 text-right text-xxs">
                <span className="text-black/40">{renderDollarAmount(totalIncurredClosed)}</span>
                <span className={clsxm(totalIncurredOpen ? 'text-[#D32F2F]' : 'text-black/40')}>
                  {renderDollarAmount(totalIncurredOpen)}
                </span>
              </div>
              <div className="flex min-w-24 flex-col gap-1 text-right text-xxs">
                <span className="text-black/40">{renderDollarAmount(totalReservesClosed)}</span>
                <span className={clsxm(totalReservesOpen ? 'text-[#D32F2F]' : 'text-black/40')}>
                  {renderDollarAmount(totalReservesOpen)}
                </span>
              </div>
            </div>
          ) : null;
        },
      },
    ],
    []
  );

  const tableData: VerifyLossRunDataRowType[] = useMemo(() => {
    let data = lossSummaryData?.total.totalYears || [];

    const sortParam = sort.length ? parseSortParam(sort) : null;

    const mapHasOpenClaimsValue = (row: VerifyLossRunDataRowType) => ({
      ...row,
      hasOpenClaims: hasOpenClaims(row),
    });

    if (sortParam?.field && sortParam.order) {
      data = orderBy(data, [sortParam?.field], [sortParam.order]);
    }

    if (data.length) {
      return [
        ...data,
        // TOTAL Row
        {
          isTotal: true,
          policyTermStart: '',
          policyTermEnd: '',
          medicalClaimsOpenNum: lossSummaryData?.total.totalMedicalClaimsOpenNum ?? 0,
          medicalClaimsOpenValue: lossSummaryData?.total.totalMedicalClaimsOpenValue ?? 0,
          medicalClaimsClosedNum: lossSummaryData?.total.totalMedicalClaimsClosedNum ?? 0,
          medicalClaimsClosedValue: lossSummaryData?.total.totalMedicalClaimsClosedValue ?? 0,
          numberOfClaimsOpen: lossSummaryData?.total.numberOfClaimsOpen ?? 0,
          numberOfClaimsClosed: lossSummaryData?.total.numberOfClaimsClosed ?? 0,
          totalIncurredOpen: lossSummaryData?.total.totalIncurredOpen ?? 0,
          totalIncurredClosed: lossSummaryData?.total.totalIncurredClosed ?? 0,
          totalReservesOpen: lossSummaryData?.total.totalReservesOpen ?? 0,
          totalReservesClosed: lossSummaryData?.total.totalReservesClosed ?? 0,
          indemnityClaimsOpenNum: lossSummaryData?.total.totalIndemnityClaimsOpenNum ?? 0,
          indemnityClaimsOpenValue: lossSummaryData?.total.totalIndemnityClaimsOpenValue ?? 0,
          indemnityClaimsClosedNum: lossSummaryData?.total.totalIndemnityClaimsClosedNum ?? 0,
          indemnityClaimsClosedValue: lossSummaryData?.total.totalIndemnityClaimsClosedValue ?? 0,
          reportOnlyClaimsOpenNum: lossSummaryData?.total.totalReportOnlyClaimsOpenNum ?? 0,
          reportOnlyClaimsClosedNum: lossSummaryData?.total.totalReportOnlyClaimsClosedNum ?? 0,
        },
      ].map(mapHasOpenClaimsValue);
    }

    return data.map(mapHasOpenClaimsValue);
  }, [lossSummaryData, sort]);

  const totalPayroll = lossSummaryData?.totalPayroll || 0;

  return (
    <>
      <Card className="flex w-full flex-col gap-2.5 rounded-lg bg-white p-6 shadow-card">
        <div className="flex items-center justify-between gap-3">
          <div className="flex items-center gap-16">
            <Typography className="text-nowrap py-4 text-xxxl">Loss Summary</Typography>
            <SubmissionLossSummarySwitcher />
          </div>
          <div className="flex items-center gap-8">
            {!lossSummaryData && isFetching ? (
              <Skeleton height={44} width={150} variant="rectangular" className="rounded" />
            ) : (
              <div className="flex items-center gap-2.5">
                <ButtonBase
                  onClick={handleClick}
                  disabled={!lossSummaryData}
                  className="flex items-center gap-2 rounded-3xl bg-black/10 py-0.5 pl-1.5 pr-2.5 hover:bg-black/20"
                >
                  <AlignHorizontalLeft className="rounded-full bg-black fill-white p-1" />
                  <Typography className="text-nowrap text-4xl">
                    {totalPayroll ? averageTrir.toFixed(2) : '-.--'}
                  </Typography>
                </ButtonBase>
                <Typography className="text-xxs uppercase text-black/90">TRIR</Typography>
              </div>
            )}
            {!lossSummaryData && isFetching ? (
              <Skeleton height={44} width={150} variant="rectangular" className="rounded" />
            ) : (
              <div ref={popoverAnchorRef} className="flex items-center gap-2.5">
                <ButtonBase
                  onClick={handleClick}
                  disabled={!lossSummaryData}
                  className="flex items-center gap-2 rounded-3xl bg-black/10 py-0.5 pl-1.5 pr-2.5 hover:bg-black/20"
                >
                  <EventNote className="rounded-full bg-black fill-white p-1" />
                  <Typography className="text-nowrap text-4xl">
                    {totalPayroll ? averageDart.toFixed(2) : '-.--'}
                  </Typography>
                </ButtonBase>
                <Typography className="text-xxs uppercase text-black/90">DART</Typography>
              </div>
            )}
          </div>
        </div>
        <Table
          cols={cols}
          tableData={tableData}
          loading={isFetching}
          hidePageSize
          wrapperClassName="w-full"
          currentSort={sort}
          onSortChanged={setSort}
          renderExpandableIcon={(isExpanded: boolean, { hasOpenClaims, isTotal }) => (
            <Tooltip
              title={isTotal ? 'Open claims in the policy terms.' : 'Open claims in this period.'}
              text="Expand for details."
              titleClassName="text-xxs text-center"
              textClassName="text-xxs text-center pt-0"
              disabled={isExpanded}
              classes={{ tooltip: 'py-0' }}
            >
              <div
                className={clsxm(
                  'flex size-5 items-center justify-center rounded-full border-[3px]',
                  hasOpenClaims ? 'border-[#D32F2F]' : ' border-transparent'
                )}
              >
                {isExpanded ? <ArrowDropUp className="w-4" /> : <ArrowDropDown className="w-4" />}
              </div>
            </Tooltip>
          )}
        />
      </Card>
      <LossSummaryPopover
        open={open}
        anchorEl={popoverAnchorRef.current}
        onClose={handleClose}
        lossSummaryData={lossSummaryData}
        submissionId={submissionId}
      />
    </>
  );
};
