import { addDays, differenceInDays, endOfDay, isBefore } from 'date-fns';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { FORMAT } from '@app/constants/formats';
import { convertLocalToUTCDate, convertUTCToLocalDate, getMaxDate } from '@app/utils/date.utils';
import { Button } from '../buttons/button/Button';
import { DatePicker, DatePickerProps } from '../date-picker/DatePicker';
import { DateInput } from '../month-picker-range/DateInput';
import { DatePatternInput } from '../month-picker-range/DatePatternInput';

export type ISORange = { startDate?: string; endDate?: string };

type PropTypes = Pick<DatePickerProps, 'maxDate' | 'minDate'> & {
  formatDate?: string;
  appliedDate?: Partial<ISORange>;
  onPeriodChange?: (args: ISORange) => void;
  calendarClassName?: string;
  contentClassName?: string;
  disabled?: boolean;
  onConfirm?: (args: ISORange) => void;
  onReset?: () => void;
  allowPastDates?: boolean;
  focusOnOpen?: boolean;
  datePatternFormat?: string;
};

const TODAY_DATE = new Date();
const TOMORROW_DATE = addDays(new Date(), 1);

export const DatePickerRange: React.FC<PropTypes> = ({
  formatDate = FORMAT.DATE_API,
  appliedDate,
  calendarClassName,
  contentClassName,
  onPeriodChange,
  onConfirm,
  disabled,
  minDate,
  maxDate,
  onReset,
  allowPastDates = true,
  focusOnOpen,
  datePatternFormat,
}) => {
  const startDateInputRef = useRef<HTMLInputElement>(null);

  const [showStartDate, setShowStartDate] = useState(true);
  const [startDate, setStartDate] = useState(appliedDate?.startDate ? new Date(appliedDate.startDate) : undefined);
  const [endDate, setEndDate] = useState(appliedDate?.endDate ? new Date(appliedDate.endDate) : undefined);

  const localStartDate = startDate ? convertUTCToLocalDate(startDate) : startDate;
  const localEndDate = endDate ? convertUTCToLocalDate(endDate) : endDate;

  const handleControlsChange = (showStart: boolean) => () => {
    setShowStartDate(showStart);
  };

  const handlePeriodChange = (startDate?: Date | undefined, endDate?: Date | undefined) => {
    if (startDate && endDate && onPeriodChange) {
      onPeriodChange({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      });
    }
  };

  const handleConfirm = (startDate?: Date | undefined, endDate?: Date | undefined) => {
    if (startDate && endDate && onConfirm) {
      onConfirm({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      });
    }
  };

  const handleEndToday = () => {
    const utcTodayDate = convertLocalToUTCDate(TODAY_DATE);
    setEndDate(utcTodayDate);
    setShowStartDate(true);

    handlePeriodChange(startDate, utcTodayDate);
  };

  const handleClear = useCallback(() => {
    onReset?.();
    setStartDate(undefined);
    setEndDate(undefined);
  }, [onReset]);

  useEffect(() => {
    if (!appliedDate?.startDate) {
      setStartDate(undefined);
    }
    if (!appliedDate?.endDate) {
      setEndDate(undefined);
      setShowStartDate(true);
    }
  }, [appliedDate]);

  const minStartDate = useMemo(
    () => (allowPastDates ? minDate : getMaxDate(minDate || TOMORROW_DATE, TOMORROW_DATE)),
    [allowPastDates, minDate]
  );

  const minEndDate = useMemo(() => {
    if (localStartDate && !differenceInDays(localStartDate, TODAY_DATE)) {
      return allowPastDates ? localStartDate : TODAY_DATE;
    }
    return allowPastDates
      ? localStartDate || minDate
      : getMaxDate(localStartDate || minDate || TOMORROW_DATE, TOMORROW_DATE);
  }, [allowPastDates, minDate, localStartDate]);

  useEffect(() => {
    if (focusOnOpen) {
      setTimeout(() => {
        startDateInputRef.current?.focus();
      }, 1);
    }
  }, [focusOnOpen]);

  const header = (
    <div className="mb-6 grid w-full grid-cols-2 justify-between gap-3">
      {/* TODO: create reusable component to render input depends 'datePatternFormat' prop */}
      {datePatternFormat ? (
        <DatePatternInput
          ref={startDateInputRef}
          dateFormat={formatDate}
          dateValue={localStartDate ?? null}
          active={showStartDate}
          onChangeData={(date) => {
            const utcDate = convertLocalToUTCDate(date);
            setStartDate(utcDate);
            if (localEndDate && isBefore(localEndDate, date)) {
              setEndDate(undefined);
            }

            handlePeriodChange(utcDate, endDate);
          }}
          placeholder={formatDate.toUpperCase()}
          label="From"
          disabled={disabled}
          onFocus={handleControlsChange(true)}
          size="small"
          minDate={minStartDate}
          maxDate={localEndDate}
          datePatternFormat={datePatternFormat}
        />
      ) : (
        <DateInput
          ref={startDateInputRef}
          dateFormat={formatDate}
          dateValue={localStartDate ?? null}
          active={showStartDate}
          onChangeData={(date) => {
            const utcDate = convertLocalToUTCDate(date);
            setStartDate(utcDate);
            if (localEndDate && isBefore(localEndDate, date)) {
              setEndDate(undefined);
            }

            handlePeriodChange(utcDate, endDate);
          }}
          variant="outlined"
          color="secondary"
          placeholder={formatDate.toUpperCase()}
          label="From"
          disabled={disabled}
          onFocus={handleControlsChange(true)}
          size="small"
          minDate={minStartDate}
          maxDate={localEndDate}
        />
      )}
      {datePatternFormat ? (
        <DatePatternInput
          dateFormat={formatDate}
          dateValue={localEndDate ?? null}
          active={!showStartDate}
          onChangeData={(date) => {
            const utcDate = endOfDay(convertLocalToUTCDate(date)) || date;

            if (localStartDate && isBefore(date, localStartDate)) {
              setStartDate(undefined);
            }

            setEndDate(utcDate);
            handlePeriodChange(startDate, utcDate);
          }}
          placeholder={formatDate.toUpperCase()}
          label="To"
          disabled={disabled}
          onFocus={handleControlsChange(false)}
          size="small"
          minDate={minEndDate}
          maxDate={maxDate}
          datePatternFormat={datePatternFormat}
        />
      ) : (
        <DateInput
          dateFormat={formatDate}
          dateValue={localEndDate ?? null}
          active={!showStartDate}
          onChangeData={(date) => {
            const utcDate = endOfDay(convertLocalToUTCDate(date)) || date;

            if (localStartDate && isBefore(date, localStartDate)) {
              setStartDate(undefined);
            }

            setEndDate(utcDate);
            handlePeriodChange(startDate, utcDate);
          }}
          variant="outlined"
          color="secondary"
          placeholder={formatDate.toUpperCase()}
          label="To"
          disabled={disabled}
          onFocus={handleControlsChange(false)}
          size="small"
          minDate={minEndDate}
          maxDate={maxDate}
        />
      )}
    </div>
  );

  const footer = (
    <div className="flex w-full justify-between">
      <Button variant="text" size="small" className="!border-0 p-2" onClick={handleEndToday}>
        End Today
      </Button>
      <div>
        <Button variant="text" size="small" className="!border-0 p-2" onClick={handleClear}>
          Clear
        </Button>
        <Button
          variant="text"
          size="small"
          className="text-orange !border-0 p-2"
          disabled={!startDate || !endDate}
          onClick={() => handleConfirm(startDate, endDate)}
        >
          Set
        </Button>
      </div>
    </div>
  );

  return (
    <div className={contentClassName}>
      <div>
        {showStartDate ? (
          <DatePicker
            header={header}
            footer={footer}
            selected={localStartDate}
            calendarClassName={calendarClassName}
            selectsStart
            startDate={localStartDate}
            endDate={localEndDate}
            minDate={minStartDate}
            maxDate={localEndDate}
            onChange={(date) => {
              const utcDate = convertLocalToUTCDate(date);
              setStartDate(utcDate);
              if (localEndDate && isBefore(localEndDate, date)) {
                setEndDate(undefined);
              }
              setShowStartDate(false);

              handlePeriodChange(utcDate, endDate);
            }}
          />
        ) : (
          <DatePicker
            header={header}
            footer={footer}
            selected={localEndDate}
            calendarClassName={calendarClassName}
            selectsEnd
            startDate={localStartDate}
            endDate={localEndDate}
            minDate={minEndDate}
            maxDate={maxDate}
            onChange={(date) => {
              const utcDate = endOfDay(convertLocalToUTCDate(date)) || date;

              if (localStartDate && isBefore(date, localStartDate)) {
                setStartDate(undefined);
              }
              setShowStartDate(true);

              setEndDate(utcDate);
              handlePeriodChange(startDate, utcDate);
            }}
          />
        )}
      </div>
    </div>
  );
};
