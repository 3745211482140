import { createContext, FC, ReactNode, useContext, useMemo, useState } from 'react';

const SubmissionNotesContext = createContext({
  notes: '',
  setNotes: (_: string) => {},
});

interface Props {
  children: ReactNode;
}

export const SubmissionNotesProvider: FC<Props> = ({ children }) => {
  const [notes, setNotes] = useState('');

  const value = useMemo(
    () => ({
      notes,
      setNotes,
    }),
    [notes, setNotes]
  );

  return <SubmissionNotesContext.Provider value={value}>{children}</SubmissionNotesContext.Provider>;
};

export const useSubmissionLossSummaryContext = () => useContext(SubmissionNotesContext);
