import { Routes } from '@app/constants/routes';
import { TypedLink } from '@app/router';
import { Typography } from '@mui/material';
import { Outlet } from 'react-router-dom';

export const UIKitModule: React.FC = () => {
  return (
    <div className="px-4 py-2">
      <div className="relative p-6 text-center">
        {/* TODO fix navigation - cannot go back to UI Kit after navigating to homepage */}
        <TypedLink
          to={Routes.index}
          className="absolute mb-4 block w-fit rounded bg-zinc-700 p-2 text-center text-white hover:bg-primary"
        >
          Go Home Page
        </TypedLink>
        <Typography className="text-xxxl font-bold"> UI Kit</Typography>
      </div>
      <div className="main-container">
        <Outlet />
      </div>
    </div>
  );
};
