import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';

import {
  SubmissionVerificationOutDto,
  SubmissionNamedInsuredOutDto,
  ESubmissionClaimStatus,
  ESubmissionFileStatus,
  ESubmissionStatus,
  ESubmissionRiskFactor,
  SubmissionStiPayrollEntryOutDto,
  NcciClassificationOutDto,
  ESubmissionFileType,
} from '@app/swagger-types';
import { SubmissionLossReviewVerifiedColumns } from '../components/VerifyLossRunDataTable';
import { CircularProgress } from '@app/components/CircularProgress';
import { SubmissionOverviewPayrollEntriesSchemaType } from '../schemas/submission.form';

export const SubmissionUtils = {
  getStatusColor: (status: ESubmissionStatus) => {
    switch (status) {
      case ESubmissionStatus.DRAFT:
        return '#737373';
      case ESubmissionStatus.EXTRACTING:
      case ESubmissionStatus.EXTRACTING_PARTIAL:
        return '#0288D1';
      case ESubmissionStatus.EXTRACTING_COMPLETED:
        return '#2E7D32';
      case ESubmissionStatus.EXTRACTING_ERROR:
        return '#D32F2F';
      default:
        return '#737373';
    }
  },
  getFriendlyStatusLabel: (status: ESubmissionStatus) => {
    switch (status) {
      case ESubmissionStatus.DRAFT:
        return 'Draft';
      case ESubmissionStatus.EXTRACTING:
        return 'Extracting';
      case ESubmissionStatus.EXTRACTING_PARTIAL:
        return 'Partial Extraction';
      case ESubmissionStatus.EXTRACTING_ERROR:
        return 'Extraction Error';
      case ESubmissionStatus.EXTRACTING_COMPLETED:
        return 'Extraction Complete';
      default:
        return 'Unknown';
    }
  },
  getFriendlyFileStatusLabel: (status: ESubmissionFileStatus) => {
    switch (status) {
      case ESubmissionFileStatus.INITIAL:
        return 'Uploading';
      case ESubmissionFileStatus.PROCESSING:
        return 'Preparing Document';
      case ESubmissionFileStatus.POLLING_FOR_RESULT:
        return 'Extracting Data';
      case ESubmissionFileStatus.DOWNLOADING_RESPONSE:
      case ESubmissionFileStatus.CHECKING_RESPONSE:
        return 'Processing Results';
      case ESubmissionFileStatus.UPDATING_RESULTS:
        return 'Updating Workbook';
      case ESubmissionFileStatus.PROCESSING_FAILED:
        return 'File Not Supported';
      case ESubmissionFileStatus.ERROR:
        return 'System Error';
      case ESubmissionFileStatus.DONE:
        return 'Done';
      default:
        return 'Unknown';
    }
  },
  getFriendlySubmissionClaimStatusLabel: (status: ESubmissionClaimStatus | string) => {
    switch (status) {
      case ESubmissionClaimStatus.OPEN:
        return 'Open';
      case ESubmissionClaimStatus.CLOSED:
        return 'Closed';
      default:
        return 'Unrecognized';
    }
  },
  // getFriendlySubmissionClaimTypeLabel: (type: ESubmissionClaimType | string) => {
  //   switch (type) {
  //     case ESubmissionClaimType.INDEMNITY:
  //       return 'Indemnity';
  //     case ESubmissionClaimType.MEDICAL:
  //       return 'Medical';
  //     case ESubmissionClaimType.REPORT_ONLY:
  //       return 'Report Only';
  //     default:
  //       return type;
  //   }
  // },
  getFileStatusIcon: (status: ESubmissionFileStatus) => {
    switch (status) {
      case ESubmissionFileStatus.DONE:
        return <CheckCircleIcon className="size-5 fill-green-600" />;
      case ESubmissionFileStatus.ERROR:
        return <InfoIcon className="size-5 fill-red-500" />;
      case ESubmissionFileStatus.PROCESSING:
      case ESubmissionFileStatus.POLLING_FOR_RESULT:
      case ESubmissionFileStatus.DOWNLOADING_RESPONSE:
      case ESubmissionFileStatus.CHECKING_RESPONSE:
      case ESubmissionFileStatus.UPDATING_RESULTS:
        return <CircularProgress size={16} />;
      case ESubmissionFileStatus.PROCESSING_FAILED:
        return <WarningAmberIcon className="size-5 fill-red-500" />;
      default:
        return null;
    }
  },
  getIsAtLeastOneVerified: (dto?: SubmissionVerificationOutDto) => {
    if (!dto) {
      return false;
    }

    return dto.carriers.some((carrier) => {
      return (
        carrier.totalClaimCountVerified ||
        carrier.totalExpensesVerified ||
        carrier.totalIncurredVerified ||
        carrier.totalIndemnityVerified ||
        carrier.totalMedicalVerified ||
        carrier.totalReservesVerified
      );
    });
  },
  getIfCarrierVerified: (field: SubmissionLossReviewVerifiedColumns, dto?: SubmissionVerificationOutDto) => {
    if (dto?.carriers?.every((carrier) => carrier?.totalClaimCountVerified && field === 'numberOfClaims')) {
      return true;
    } else if (dto?.carriers?.every((carrier) => carrier?.totalExpensesVerified && field === 'totalExpense')) {
      return true;
    } else if (dto?.carriers?.every((carrier) => carrier?.totalIncurredVerified && field === 'totalIncurred')) {
      return true;
    } else if (dto?.carriers?.every((carrier) => carrier?.totalIndemnityVerified && field === 'totalIndemnity')) {
      return true;
    } else if (dto?.carriers?.every((carrier) => carrier?.totalMedicalVerified && field === 'totalMedical')) {
      return true;
    } else if (dto?.carriers?.every((carrier) => carrier?.totalReservesVerified && field === 'totalReserves')) {
      return true;
    }

    return false;
  },
  renderNamedInsuredLabel: (option: SubmissionNamedInsuredOutDto) =>
    `${option.name}${option.fein ? ` (${option.fein})` : ''}`,
  getFriendlyESubmissionRiskFactorLabel: (value: ESubmissionRiskFactor) => {
    switch (value) {
      case ESubmissionRiskFactor.NOT_APPLICABLE:
        return 'Not Applicable';
      case ESubmissionRiskFactor.CONTEMPLATED_IN_CLASS_CODE:
        return 'Contemplated in Class Code';
      case ESubmissionRiskFactor.ABNORMAL_HAZARD_PRESENT:
        return 'Abnormal Hazards';
    }
  },
  normalizeOperationStates: (states: string) => {
    return states.replace(/,\s*/g, ', '); // Ensures a space after each comma (TX,FL -> TX, FL)
  },
  renderPayrollEntryLabel: (option: SubmissionStiPayrollEntryOutDto) =>
    `${option.state} ${option.ncci} - ${option.phraseology ? option.phraseology : ''}`,

  getIsMatchedNcci: (classCode: string, phraseology: string, ncciItems?: NcciClassificationOutDto[]) => {
    const matchedNcci = ncciItems?.find(
      (ncciItem) => ncciItem.ncci === String(classCode) && ncciItem.phraseology === phraseology
    );

    return matchedNcci;
  },
  formatPayrollEntries: (formValues: SubmissionOverviewPayrollEntriesSchemaType) => {
    return formValues.stateInputs.map(({ stateCode, ncci, payroll }) => ({
      state: stateCode,
      ncci: ncci?.ncci || undefined,
      amount: Number(payroll),
      ncciUniqueId: ncci?.uniqueIdentifier,
      phraseology: ncci?.phraseology,
    }));
  },
  getDeleteFileDialogTitle: (fileType: ESubmissionFileType) => {
    switch (fileType) {
      case ESubmissionFileType.ACCORD:
        return 'Delete Acord 130 Application and Remove Data';
      case ESubmissionFileType.EMR:
        return 'Delete Ex Mod Worksheet and Remove Data';
      case ESubmissionFileType.LOSS_RUNS:
        return 'Delete Loss Run Report and Remove Data';
      default:
        return '';
    }
  },
  getDeleteFileDialogContent: (fileType: ESubmissionFileType) => {
    switch (fileType) {
      case ESubmissionFileType.ACCORD:
        return 'Deleting this Acord 130 Application will remove associated data including the Estimated Annual Payroll, Governing Class, Hazard Grade, NAICS Code, States of Operation, Domiciled State, and Agency information.';
      case ESubmissionFileType.EMR:
        return 'Deleting this Ex Mod Worksheet will remove associated data including the EMR and historical payroll.';
      case ESubmissionFileType.LOSS_RUNS:
        return 'Deleting this Loss Run will remove associated data including ALL claims that were extracted from this document.';
      default:
        return '';
    }
  },
  getReplaceFileDialogErrorContent: (fileType?: ESubmissionFileType) => {
    switch (fileType) {
      case ESubmissionFileType.ACCORD:
        return "Oops that document didn't work; ensure that the replacement file is an Acord 130 Application";
      case ESubmissionFileType.EMR:
        return "Oops that file didn't work; ensure that the replacement file is an Ex Mod Worksheet";
      case ESubmissionFileType.LOSS_RUNS:
        return "Oops that file didn't work; ensure that the replacement file is a supported loss run.";
      default:
        return '';
    }
  },
};
