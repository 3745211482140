import { Typography, Box } from '@mui/material';
import { capitalize } from 'lodash';
import { FC } from 'react';

import { CircularProgress } from '@app/components/CircularProgress';

type PropsTypes = {
  entity?: string;
  isEmpty?: boolean;
  loading?: boolean;
  height?: string | number;
  width?: string | number;
};
export const ContentLoader: FC<PropsTypes> = ({ entity, isEmpty, loading, height, width }) => {
  if (!loading && !isEmpty) {
    return null;
  }

  return (
    <Box flex={1} display="flex" justifyContent="center" alignItems="center" height={height} width={width}>
      {loading ? (
        <CircularProgress />
      ) : (
        isEmpty && (
          <Typography variant="h5" className="highlight-zinc-200">
            {capitalize(entity)} Not Found
          </Typography>
        )
      )}
    </Box>
  );
};
