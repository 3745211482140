import { createContext, FC, ReactNode, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';

import { useHandler } from '@app/hooks/useHandler.hook';

import { DEFAULT_LOSS_RATIO_FILTER_VALUES } from '../const/loss-ratio.filter.const';
import { useLossRatioFiltersLocalStorage } from '../hooks/useLossRatioFiltersLocalStorage';
import { useSharedLossRatioFiltersLocalStorage } from '../hooks/useSharedLossRatioFiltersLocalStorage';
import { useFilterIdQueryParam } from '@app/domain/claim/hooks/useFilterIdQueryParam';
import { LossRatioFilter } from '@app/swagger-types';

const DEFAULT_HAS_CHANGES_BY_FIELD: Record<keyof Omit<LossRatioFilter, 'groupBy'>, boolean> = {
  agencies: false,
  ncci: false,
  effectiveDateStart: false,
  effectiveDateEnd: false,
  bindingMonthStart: false,
  bindingMonthEnd: false,
  states: false,
  policyNumbers: false,
  insuredNames: false,
  hazardGrades: false,
  statuses: false,
  policyTypes: false,
  deductibleValues: false,
};

const LossRatioFiltersContext = createContext({
  countChanges: 0,
  hasChanges: false,
  filters: DEFAULT_LOSS_RATIO_FILTER_VALUES,
  setFilters: (_: LossRatioFilter) => {},
  sharedFilters: DEFAULT_LOSS_RATIO_FILTER_VALUES,
  setSharedFilters: (_: LossRatioFilter) => {},
  filtersDto: {} as LossRatioFilter,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChangeFilterParam: (key: keyof LossRatioFilter, _: LossRatioFilter[typeof key]) => {},
  onChangeFilterParams: (_: Partial<LossRatioFilter>) => {},
  onResetFilters: () => {},
  onSubmitFilterParams: (_: LossRatioFilter) => {},
  expanded: false,
  setExpanded: (_: SetStateAction<boolean>) => {},
  hasChangesByField: DEFAULT_HAS_CHANGES_BY_FIELD,
});

interface Props {
  children: ReactNode;
}

export const LossRatioFiltersContextProvider: FC<Props> = ({ children }) => {
  const [expanded, setExpanded] = useState(false);
  const [filterIdParam, setFilterIdParam] = useFilterIdQueryParam();
  const [sharedFilters, setSharedFilters] = useSharedLossRatioFiltersLocalStorage();
  const [filters, setFilters] = useLossRatioFiltersLocalStorage();

  const visibleFilters: LossRatioFilter = useMemo(
    () => (filterIdParam ? sharedFilters : filters) || DEFAULT_LOSS_RATIO_FILTER_VALUES,
    [filterIdParam, sharedFilters, filters]
  );

  const filtersDto: LossRatioFilter = useMemo(() => visibleFilters, [visibleFilters]);

  const removeFilterIdParam = useHandler(() => {
    setFilterIdParam(undefined, 'pushIn');
  });

  const onChangeFilterParam = useHandler((key: keyof LossRatioFilter, value: LossRatioFilter[typeof key]) => {
    setFilters({ ...visibleFilters, [key]: value });
    removeFilterIdParam();
  });

  const onChangeFilterParams = useHandler((override: Partial<LossRatioFilter>) => {
    setFilters({ ...visibleFilters, ...override });
    removeFilterIdParam();
  });

  const onResetFilters = useHandler(() => {
    setFilters(DEFAULT_LOSS_RATIO_FILTER_VALUES);
    removeFilterIdParam();
  });

  const onSubmitFilterParams = useHandler((values: LossRatioFilter) => {
    setFilters(values);
    removeFilterIdParam();
  });

  const countChanges = useMemo(() => {
    return [
      Boolean(JSON.stringify(filtersDto.agencies) !== JSON.stringify(DEFAULT_LOSS_RATIO_FILTER_VALUES.agencies)),
      Boolean(JSON.stringify(filtersDto.ncci) !== JSON.stringify(DEFAULT_LOSS_RATIO_FILTER_VALUES.ncci)),
      Boolean(filtersDto.effectiveDateStart !== DEFAULT_LOSS_RATIO_FILTER_VALUES.effectiveDateStart),
      Boolean(filtersDto.effectiveDateEnd !== DEFAULT_LOSS_RATIO_FILTER_VALUES.effectiveDateEnd),
      Boolean(filtersDto.bindingMonthStart !== DEFAULT_LOSS_RATIO_FILTER_VALUES.bindingMonthStart),
      Boolean(filtersDto.bindingMonthEnd !== DEFAULT_LOSS_RATIO_FILTER_VALUES.bindingMonthEnd),
      Boolean(JSON.stringify(filtersDto.states) !== JSON.stringify(DEFAULT_LOSS_RATIO_FILTER_VALUES.states)),
      Boolean(
        JSON.stringify(filtersDto.policyNumbers) !== JSON.stringify(DEFAULT_LOSS_RATIO_FILTER_VALUES.policyNumbers)
      ),
      Boolean(
        JSON.stringify(filtersDto.insuredNames) !== JSON.stringify(DEFAULT_LOSS_RATIO_FILTER_VALUES.insuredNames)
      ),
      Boolean(
        JSON.stringify(filtersDto.hazardGrades) !== JSON.stringify(DEFAULT_LOSS_RATIO_FILTER_VALUES.hazardGrades)
      ),
      Boolean(JSON.stringify(filtersDto.statuses) !== JSON.stringify(DEFAULT_LOSS_RATIO_FILTER_VALUES.statuses)),
      Boolean(JSON.stringify(filtersDto.policyTypes) !== JSON.stringify(DEFAULT_LOSS_RATIO_FILTER_VALUES.policyTypes)),
      Boolean(
        JSON.stringify(filtersDto.deductibleValues) !==
          JSON.stringify(DEFAULT_LOSS_RATIO_FILTER_VALUES.deductibleValues)
      ),
    ].filter(Boolean).length;
  }, [filtersDto]);

  const hasChangesByField: Record<keyof Omit<LossRatioFilter, 'groupBy'>, boolean> = useMemo(() => {
    return {
      agencies: Boolean(
        JSON.stringify(filtersDto.agencies) !== JSON.stringify(DEFAULT_LOSS_RATIO_FILTER_VALUES.agencies)
      ),
      ncci: Boolean(JSON.stringify(filtersDto.ncci) !== JSON.stringify(DEFAULT_LOSS_RATIO_FILTER_VALUES.ncci)),
      effectiveDateStart: Boolean(
        filtersDto.effectiveDateStart !== DEFAULT_LOSS_RATIO_FILTER_VALUES.effectiveDateStart
      ),
      effectiveDateEnd: Boolean(filtersDto.effectiveDateEnd !== DEFAULT_LOSS_RATIO_FILTER_VALUES.effectiveDateEnd),
      bindingMonthStart: Boolean(filtersDto.bindingMonthStart !== DEFAULT_LOSS_RATIO_FILTER_VALUES.bindingMonthStart),
      bindingMonthEnd: Boolean(filtersDto.bindingMonthEnd !== DEFAULT_LOSS_RATIO_FILTER_VALUES.bindingMonthEnd),
      states: Boolean(JSON.stringify(filtersDto.states) !== JSON.stringify(DEFAULT_LOSS_RATIO_FILTER_VALUES.states)),
      policyNumbers: Boolean(
        JSON.stringify(filtersDto.policyNumbers) !== JSON.stringify(DEFAULT_LOSS_RATIO_FILTER_VALUES.policyNumbers)
      ),
      insuredNames: Boolean(
        JSON.stringify(filtersDto.insuredNames) !== JSON.stringify(DEFAULT_LOSS_RATIO_FILTER_VALUES.insuredNames)
      ),
      hazardGrades: Boolean(
        JSON.stringify(filtersDto.hazardGrades) !== JSON.stringify(DEFAULT_LOSS_RATIO_FILTER_VALUES.hazardGrades)
      ),
      statuses: Boolean(
        JSON.stringify(filtersDto.statuses) !== JSON.stringify(DEFAULT_LOSS_RATIO_FILTER_VALUES.statuses)
      ),
      policyTypes: Boolean(
        JSON.stringify(filtersDto.policyTypes) !== JSON.stringify(DEFAULT_LOSS_RATIO_FILTER_VALUES.policyTypes)
      ),
      deductibleValues: Boolean(
        JSON.stringify(filtersDto.deductibleValues) !==
          JSON.stringify(DEFAULT_LOSS_RATIO_FILTER_VALUES.deductibleValues)
      ),
    };
  }, [filtersDto]);

  // open by default if has filters
  useEffect(() => {
    setExpanded(Boolean(countChanges));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(
    () => ({
      countChanges,
      hasChanges: Boolean(countChanges),
      filters: visibleFilters,
      sharedFilters,
      setFilters,
      filtersDto,
      onChangeFilterParam,
      onResetFilters,
      onSubmitFilterParams,
      setSharedFilters,
      onChangeFilterParams,
      expanded,
      setExpanded,
      hasChangesByField,
    }),
    [
      countChanges,
      setFilters,
      filtersDto,
      onChangeFilterParam,
      onResetFilters,
      onSubmitFilterParams,
      setSharedFilters,
      visibleFilters,
      sharedFilters,
      onChangeFilterParams,
      expanded,
      setExpanded,
      hasChangesByField,
    ]
  );

  return <LossRatioFiltersContext.Provider value={value}>{children}</LossRatioFiltersContext.Provider>;
};

export const useLossRatioFiltersContext = () => useContext(LossRatioFiltersContext);
