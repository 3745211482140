import { FC } from 'react';
import { Skeleton } from '@mui/material';

import { useGetSubmissionLossReviewQuery, useGetSubmissionLossSummaryQuery } from '../api/submission.api.hooks';
import { ESubmissionLossSummaryTab, useSubmissionLossSummaryContext } from '../contexts/SubmissionLossSummaryContext';
import { SubmissionLossSummaryByLossRun } from './SubmissionLossSummaryByLossRun';
import { SubmissionLossSummaryByPeriod } from './SubmissionLossSummaryByPeriod';

interface Props {
  submissionId: string;
}

export const SubmissionLossSummaryTab: FC<Props> = ({ submissionId }) => {
  const { tab } = useSubmissionLossSummaryContext();

  const { data: lossReviewData, isFetching: isFetchingLossReview } = useGetSubmissionLossReviewQuery(submissionId, {
    keepPreviousData: true,
  });

  const { data: lossSummaryData, isFetching: isFetchingLossSummary } = useGetSubmissionLossSummaryQuery(submissionId, {
    keepPreviousData: true,
  });

  if ((!lossReviewData && isFetchingLossReview) || (!lossSummaryData && isFetchingLossSummary)) {
    return (
      <div className="flex flex-col gap-6">
        <Skeleton height={360} variant="rectangular" className="rounded" />
        <Skeleton height={500} variant="rectangular" className="rounded" />
      </div>
    );
  }

  return (
    <>
      {tab === ESubmissionLossSummaryTab.BY_PERIOD && (
        <SubmissionLossSummaryByPeriod
          lossSummaryData={lossSummaryData}
          isFetching={isFetchingLossSummary}
          submissionId={submissionId}
        />
      )}
      {tab === ESubmissionLossSummaryTab.BY_CARRIER && (
        <SubmissionLossSummaryByLossRun
          lossReviewData={lossReviewData}
          isFetching={isFetchingLossReview}
          submissionId={submissionId}
        />
      )}
    </>
  );
};
