import { PAYROLL_REGEX } from '@app/utils/validators';
import { z } from 'zod';

type PreviousFields = {
  previousMedicalClaims?: string;
  previousTotalMedicalLosses?: string;
  previousIndemnityClaims?: string;
  previousTotalIndemnityLosses?: string;
};
type OldFields = {
  olderMedicalClaims?: string;
  olderTotalMedicalLosses?: string;
  olderIndemnityClaims?: string;
  olderTotalIndemnityLosses?: string;
};

const previousKeys: (keyof PreviousFields)[] = [
  'previousMedicalClaims',
  'previousTotalMedicalLosses',
  'previousIndemnityClaims',
  'previousTotalIndemnityLosses',
];

const oldKeys: (keyof OldFields)[] = [
  'olderMedicalClaims',
  'olderTotalMedicalLosses',
  'olderIndemnityClaims',
  'olderTotalIndemnityLosses',
];

const isRequiredPreviousYear = (data: PreviousFields & OldFields) => {
  const {
    olderMedicalClaims,
    olderTotalMedicalLosses,
    olderIndemnityClaims,
    olderTotalIndemnityLosses,
    previousMedicalClaims,
    previousTotalMedicalLosses,
    previousIndemnityClaims,
    previousTotalIndemnityLosses,
  } = data;

  return (
    Boolean(
      previousMedicalClaims && previousTotalMedicalLosses && previousIndemnityClaims && previousTotalIndemnityLosses
    ) ||
    !Boolean(
      olderMedicalClaims ||
        olderTotalMedicalLosses ||
        olderIndemnityClaims ||
        olderTotalIndemnityLosses ||
        previousMedicalClaims ||
        previousTotalMedicalLosses ||
        previousIndemnityClaims ||
        previousTotalIndemnityLosses
    )
  );
};

const isRequiredOlderYear = (data: OldFields) => {
  const { olderMedicalClaims, olderTotalMedicalLosses, olderIndemnityClaims, olderTotalIndemnityLosses } = data;
  return (
    Boolean(olderMedicalClaims && olderTotalMedicalLosses && olderIndemnityClaims && olderTotalIndemnityLosses) ||
    !Boolean(olderMedicalClaims || olderTotalMedicalLosses || olderIndemnityClaims || olderTotalIndemnityLosses)
  );
};

const CompanyNameValidationSchema = z.string().optional().default('');

export const StateInputSchema = z.object({
  rowLocalId: z.string().min(1, 'Required'),
  stateCode: z.string().min(1, 'Required'),
  ncci: z
    // TODO: maybe reuse 'NcciClassificationOutDtoSchema'
    .object({
      id: z.number().min(1, 'Required'),
      ncci: z.string().min(1, 'Required'),
      phraseology: z.string().min(1, 'Required'),
      state: z.string().min(1, 'Required'),
      uniqueIdentifier: z.string().min(1, 'Required'),
    })
    .nullable()
    .refine((value) => Boolean(value), 'Required')
    .optional(),
  payroll: z.string().min(1, 'Required').regex(PAYROLL_REGEX, 'Invalid value'),
  uniqueIdentifier: z.string().nullable().optional(),
});

export const AwsStateInputSchema = StateInputSchema.extend({
  zipCode: z.string().min(5, 'Must be 5 characters').max(5, 'Must be 5 characters'),
});

export type AwsStateInputSchemaType = z.infer<typeof AwsStateInputSchema>;
export type StateInputSchemaType = z.infer<typeof StateInputSchema>;

export const AwsCalcValidationSchema = z
  .object({
    companyName: CompanyNameValidationSchema,
    emr: z
      .string()
      .min(1, 'Required')
      .refine((val) => Number(val) >= 0.3, {
        message: 'Min value 0.3',
      }),
    yearsInBusiness: z.string().min(1, 'Required'),
    // RECENT YEAR
    recentMedicalClaims: z.string().min(1, 'Required'),
    recentTotalMedicalLosses: z.string().min(1, 'Required'),
    recentIndemnityClaims: z.string().min(1, 'Required'),
    recentTotalIndemnityLosses: z.string().min(1, 'Required'),
    // PREVIOUS YEAR
    previousMedicalClaims: z.string().optional(),
    previousTotalMedicalLosses: z.string().optional(),
    previousIndemnityClaims: z.string().optional(),
    previousTotalIndemnityLosses: z.string().optional(),
    // OLDER YEAR
    olderMedicalClaims: z.string().optional(),
    olderTotalMedicalLosses: z.string().optional(),
    olderIndemnityClaims: z.string().optional(),
    olderTotalIndemnityLosses: z.string().optional(),
    stateInputs: z.array(AwsStateInputSchema).min(1, 'Required'),
  })
  .superRefine((data, ctx) => {
    if (!isRequiredPreviousYear(data)) {
      previousKeys.forEach((key) => {
        if (!data[key]) {
          ctx.addIssue({
            code: 'custom',
            message: 'Required',
            path: [key],
          });
        }
      });
    }
    if (!isRequiredOlderYear(data)) {
      oldKeys.forEach((key) => {
        if (!data[key]) {
          ctx.addIssue({
            code: 'custom',
            message: 'Required',
            path: [key],
          });
        }
      });
    }
  });

export type AwsCalcValidationSchema = z.infer<typeof AwsCalcValidationSchema>;

export const FullCalcValidationSchema = z.object({
  companyName: CompanyNameValidationSchema,
  naics: z
    .object({
      id: z.number().min(1, 'Required'),
      naics: z.string().min(1, 'Required'),
      description: z.string().min(1, 'Required'),
    })
    .nullable()
    .refine((value) => Boolean(value), 'Required')
    .optional(),
  emr: z
    .string()
    .min(1, 'Required')
    .refine((val) => Number(val) >= 0.3, {
      message: 'Min value 0.3',
    }),
  trir: z.string().min(1, 'Required'),
  da: z.string().min(1, 'Required'),
  safety: z.boolean(),
  health: z.boolean(),
  supplemental: z.boolean(),
  stateInputs: z.array(StateInputSchema).min(1, 'Required'),
});

export type FullCalcValidationSchemaType = z.infer<typeof FullCalcValidationSchema>;

export const MultipleYearsFullCalcValidationSchema = z.object({
  companyName: CompanyNameValidationSchema,
});

export type MultipleYearsFullCalcValidationSchemaType = z.infer<typeof MultipleYearsFullCalcValidationSchema>;
