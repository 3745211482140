import { LossRatiosBarChartExamples } from '@app/modules/ui-kit/components/LossRatiosBarChartExamples';
import { FC } from 'react';
import { StiSliderExamples } from '../components/StiSliderExamples';
import { UIStyledSection } from '../components/UIStyledSection';
import { PlotDataExamples } from '../components/PlotDataExamples';

export const UISectionFeatured: FC = () => {
  return (
    <UIStyledSection title="Featured">
      <StiSliderExamples />
      <LossRatiosBarChartExamples />
      <PlotDataExamples />
    </UIStyledSection>
  );
};
