import { clsxm } from '@app/styles/clsxm';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { UISectionBrandingAndColors } from './sections/UISectionBrandingAndColors';
import { UISectionFeatured } from '@app/modules/ui-kit/sections/UISectionFeatured';
import { UISectionButtons } from './sections/UISectionButtons';
import { UISectionFields } from './sections/UISectionFields';
import { UISectionAnimations } from './sections/UISectionAnimations';
import { UISectionIcons } from './sections/UISectionIcons';

export const UIKitPage: React.FC = () => {
  const [tab, setTab] = useQueryParam('tab', withDefault(StringParam, DEFAULT_SECTION));

  return (
    <div>
      <div className="flex justify-center gap-4">
        {TABS.map((e, i) => (
          <button key={i} onClick={() => setTab(e, 'replace')} className={clsxm(e === tab && 'underline')}>
            {e}
          </button>
        ))}
      </div>
      {SECTIONS.find((e) => e.title === tab)?.jsx}
    </div>
  );
};

const SECTIONS: {
  title: string;
  jsx: React.ReactNode;
}[] = [
  { title: 'Branding & Colors', jsx: <UISectionBrandingAndColors /> },
  { title: 'Buttons', jsx: <UISectionButtons /> },
  { title: 'Fields', jsx: <UISectionFields /> },
  { title: 'Animations', jsx: <UISectionAnimations /> },
  { title: 'Icons', jsx: <UISectionIcons /> },
  { title: 'Featured', jsx: <UISectionFeatured /> },
];

const DEFAULT_SECTION = SECTIONS[0]?.title || '';
const TABS = SECTIONS.map((e) => e.title);
