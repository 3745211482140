import { useMemo } from 'react';
import { add, divide, multiply } from 'lodash';
import { SubmissionLossSummaryOutDto } from '@app/swagger-types';

/**
 * TRIR and DART Table Calculations:
 *
 * **Period:** Refers to the policy term, matching the date range on the "Loss Summary" table.
 *
 * **Current:** The most recent year on the Loss Summary table.
 * **1st Prior:** The next most recent year.
 * **2nd Prior:** The year before the 1st Prior.
 *
 * **TRIR Calculation:**
 * TRIR = ([Number of Qualified Claims] × 200,000) ÷ [Est Hours Worked]
 *
 * **Number of Qualified Claims:**
 * - Includes:
 *   - All Indemnity claims.
 *   - Medical claims with a total incurred value > $1,000.
 * - Excludes:
 *   - Report-only claims.
 *   - Medical claims with a total incurred value ≤ $1,000.
 *
 * Calculations should be performed for each period individually.
 *
 * **Est Hours Worked:**
 * Derived from the "Avg Hourly Wage" input shown at the top of the pop-over.
 *
 * **DART Calculation:**
 * DART = ([Number of Indemnity Claims] × 200,000) ÷ [Est Hours Worked]
 *
 * **Number of Indemnity Claims:**
 * Includes only the Indemnity claims in that specific period.
 *
 * **3-Year Averages:**
 * - TRIR: The average TRIR across the three periods.
 * - DART: The average DART across the three periods.
 */

type YearStatistic = {
  trir: number;
  dart: number;
};

export const useTrirDartCalculations = (lossSummaryData?: SubmissionLossSummaryOutDto, totalHrsWorked?: number) => {
  const totalPayroll = lossSummaryData?.totalPayroll || 0;
  const avgHourlyWage = lossSummaryData?.averageHourlyWage ?? 0;
  const fullTimeEmployeeCount = lossSummaryData?.fullTimeEmployeeCount || 0;
  const partTimeEmployeeCount = lossSummaryData?.partTimeEmployeeCount || 0;

  const fullTimeHours = Number(fullTimeEmployeeCount) * 40 * 52;
  const partTimeHours = Number(partTimeEmployeeCount) * 20 * 52;

  const useEmployeeCount = Boolean(lossSummaryData?.fullTimeEmployeeCount || lossSummaryData?.partTimeEmployeeCount);

  const employeeCountTotalHours = fullTimeHours + partTimeHours;
  const usualTotalHours = avgHourlyWage ? Number(divide(totalPayroll, Number(avgHourlyWage)).toFixed(2)) : 0;

  const totalHoursWorked = totalHrsWorked ?? (useEmployeeCount ? employeeCountTotalHours : usualTotalHours);

  const isZeroValues = totalPayroll === 0 || totalHoursWorked === 0;

  const lastYearsData: YearStatistic[] = useMemo(
    () =>
      lossSummaryData?.total.totalYears
        // Sort by date.
        .sort((a, b) => new Date(b.policyTermStart).getTime() - new Date(a.policyTermStart).getTime())
        // Pick the first 3 items.
        .slice(0, 3)
        .map(({ policyTermStart, policyTermEnd }) => {
          // Find detailed year by date fields.
          const detailedYear = lossSummaryData.years.find(
            (year) => year.effectiveDateStart === policyTermStart && year.effectiveDateEnd === policyTermEnd
          );

          if (detailedYear) {
            const { claims } = detailedYear;

            return {
              trir: isZeroValues
                ? 0
                : divide(
                    multiply(
                      add(
                        // Count all claims with type - 'Indemnity'.
                        claims.filter(({ type }) => type === 'Indemnity').length,
                        // Count only claims with type - 'Medical', where incurred is higher than 1000.
                        claims.filter(({ type, incurred }) => incurred && type === 'Medical' && incurred > 1000).length
                      ),
                      200000
                    ),
                    totalHoursWorked
                  ),
              dart: isZeroValues
                ? 0
                : divide(multiply(claims.filter(({ type }) => type === 'Indemnity').length, 200000), totalHoursWorked),
            };
          }

          return {
            trir: 0,
            dart: 0,
          };
        }) || [],
    [lossSummaryData, totalHoursWorked, isZeroValues]
  );

  const averageTrir = isZeroValues
    ? 0
    : divide(add(add(lastYearsData[0]?.trir, lastYearsData[1]?.trir), lastYearsData[2]?.trir) || 0, 3);
  const averageDart = isZeroValues
    ? 0
    : divide(add(add(lastYearsData[0]?.dart, lastYearsData[1]?.dart), lastYearsData[2]?.dart) || 0, 3);

  return {
    lastYearsData,
    averageTrir,
    averageDart,
    totalPayroll,
    totalHoursWorked,
  };
};
