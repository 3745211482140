import { ALL_VALUES_OPTION, DEFAULT_CLAIM_FILTER_VALUES } from '@app/domain/claim/const/claims.filter.const';
import { format } from 'date-fns';
import { FORMAT } from '@app/constants/formats';
import { convertCentToUnit, convertUnitToCent } from '@app/utils/currency/currency.utils';
import { ClaimFilter } from '@app/swagger-types';
import { ALL_CLAIM_FILTER_CONFIGS } from '@app/domain/claim/const/claims.filter-configs.const';
import { ClaimFilterConfig, EClaimFilterType } from '@app/domain/claim/types/claim.filter.types';

export const createFiltersDto = (filters: ClaimFilter): ClaimFilter => {
  const baseDto = { ...filters };

  ALL_CLAIM_FILTER_CONFIGS.forEach((config) => {
    if (config.type === EClaimFilterType.STRING_ARR) {
      const { field } = config;
      const value = filters[field];

      baseDto[field] = value?.includes(ALL_VALUES_OPTION) ? undefined : value;
    } else if (config.type === EClaimFilterType.DATE) {
      const { fromField, toField } = config;
      const fromValue = filters[fromField];
      const toValue = filters[toField];

      baseDto[fromField] = fromValue ? format(new Date(fromValue), FORMAT.DATE_API) : undefined;
      baseDto[toField] = toValue ? format(new Date(toValue), FORMAT.DATE_API) : undefined;
    } else if (config.type === EClaimFilterType.COIN) {
      const { fromField, toField } = config;
      const fromValue = filters[fromField];
      const toValue = filters[toField];

      baseDto[fromField] = fromValue ? convertUnitToCent(fromValue) : undefined;
      baseDto[toField] = toValue ? convertUnitToCent(toValue) : undefined;
    }
  });

  return baseDto;
};

export const createFiltersFromDto = (filters: ClaimFilter): ClaimFilter => {
  const baseDto = { ...filters };

  ALL_CLAIM_FILTER_CONFIGS.forEach((config) => {
    if (config.type === EClaimFilterType.STRING_ARR) {
      const { field } = config;
      const value = filters[field];

      baseDto[field] = value || [];
    } else if (config.type === EClaimFilterType.DATE) {
      const { fromField, toField } = config;
      const fromValue = filters[fromField];
      const toValue = filters[toField];

      baseDto[fromField] = fromValue ? new Date(fromValue).toISOString() : undefined;
      baseDto[toField] = toValue ? new Date(toValue).toISOString() : undefined;
    } else if (config.type === EClaimFilterType.COIN) {
      const { fromField, toField } = config;
      const fromValue = filters[fromField];
      const toValue = filters[toField];

      baseDto[fromField] = fromValue ? convertCentToUnit(fromValue) : undefined;
      baseDto[toField] = toValue ? convertCentToUnit(toValue) : undefined;
    }
  });

  return baseDto;
};

export const generateDefaultFilters = (): ClaimFilter => {
  const baseDto: ClaimFilter = {};

  ALL_CLAIM_FILTER_CONFIGS.forEach((config) => {
    if (config.type === EClaimFilterType.STRING_ARR) {
      const { field } = config;

      baseDto[field] = [];
    }
  });

  return baseDto;
};

export const getCoinFilterKeys = (): Array<keyof ClaimFilter> => {
  const keys: Array<keyof ClaimFilter> = [];
  ALL_CLAIM_FILTER_CONFIGS.forEach((config) => {
    if (config.type === EClaimFilterType.COIN) {
      keys.push(config.fromField);
      keys.push(config.toField);
    }
  });

  return keys;
};

export const getDateFilterKeys = (): Array<keyof ClaimFilter> => {
  const keys: Array<keyof ClaimFilter> = [];
  ALL_CLAIM_FILTER_CONFIGS.forEach((config) => {
    if (config.type === EClaimFilterType.DATE) {
      keys.push(config.fromField);
      keys.push(config.toField);
    }
  });

  return keys;
};

export const countChangesByConfig = (filters: ClaimFilter, configs: ClaimFilterConfig[]): number => {
  let countChanges = 0;

  configs.forEach((config) => {
    const isChanged = (key: keyof ClaimFilter) =>
      JSON.stringify(filters[key]) !== JSON.stringify(DEFAULT_CLAIM_FILTER_VALUES[key]);

    if (config.type === EClaimFilterType.STRING_ARR && isChanged(config.field as keyof ClaimFilter)) {
      countChanges++;
    } else if (
      [EClaimFilterType.COIN, EClaimFilterType.DATE, EClaimFilterType.NUMBER, EClaimFilterType.DECIMAL_NUMBER].includes(
        config.type
      )
    ) {
      const typedConfig = config as Exclude<ClaimFilterConfig, { type: EClaimFilterType.STRING_ARR }>;

      if (isChanged(typedConfig.fromField as keyof ClaimFilter)) {
        countChanges += 1;
      }
      if (isChanged(typedConfig.toField as keyof ClaimFilter)) {
        countChanges += 1;
      }
    }
  });

  return countChanges;
};
