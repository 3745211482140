import styles from './Tooltip.module.scss';
import clsx from 'clsx';

import MuiTooltip, { TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip';
import { clsxm } from '@app/styles/clsxm';

export type TooltipProps = MuiTooltipProps & {
  variant?: 'light' | 'dark';
  text?: string;
  titleClassName?: string;
  textClassName?: string;
  disabled?: boolean;
};

export const Tooltip: React.FC<TooltipProps> = ({
  variant = 'light',
  title,
  text,
  titleClassName,
  textClassName,
  children,
  disabled,
  ...props
}) => {
  return (
    <MuiTooltip
      {...props}
      title={
        disabled ? null : text ? (
          <div className="flex flex-col gap-0.5 py-3">
            <span className={clsxm('text-xs font-semibold text-gray-800', titleClassName)}>{title}</span>
            <span className={clsxm('pt-2 text-xxs text-gray-500', textClassName)}>{text}</span>
          </div>
        ) : (
          title
        )
      }
      classes={{
        ...props?.classes,
        tooltip: clsx(styles.Tooltip, variant === 'light' ? styles.Light : styles.Dark, props.classes?.tooltip),
      }}
    >
      <span>{children}</span>
    </MuiTooltip>
  );
};
