import { FC } from 'react';
import { UIStyledSection } from '../components/UIStyledSection';
import { Skeleton, Typography } from '@mui/material';
import { DotsLoader } from '@app/components/loaders/dots-loader/DotsLoader';
import { ContentLoader } from '@app/components/loaders/content-loader/ContentLoader';
import { CircularProgress } from '@app/components/CircularProgress';
import { LinearDeterminate } from '@app/components/loaders/linear-determinate/LinearDeterminate';
import { AiLoader } from '@app/components/loaders/ai-loader/AiLoader';

export const UISectionAnimations: FC = () => {
  return (
    <UIStyledSection title="Animations">
      <div className="flex flex-col gap-6">
        <div className="grid w-96 grid-cols-2 gap-2">
          <Typography>Content Loader:</Typography>
          <ContentLoader loading />
        </div>
        <div className="grid w-96 grid-cols-2 gap-2">
          <Typography>Linear Loader:</Typography>
          <LinearDeterminate loading variant="indeterminate" />
        </div>
        <div className="grid w-96 grid-cols-2 gap-2">
          <Typography>Circular Loader:</Typography>
          <CircularProgress />
        </div>
        <div className="grid w-96 grid-cols-2 gap-2">
          <Typography>AI Loader:</Typography>
          <AiLoader />
        </div>
        <div className="grid w-96 grid-cols-2 gap-2">
          <Typography>Dots Loader:</Typography>
          <DotsLoader />
        </div>
        <div className="grid w-96 grid-cols-2 gap-2">
          <Typography>Skeleton:</Typography>
          <Skeleton height={100} variant="rounded" />
        </div>
      </div>
    </UIStyledSection>
  );
};
