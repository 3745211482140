import { FC } from 'react';
import { UIStyledSection } from '../components/UIStyledSection';
import { ButtonExamples } from '../components/ButtonExamples';

export const UISectionButtons: FC = () => {
  return (
    <UIStyledSection title="Buttons">
      <ButtonExamples />
    </UIStyledSection>
  );
};
