import { LinearProgress, LinearProgressProps } from '@mui/material';
import { FC, useState, useEffect, useRef } from 'react';

interface PropTypes extends LinearProgressProps {
  loading: boolean;
  onProgressEnd?: () => void;
}

export const LinearDeterminate: FC<PropTypes> = ({ loading, onProgressEnd, ...rest }) => {
  const [progress, setProgress] = useState(0);
  const timer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (!loading) {
      if (timer.current) {
        clearInterval(timer.current);
      }
      setProgress(100);
      if (onProgressEnd) {
        onProgressEnd();
      }
      return;
    }

    timer.current = setInterval(() => {
      setProgress((oldProgress) => {
        const diff = Math.random() * 30;
        const currentProgres = Math.min(oldProgress + diff, 100);
        if (currentProgres >= 85 && timer.current) {
          clearInterval(timer.current);
        }
        return currentProgres;
      });
    }, 300);

    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, [loading, onProgressEnd]);

  return <LinearProgress variant="determinate" value={progress} {...rest} />;
};
