import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import DownloadIcon from '@mui/icons-material/Download';
import BarChartIcon from '@mui/icons-material/BarChart';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { FC, useState } from 'react';

import { DataExplorationChartTypePopover } from './DataExplorationChartSelector';
import { useSendBadResponseMutation } from '../api/data-exploration.api.hooks';
import { IconButton } from '@app/components/buttons/icon-button/IconButton';
import { DataExplorationBarChart } from './DataExplorationBarChart';
import { DataExplorationPieChart } from './DataExplorationPieChart';
import { DEFAULT_ANSWER } from '../const/data-exploration.const';
import { DataExplorationTable } from './DataExplorationTable';
import { DataExplorationOutDto } from '@app/swagger-types';
import { Tooltip } from '@app/components/tooltip/Tooltip';
import { markdownParser } from '@app/utils/markdown.util';
import { showSuccessToast } from '@app/utils/toast.utils';
import { useHandler } from '@app/hooks/useHandler.hook';
import { clsxm } from '@app/styles/clsxm';
import { AiLoader } from '@app/components/loaders/ai-loader/AiLoader';

export enum EChartType {
  BAR = 'BAR',
  PIE = 'PIE',
}

interface Props {
  submittedQuestion: string | null;
  data: DataExplorationOutDto | null;
  isRequestingDataExploration: boolean;
}

export const DataExplorationResults: FC<Props> = ({ submittedQuestion, data, isRequestingDataExploration }) => {
  const { mutateAsync: sendBadResponse, isLoading: isSendingBadResponse } = useSendBadResponseMutation({
    onSettled: (response) => {
      if (response?.status === 204) {
        showSuccessToast('Your feedback has been submitted successfully');
      }
    },
  });

  const [isPlotVisible, setPlotVisibility] = useState(false);
  const [chartType, setChartType] = useState(EChartType.BAR);

  const gridApiRef = useGridApiRef();

  const plotData = data?.questionResults?.[0]?.plotData || null;
  const gridData = data?.questionResults?.[0]?.gridData || null;
  const answer = data?.questionResults?.[0]?.answer || null;

  const isValidPlotData = plotData?.every(
    (item) =>
      item && item.keyName !== null && item.keyValue !== null && item.dataValue !== null && item.dataName !== null
  );

  const isChartReady = plotData && plotData.length > 1 && isValidPlotData;

  const handleSendBadResponse = () => {
    if (data?.logId) {
      sendBadResponse(data?.logId);
    }
  };

  const handleDownloadAsCSV = useHandler(() => {
    gridApiRef.current?.exportDataAsCsv({ fileName: 'grid-data' });
  });

  const parsedAnswer = markdownParser.parse(answer || DEFAULT_ANSWER) as string;

  if (!submittedQuestion) {
    return null;
  }

  return (
    <section className="flex flex-col gap-2">
      <div className="ml-auto max-w-fit rounded-md bg-white p-4 shadow-md">{submittedQuestion}</div>
      <div className="flex w-full items-start gap-2">
        <div className="max-w-fit">{data ? <AutoAwesomeIcon className="mr-auto text-secondary" /> : null}</div>
        <div
          className={clsxm(
            'mr-auto flex w-full flex-col justify-center gap-2 rounded-md bg-white p-4 shadow-md',
            isChartReady ? 'max-w-full' : 'max-w-96'
          )}
        >
          {!isRequestingDataExploration && <p dangerouslySetInnerHTML={{ __html: parsedAnswer }} className="mr-2"></p>}
          {isChartReady && !isRequestingDataExploration && (
            <DataExplorationTable isLoading={isRequestingDataExploration} apiRef={gridApiRef} gridData={gridData} />
          )}
          {!isChartReady && isRequestingDataExploration && <AiLoader />}
        </div>
      </div>

      {isPlotVisible && isChartReady && !isRequestingDataExploration && (
        <div className="flex items-center gap-2">
          <div className="size-6" />
          <div className="flex w-full flex-col gap-4 rounded-md bg-white p-4">
            <div className="ml-auto max-w-[120px]">
              <DataExplorationChartTypePopover chartType={chartType} setChartType={setChartType} />
            </div>
            {chartType === EChartType.BAR && <DataExplorationBarChart data={plotData} />}
            {chartType === EChartType.PIE && <DataExplorationPieChart data={plotData} />}
          </div>
        </div>
      )}
      <div
        className={clsxm(
          'flex h-10 w-full flex-col items-center justify-end gap-4 rounded-md',
          isChartReady ? 'max-w-full' : 'max-w-96'
        )}
      >
        {!isRequestingDataExploration && (
          <div className="ml-auto flex items-center gap-4">
            {isChartReady && (
              <>
                <Tooltip title="Bad response">
                  <IconButton
                    className="rounded text-gray-600"
                    onClick={handleSendBadResponse}
                    disabled={isSendingBadResponse}
                    loading={isSendingBadResponse}
                    variant="transparent"
                    size="large"
                  >
                    <ThumbDownOffAltIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Download CSV">
                  <IconButton
                    className="rounded text-gray-600"
                    onClick={handleDownloadAsCSV}
                    variant="transparent"
                    size="large"
                  >
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={isPlotVisible ? 'Hide Chart' : 'Show Chart'}>
                  <IconButton
                    onClick={() => setPlotVisibility((prev) => !prev)}
                    className="rounded text-gray-600"
                    variant="transparent"
                    size="large"
                  >
                    <BarChartIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </div>
        )}
      </div>
    </section>
  );
};
