import { FC, useState, MouseEvent, ReactNode, useEffect, ChangeEvent, useCallback, useRef, KeyboardEvent } from 'react';
import { CircularProgress, Popover, PopoverOrigin } from '@mui/material';
import { GridScrollParams } from '@mui/x-data-grid-pro';
import { useQueryClient } from '@tanstack/react-query';
import CheckIcon from '@mui/icons-material/Check';

import { useUsersCompactQuery } from '@app/domain/user/api/user.api.hooks';
import { useEditSubmissionMutation } from '../api/submission.api.hooks';
import { Button } from '@app/components/buttons/button/Button';
import { useHandler } from '@app/hooks/useHandler.hook';
import { NotAssignedIcon } from '@app/assets';
import { clsxm } from '@app/styles/clsxm';
import { renderUserCompactName } from '@app/domain/user/utils/user.utils';
import { EQueryConfigName } from '@app/constants/query-config.const';

type Props = {
  submissionId: string;
  value?: string | null;
  className?: string;
  anchorOrigin?: PopoverOrigin;
  onChangeScroll?: (scrollPosition: Partial<GridScrollParams> | undefined) => void;
  transformOrigin?: PopoverOrigin;
  scrollPosition?: Partial<GridScrollParams>;
} & (
  | {
      icon: ReactNode;
      text?: never;
    }
  | {
      text: string;
      icon?: never;
    }
);

export const SubmissionAssigneePopover: FC<Props> = ({
  icon,
  text,
  scrollPosition,
  submissionId,
  value,
  className,
  onChangeScroll,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'center',
  },
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [assignee, setAssignee] = useState(value);
  const { data = [] } = useUsersCompactQuery({ includeDisabled: false });
  const [filteredUsers, setFilteredUsers] = useState(data);

  const [focusedIndex, setFocusedIndex] = useState(-1);

  const buttonRefs = useRef<(HTMLButtonElement | null)[]>([]);

  const open = Boolean(anchorEl);
  const id = open ? String(submissionId) : undefined;

  const queryClient = useQueryClient();

  const { mutate: updateSubmission, isLoading } = useEditSubmissionMutation(submissionId, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [EQueryConfigName.GET_SUBMISSION_BY_ID, submissionId] });

      onChangeScroll?.(scrollPosition);
    },
  });

  const handleClick = useHandler((event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  });

  const handleClose = useHandler(() => {
    setAnchorEl(null);
    setAssignee(value);
  });

  const onChangeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    setFilteredUsers(
      data.filter((user) => {
        const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();

        return fullName.toLowerCase().includes(event.target.value.toLowerCase());
      })
    );

    setFocusedIndex(0);
  };

  const onChangeAssignee = useCallback(
    (newAssignee: string) => {
      if (assignee === newAssignee) {
        updateSubmission({ assignee: '' });
        setAssignee('');
      } else {
        updateSubmission({ assignee: newAssignee });
        setAssignee(newAssignee);
      }
    },
    [assignee, updateSubmission]
  );

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (!filteredUsers.length) {
      return;
    }

    if (event.key === 'ArrowDown') {
      event.preventDefault();
      setFocusedIndex((prev) => (prev + 1) % filteredUsers.length);
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      setFocusedIndex((prev) => (prev - 1 + filteredUsers.length) % filteredUsers.length);
    } else if (event.key === 'Enter') {
      event.preventDefault();
      const focusedUser = filteredUsers[focusedIndex];
      if (focusedUser) {
        onChangeAssignee(focusedUser.username);
      }
    }
  };

  useEffect(() => {
    if (data && data.length) {
      setFilteredUsers(data);
    }
  }, [data]);

  useEffect(() => {
    if (buttonRefs.current[focusedIndex]) {
      buttonRefs.current[focusedIndex]?.focus();
    }
  }, [focusedIndex]);

  useEffect(() => {
    if (!open) {
      setFocusedIndex(-1);
    }
  }, [open]);

  const renderedUsers = filteredUsers
    .sort((userA, userB) => renderUserCompactName(userA).localeCompare(renderUserCompactName(userB)))
    .map((user, index) => (
      <button
        ref={(el) => (buttonRefs.current[index] = el)}
        className={clsxm(
          'flex w-full items-center justify-between rounded-md border-none p-2 text-start hover:bg-zinc-100',
          assignee === user.username && 'bg-zinc-100 hover:bg-zinc-100',
          index === focusedIndex && 'bg-gray-200'
        )}
        onClick={() => onChangeAssignee(user.username)}
        key={user.username}
      >
        <span>{renderUserCompactName(user)}</span>
        {assignee === user.username && isLoading && <CircularProgress size={20} />}
        {assignee === user.username && !isLoading && <CheckIcon className="text-[#777]" fontSize="small" />}
      </button>
    ));

  const onUnassign = () => {
    updateSubmission({ assignee: '' });

    setAssignee('');
  };

  useEffect(() => {
    if (value) {
      setAssignee(value);
    }
  }, [value]);

  return (
    <>
      {icon ? (
        <Button
          className={clsxm(
            'mt-2 rounded-[5px] border-none bg-white px-2.5 py-2 font-normal text-gray-400',
            'hover:text-gray-500 hover:shadow-none group-hover/datagrid-row:shadow-assigneeBtn',
            className
          )}
          onClick={handleClick}
          aria-describedby={id}
          startIcon={icon}
          size="small"
        >
          Assign
        </Button>
      ) : (
        <Button
          aria-describedby={id}
          size="small"
          className={clsxm(
            'mt-[10px] w-fit rounded-[5px] border-0 bg-white p-2 font-normal text-[#000000E6] transition-all',
            'hover:bg-gray-100 group-hover/datagrid-row:shadow-assigneeBtn',
            className
          )}
          onClick={handleClick}
        >
          {text}
        </Button>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        transformOrigin={transformOrigin}
        anchorOrigin={anchorOrigin}
      >
        <div className="col flex flex-col">
          <input
            ref={(node) => {
              if (node) {
                setTimeout(() => {
                  node.focus();
                }, 1);
              }
            }}
            onKeyDown={handleKeyDown}
            className="mt-1 px-4 py-2 outline-none placeholder:text-xs placeholder:text-gray-400"
            onChange={onChangeSearchValue}
            placeholder="Assign User"
            disabled={isLoading}
            type="text"
          />
          <hr className="w-full bg-gray-100" />
          <button
            className={clsxm(
              'mx-2 mt-1 flex cursor-pointer items-center justify-between gap-2 rounded-md p-2',
              'hover:bg-gray-100',
              !assignee && 'cursor-default hover:bg-white'
            )}
            onClick={onUnassign}
            disabled={!assignee}
          >
            <div className="flex items-center gap-2 text-xs">
              <NotAssignedIcon fill="#777" width={20} height={20} />
              <p>No assignee</p>
            </div>
            {!assignee && !isLoading && <CheckIcon fontSize="small" className="text-[#777]" />}
            {!assignee && isLoading && <CircularProgress size={20} />}
          </button>
          {filteredUsers.length ? (
            <>
              <p className="mx-4 my-2 text-xs font-medium text-gray-400">Team members</p>
              <div className="mx-2 mb-2 flex max-h-[160px] flex-col gap-2 overflow-y-auto text-xs">{renderedUsers}</div>
            </>
          ) : null}
        </div>
      </Popover>
    </>
  );
};
