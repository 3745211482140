import { z } from 'zod';
import {
  ESubmissionVerificationMatchValidationStatus,
  ESubmissionClaimStatus,
  ESubmissionFileStatus,
  ESubmissionStatus,
  EOutcomeType,
  ESubmissionRiskFactor,
} from '@app/swagger-types';
import { VersionSchema } from '@app/utils/zod.utils';

export const SubmissionNamedInsuredDtoSchema = z.object({
  id: z.number(),
  name: z.string(),
  fein: z.string().nullable().optional(),
});

export const SubmissionFileCompactOutDtoSchema = z.object({
  id: z.number(),
  filename: z.string(),
  createdDate: z.string(),
  status: z.nativeEnum(ESubmissionFileStatus),
  errorMessage: z.string().nullable().optional(),
});

export const SubmissionFileCompactOutDtoNullableStatusSchema = SubmissionFileCompactOutDtoSchema.extend({
  status: z.nativeEnum(ESubmissionFileStatus).nullable(),
});

export const SubmissionFileOutDtoNullableStatusAndResultFilesSchema =
  SubmissionFileCompactOutDtoNullableStatusSchema.extend({
    resultFiles: z.array(z.string().nullable()).optional().nullable(),
  });

export const SubmissionFileOutDtoSchema = SubmissionFileCompactOutDtoSchema.extend({
  resultFiles: z.array(z.string().nullable()).optional(),
});

export const SubmissionOutDtoSchema = z.object({
  id: z.number(),
  submissionNamedInsured: SubmissionNamedInsuredDtoSchema,
  effectiveDate: z.string(),
  createdDate: z.string(),
  neededByDate: z.string(),
  creator: z.string(),
  assignee: z.string().nullable().optional(),
  assigneeName: z.string().nullable().optional(),
  status: z.nativeEnum(ESubmissionStatus),
  fileOutAccordApplicationDtos: z.array(SubmissionFileCompactOutDtoSchema).nullable().optional(),
  fileOutEmrDtos: z.array(SubmissionFileCompactOutDtoSchema).nullable().optional(),
  fileOutLossRunsDtos: z.array(SubmissionFileCompactOutDtoSchema).nullable().optional(),
  isOnStiResultsWindow: z.boolean(),
  isStandard: z.boolean(),
  hasGeneratedInsights: z.boolean(),
  underwriterNotes: z.string().nullable().optional(),
});

export const PresignedUrlOutDtoSchema = z.object({
  downloadUrl: z.string(),
});

export const SubmissionLossClaimOutDtoSchema = z.object({
  dateOfLoss: z.string(),
  status: z.nativeEnum(ESubmissionClaimStatus),
  type: z.string(),
  medical: z.number().nullable().optional(),
  indemnity: z.number().nullable().optional(),
  expense: z.number().nullable().optional(),
  reserves: z.number().nullable().optional(),
  incurred: z.number().nullable().optional(),
  details: z.string().nullable().optional(),
});

// Submission Loss Review Schemas

export const SubmissionLossReviewTotalCarrierOutDtoSchema = z.object({
  name: z.string().nullable().optional(),
  coverageStartYear: z.number().nullable().optional(),
  coverageEndYear: z.number().nullable().optional(),
  totalMedical: z.number(),
  totalIndemnity: z.number(),
  totalExpense: z.number(),
  totalReserves: z.number(),
  totalIncurred: z.number(),
  numberOfClaims: z.number(),
});

export const SubmissionLossReviewTotalOutDtoSchema = z.object({
  submissionTotalCarriers: z.array(SubmissionLossReviewTotalCarrierOutDtoSchema),
  totalMedical: z.number(),
  totalIndemnity: z.number(),
  totalExpense: z.number(),
  totalReserves: z.number(),
  totalIncurred: z.number(),
  numberOfClaims: z.number(),
});

export const SubmissionLossReviewCarrierOutDtoSchema = z.object({
  effectiveDateStart: z.string().nullable().optional(),
  effectiveDateEnd: z.string().nullable().optional(),
  claims: z.array(SubmissionLossClaimOutDtoSchema),
  claimCount: z.number(),
  totalMedical: z.number(),
  totalIndemnity: z.number(),
  totalExpense: z.number(),
  totalReserves: z.number(),
  totalIncurred: z.number(),
  name: z.string().nullable().optional(),
});

export const SubmissionLossReviewOutDtoSchema = z.object({
  total: SubmissionLossReviewTotalOutDtoSchema,
  carriers: z.array(SubmissionLossReviewCarrierOutDtoSchema),
});

// Submission SafeTier Rating Schemas

export const SubmissionStiPayrollEntryOutDtoSchema = z.object({
  state: z.string().nullable().optional(),
  zipCode: z.string().nullable().optional(),
  ncci: z.string().nullable().optional(),
  amount: z.number().nullable().optional(),
  ncciUniqueId: z.string().nullable().optional(),
  phraseology: z.string().nullable().optional(),
});

export const SubmissionStiPayrollEntryInDtoSchema = z.object({
  state: z.string().nullable().optional(),
  zipCode: z.string().nullable().optional(),
  ncci: z.string().nullable().optional(),
  amount: z.number().nullable().optional(),
  ncciUniqueId: z.string().nullable().optional(),
});

export const SubmissionStiFullPrefillInDtoSchema = z.object({
  naics: z.string().optional(),
  trir: z.number().optional(),
  dart: z.number().optional(),
  emr: z.number().optional(),
  safetyPrograms: z.boolean().optional(),
  healthCoverage: z.boolean().optional(),
  supplementalCoverage: z.boolean().optional(),
  payrollEntries: z.array(SubmissionStiPayrollEntryOutDtoSchema),
});

export const SubmissionStiFullPrefillOutDtoSchema = z.object({
  naics: z.string().nullable().optional(),
  trir: z.number().nullable().optional(),
  dart: z.number().nullable().optional(),
  emr: z.number().nullable().optional(),
  safetyPrograms: z.boolean().nullable().optional(),
  healthCoverage: z.boolean().nullable().optional(),
  supplementalCoverage: z.boolean().nullable().optional(),
  payrollEntries: z.array(SubmissionStiPayrollEntryOutDtoSchema),
});

export const SubmissionStiYearPrefillInDtoSchema = z.object({
  medicalClaims: z.number().optional(),
  totalMedicalLosses: z.number().optional(),
  indemnityClaims: z.number().optional(),
  totalIndemnityLosses: z.number().optional(),
});

export const SubmissionStiAwsPrefillInDtoSchema = z.object({
  emr: z.number().optional(),
  yearsInBusiness: z.number().optional(),
  recentYear: SubmissionStiYearPrefillInDtoSchema,
  previousYear: SubmissionStiYearPrefillInDtoSchema.optional(),
  twoYearsAgo: SubmissionStiYearPrefillInDtoSchema.optional(),
  payrollEntries: z.array(SubmissionStiPayrollEntryInDtoSchema),
});

export const SubmissionStiYearPrefillOutDtoSchema = z.object({
  medicalClaims: z.number().nullable().optional(),
  totalMedicalLosses: z.number().nullable().optional(),
  indemnityClaims: z.number().nullable().optional(),
  totalIndemnityLosses: z.number().nullable().optional(),
});

export const SubmissionStiAwsPrefillOutDtoSchema = z.object({
  emr: z.number().nullable().optional(),
  yearsInBusiness: z.number().nullable().optional(),
  recentYear: SubmissionStiYearPrefillOutDtoSchema.nullable().optional(),
  previousYear: SubmissionStiYearPrefillOutDtoSchema.nullable().optional(),
  twoYearsAgo: SubmissionStiYearPrefillOutDtoSchema.nullable().optional(),
  payrollEntries: z.array(SubmissionStiPayrollEntryOutDtoSchema),
});

export const SubmissionGiCalcDataOutDtoSchema = z.object({
  stig: z.number(),
  predictedLosses: z.number(),
});

export const SubmissionGiCalcOutDtoSchema = z.object({
  responseId: z.number().optional(),
  isSuccessful: z.boolean().optional(),
  responseType: z.nativeEnum(EOutcomeType).optional(),
  version: VersionSchema.optional().default({ reqTs: '', model: '' }),
  data: SubmissionGiCalcDataOutDtoSchema,
});

// Submission Loss Summary Schemas

export const SubmissionLossSummaryTotalYearsOutDtoSchema = z.object({
  policyTermStart: z.string(),
  policyTermEnd: z.string(),
  medicalClaimsOpenNum: z.number(),
  medicalClaimsOpenValue: z.number(),
  medicalClaimsClosedNum: z.number(),
  medicalClaimsClosedValue: z.number(),
  indemnityClaimsOpenNum: z.number(),
  indemnityClaimsOpenValue: z.number(),
  indemnityClaimsClosedNum: z.number(),
  indemnityClaimsClosedValue: z.number(),
  reportOnlyClaimsOpenNum: z.number(),
  reportOnlyClaimsClosedNum: z.number(),
  totalIncurredOpen: z.number(),
  totalIncurredClosed: z.number(),
  totalReservesOpen: z.number(),
  totalReservesClosed: z.number(),
  numberOfClaimsClosed: z.number(),
  numberOfClaimsOpen: z.number(),
});

export const SubmissionLossSummaryTotalOutDtoSchema = z.object({
  totalYears: z.array(SubmissionLossSummaryTotalYearsOutDtoSchema),
  totalIncurredOpen: z.number(),
  totalIncurredClosed: z.number(),
  totalReservesOpen: z.number(),
  totalReservesClosed: z.number(),
  numberOfClaimsOpen: z.number(),
  numberOfClaimsClosed: z.number(),
  totalMedicalClaimsOpenNum: z.number(),
  totalMedicalClaimsOpenValue: z.number(),
  totalMedicalClaimsClosedNum: z.number(),
  totalMedicalClaimsClosedValue: z.number(),
  totalIndemnityClaimsOpenNum: z.number(),
  totalIndemnityClaimsOpenValue: z.number(),
  totalIndemnityClaimsClosedNum: z.number(),
  totalIndemnityClaimsClosedValue: z.number(),
  totalReportOnlyClaimsOpenNum: z.number(),
  totalReportOnlyClaimsClosedNum: z.number(),
});

export const SubmissionLossSummaryYearsOutDtoSchema = z.object({
  effectiveDateStart: z.string(),
  effectiveDateEnd: z.string(),
  claims: z.array(SubmissionLossClaimOutDtoSchema),
  totalMedical: z.number(),
  totalIndemnity: z.number(),
  totalExpense: z.number(),
  totalReserves: z.number(),
  totalIncurred: z.number(),
  claimCount: z.number(),
});

export const SubmissionLossSummaryOutDtoSchema = z.object({
  totalPayroll: z.number().nullable().optional(),
  averageHourlyWage: z.number(),
  fullTimeEmployeeCount: z.number().nullable().optional(),
  partTimeEmployeeCount: z.number().nullable().optional(),
  total: SubmissionLossSummaryTotalOutDtoSchema,
  years: z.array(SubmissionLossSummaryYearsOutDtoSchema),
});

// Submission Insights Schemas

export const SubmissionInsightApiDtoSchema = z.object({
  question: z.string(),
  answer: z.string(),
});

export const SubmissionInsightsOutDtoSchema = z.object({
  isSuccessful: z.boolean(),
  responseMessage: z.string(),
  insights: z.array(SubmissionInsightApiDtoSchema),
});

export const SubmissionVerificationCarrierOutDtoSchema = z.object({
  submissionVerificationMatchValidationStatus: z.nativeEnum(ESubmissionVerificationMatchValidationStatus),
  carrierName: z.string().nullable(),
  coverageYearStart: z.number().nullable(),
  coverageYearEnd: z.number().nullable(),
  totalMedicalVerified: z.boolean(),
  totalIndemnityVerified: z.boolean(),
  totalExpensesVerified: z.boolean(),
  totalIncurredVerified: z.boolean(),
  totalReservesVerified: z.boolean(),
  totalClaimCountVerified: z.boolean(),
});

export const SubmissionVerificationOutDtoSchema = z.object({
  isSuccessful: z.boolean(),
  carriers: z.array(SubmissionVerificationCarrierOutDtoSchema),
  message: z.string().nullable(),
});

export const SubmissionRiskFactorOutDtoSchema = z.object({
  id: z.number(),
  riskFactorId: z.number(),
  riskFactorName: z.string(),
  uiOrder: z.number(),
  riskFactorStatus: z.nativeEnum(ESubmissionRiskFactor),
  riskFactorDescription: z.string().nullable().optional(),
  riskValue: z.number().nullable().optional(),
  notes: z.string().nullable().optional(),
  riskImpactLevelName: z.string().nullable().optional(),
});

export const SubmissionRiskFactorsOutDtoSchema = z.object({
  totalRiskFactor: z.number(),
  risks: z.array(SubmissionRiskFactorOutDtoSchema),
});

const RiskSeverityImpactOutDtoSchema = z.object({
  impactLevelName: z.string(),
  defaultImpactLevelValue: z.number(),
  uiOrder: z.number(),
});

export const RiskFactorsOutDtoSchema = z.array(
  z.object({
    id: z.number(),
    riskFactorName: z.string(),
    riskFactorDescription: z.string().nullable().optional(),
    riskSeverityImpactLevels: z.array(RiskSeverityImpactOutDtoSchema),
  })
);

export const BlockContentSchema = z.object({
  type: z.string(),
  text: z.string(),
  styles: z.any(),
});

export const SubmissionNotesInDtoSchema = z.array(
  z.object({
    id: z.string(),
    type: z.string(),
    // Using z.any() for props, content, and children due to the complexity and variety of possible values these fields can hold.
    props: z.any(),
    content: z.any(),
    children: z.any(),
  })
);

export const SubmissionNotesOutDtoSchema = z.union([z.string(), SubmissionNotesInDtoSchema]);

export const SubmissionOverviewOutDtoSchema = z.object({
  domiciledState: z.string().nullable().optional(),
  operationStates: z.string().nullable().optional(),
  exModFactor: z.number().nullable().optional(),
  hazardGrade: z.string().nullable().optional(),
  operationalNotes: z.string().nullable().optional(),
  governingClassCodeNcciId: z.string().nullable().optional(),
  naics: z.string().nullable().optional(),
  agencyName: z.string().nullable().optional(),
  producerName: z.string().nullable().optional(),
  csRepresentativeName: z.string().nullable().optional(),
  agencyPhone: z.string().nullable().optional(),
  agencyEmail: z.string().nullable().optional(),
  payrollEntries: z.array(SubmissionStiPayrollEntryOutDtoSchema).nullable().optional(),
});
