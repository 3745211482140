import { DataExplorationBarChart } from '@app/domain/data-exploration/components/DataExplorationBarChart';
import { DataExplorationPieChart } from '@app/domain/data-exploration/components/DataExplorationPieChart';
import { DataExplorationPlotOutDto } from '@app/swagger-types';
import { Typography } from '@mui/material';
import { FC } from 'react';

interface Props {}

export const PlotDataExamples: FC<Props> = () => {
  return (
    <div className="p-10">
      <Typography variant="h4">DataExplorationBarChart:</Typography>
      <DataExplorationBarChart data={plotData} />
      <Typography variant="h4">DataExplorationPieChart:</Typography>
      <DataExplorationPieChart data={plotData} />
    </div>
  );
};

const plotData: DataExplorationPlotOutDto[] = [
  {
    keyName: 'columnName',
    keyValue: 'claim_number',
    dataValue: 'text',
    dataName: 'dataType',
  },
  {
    keyName: 'columnName',
    keyValue: 'claimant',
    dataValue: 'text',
    dataName: 'dataType',
  },
  {
    keyName: 'columnName',
    keyValue: 'date_of_loss',
    dataValue: 'datetime',
    dataName: 'dataType',
  },
  {
    keyName: 'columnName',
    keyValue: 'claim_type',
    dataValue: 'text',
    dataName: 'dataType',
  },
  {
    keyName: 'columnName',
    keyValue: 'claim_status',
    dataValue: 'text',
    dataName: 'dataType',
  },
  {
    keyName: 'columnName',
    keyValue: 'date_closed',
    dataValue: 'datetime',
    dataName: 'dataType',
  },
  {
    keyName: 'columnName',
    keyValue: 'lost_time_indicator',
    dataValue: 'text',
    dataName: 'dataType',
  },
  {
    keyName: 'columnName',
    keyValue: 'returned_to_work',
    dataValue: 'text',
    dataName: 'dataType',
  },
  {
    keyName: 'columnName',
    keyValue: 'total_days_lost',
    dataValue: 'int',
    dataName: 'dataType',
  },
  {
    keyName: 'columnName',
    keyValue: 'litigation',
    dataValue: 'text',
    dataName: 'dataType',
  },
  {
    keyName: 'columnName',
    keyValue: 'suit_filed',
    dataValue: 'text',
    dataName: 'dataType',
  },
  {
    keyName: 'columnName',
    keyValue: 'claim_denied',
    dataValue: 'text',
    dataName: 'dataType',
  },
  {
    keyName: 'columnName',
    keyValue: 'subrogation_status',
    dataValue: 'text',
    dataName: 'dataType',
  },
  {
    keyName: 'columnName',
    keyValue: 'accident_state',
    dataValue: 'text',
    dataName: 'dataType',
  },
];
