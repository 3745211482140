import { EGroupLossRatioBy, LossRatioFilter } from '@app/swagger-types';

export const DEFAULT_GROUP_BY_LOSS_RATIO_VALUE = EGroupLossRatioBy.POLICY_NUMBER;

export const DEFAULT_LOSS_RATIO_FILTER_VALUES: LossRatioFilter = {
  groupBy: DEFAULT_GROUP_BY_LOSS_RATIO_VALUE,
  ncci: [],
  states: [],
  agencies: [],
  policyNumbers: [],
  insuredNames: [],
  hazardGrades: [],
  statuses: [],
  policyTypes: [],
  deductibleValues: [],
  effectiveDateStart: undefined,
  effectiveDateEnd: undefined,
  bindingMonthStart: undefined,
  bindingMonthEnd: undefined,
};

export const GROUP_BY_LOSS_RATIO_VALUES: EGroupLossRatioBy[] = [
  EGroupLossRatioBy.POLICY_NUMBER,
  EGroupLossRatioBy.NCCI,
  EGroupLossRatioBy.AGENCY,
  EGroupLossRatioBy.STATE,
  EGroupLossRatioBy.BINDING_MONTH,
  EGroupLossRatioBy.HAZARD_GRADE,
];
