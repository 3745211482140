import { FC, useState } from 'react';

import { NaicsOutDto, SubmissionOverviewOutDto } from '@app/swagger-types';
import { OVERVIEW_PROFILE_NULL_PLACEHOLDERS } from '../const/submission.const';
import { clsxm } from '@app/styles/clsxm';
import { SubmissionUtils } from '../utils/submission.utils';

interface Props {
  overviewData?: SubmissionOverviewOutDto;
  naics?: NaicsOutDto[];
}

export const SubmissionOverviewProfileView: FC<Props> = ({ overviewData = {}, naics }) => {
  const [showMore, setShowMore] = useState(false);

  const { domiciledState, operationStates, operationalNotes, exModFactor, hazardGrade, governingClassCodeNcciId } =
    overviewData;

  const governingClassCode = governingClassCodeNcciId
    ? overviewData?.payrollEntries?.find((entry) => entry.ncciUniqueId === governingClassCodeNcciId)
    : null;

  const naicsItem = naics?.find((item) => item.naics === overviewData?.naics) || null;
  const descriptionOfOperations = operationalNotes || OVERVIEW_PROFILE_NULL_PLACEHOLDERS.operationalNotes;

  return (
    <div className="grid grid-cols-1 gap-8 @overview-profile:grid-cols-[65fr_35fr] @overview-profile:gap-20">
      <div className="flex flex-col gap-8">
        <div
          className={clsxm(
            'grid gap-14 md:grid-cols-2',
            'lg:grid-cols-[fit-content(120px)_fit-content(100%)_fit-content(120px)_140px]'
          )}
        >
          <div className="flex flex-col justify-start gap-2">
            <span className="text-xxs text-gray-500">Dom State</span>
            <span className={clsxm('text-l', !domiciledState && 'italic text-gray-400')}>
              {domiciledState || OVERVIEW_PROFILE_NULL_PLACEHOLDERS.domiciledState}
            </span>
          </div>
          <div className="flex flex-col justify-start gap-2">
            <span className="text-xxs text-gray-500">States of Operation</span>
            <span className={clsxm('text-l', !operationStates && 'italic text-gray-400')}>
              {operationStates || OVERVIEW_PROFILE_NULL_PLACEHOLDERS.operationStates}
            </span>
          </div>
          <div className="flex flex-col justify-start gap-2">
            <span className="text-xxs text-gray-500">EMR</span>
            <span className={clsxm('text-l', !exModFactor && 'italic text-gray-400')}>
              {exModFactor?.toFixed(2) || OVERVIEW_PROFILE_NULL_PLACEHOLDERS.exModFactor}
            </span>
          </div>
          <div className="flex flex-col justify-start gap-2">
            <span className="text-xxs text-gray-500">Haz Grade</span>
            <span className={clsxm('text-l', !hazardGrade && 'italic text-gray-400')}>
              {hazardGrade || OVERVIEW_PROFILE_NULL_PLACEHOLDERS.hazardGrade}
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-8">
          <div className="flex flex-col justify-start gap-2">
            <span className="text-xxs text-gray-500">Gov Class</span>
            <span className={clsxm('text-l', !governingClassCode && 'italic text-gray-400')}>
              {governingClassCode
                ? SubmissionUtils.renderPayrollEntryLabel(governingClassCode)
                : OVERVIEW_PROFILE_NULL_PLACEHOLDERS.governingClassCodeNcciId}
            </span>
          </div>
          <div className="flex flex-col justify-start gap-2">
            <span className="text-xxs text-gray-500">NAICS</span>
            <span className={clsxm('text-l', !naicsItem && 'italic text-gray-400')}>
              {naicsItem ? `${naicsItem.naics} - ${naicsItem.description}` : OVERVIEW_PROFILE_NULL_PLACEHOLDERS.naics}
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-start gap-2 @overview-profile:gap-2">
        <h3 className="text-xxs text-gray-500">Description of Operations</h3>
        <p className={clsxm('text-l', !operationalNotes && 'italic text-gray-400')}>
          {descriptionOfOperations
            .slice(0, showMore || descriptionOfOperations.length <= 300 ? undefined : 300)
            .trimEnd()}
          {!showMore && descriptionOfOperations.length > 300 && '...'}
          {descriptionOfOperations.length > 300 && (
            <button
              className="ml-1 text-blue-500 hover:underline"
              onClick={() => setShowMore((prevValue) => !prevValue)}
            >
              {showMore ? 'Show Less' : 'Show More'}
            </button>
          )}
        </p>
      </div>
    </div>
  );
};
