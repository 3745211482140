import clsx from 'clsx';
import React, { Fragment, ReactNode, useCallback, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow as MuiTableRow,
  Typography,
  LinearProgress,
  Fade,
} from '@mui/material';

import { EBodyCellBG, TableColumn } from './TableTypes';
import { NotFound } from './components/not-found/NotFound';
import { TableLoader } from './components/table-loader/TableLoader';
import { Checkbox } from '@app/components/checkbox/Checkbox';
import { TableCard } from '@app/components/table-card/TableCard';
import { clsxm } from '@app/styles/clsxm';
import { isNumber, isString } from 'lodash';

import styles from './Table.module.scss';

export interface TableProps<T> {
  id?: string;
  idKey?: string;
  notFoundMessage?: string;
  cols: TableColumn<T>[];
  tableData: T[];
  loading?: boolean;
  total?: number;
  currentPage?: number;
  pageSize?: number;
  selectedItems?: T[];
  selectableCells?: boolean;
  showSelectAll?: boolean;
  handleAllSelect?: (selectedItems: T[]) => void;
  handleCellSelect?: (selectedItem: T, isChecked: boolean) => void;
  rightHeaderContent?: React.ReactNode;
  leftHeaderContent?: React.ReactNode;
  footerContent?: React.ReactNode;
  headerContent?: React.ReactNode;
  onPagination?: (page: number) => void;
  currentSort?: string;
  onSortChanged?: (newSort: string) => void;
  currentItem?: T;
  onItemSelect?: (item: T) => void;
  onSizeChange?: (newSize: number) => void;
  headerNameClassName?: string;
  subHeaderNameClassName?: string;
  tableRowClassName?: string;
  tableCellClassName?: string;
  wrapperClassName?: string;
  cardHeaderClassName?: string;
  tableHeadCellClassName?: string;
  tableContainerClassName?: string;
  tableHeaderClassName?: string;
  notFoundClassName?: string;
  hasMore?: boolean;
  onHasMoreClick?: () => void;
  hidePageSize?: boolean;
  customRowClassDynamic?: (rowData: T) => string;
  title?: string;
  pdf?: boolean;
  tableLayout?: 'auto' | 'fixed';
  tableChildren?: JSX.Element;
  renderExpandableIcon?: (isExpanded: boolean, rowData: T) => ReactNode;
}

export function Table<T extends { [key: string]: any }>({
  id,
  idKey = 'id',
  notFoundMessage,
  cols,
  tableData = [],
  loading,
  leftHeaderContent,
  rightHeaderContent,
  footerContent,
  currentPage,
  total = 0,
  selectedItems,
  selectableCells,
  showSelectAll,
  handleAllSelect,
  handleCellSelect,
  pageSize = 10,
  onPagination,
  currentSort,
  onSortChanged,
  currentItem,
  onItemSelect,
  hasMore,
  onHasMoreClick,
  onSizeChange,
  headerContent,
  headerNameClassName,
  subHeaderNameClassName,
  tableHeadCellClassName,
  tableContainerClassName,
  tableHeaderClassName,
  wrapperClassName,
  cardHeaderClassName,
  tableCellClassName,
  tableRowClassName,
  notFoundClassName,
  hidePageSize,
  customRowClassDynamic,
  title,
  pdf,
  tableLayout = 'auto',
  tableChildren,
  renderExpandableIcon,
}: React.PropsWithChildren<TableProps<T>>) {
  const [expandedIds, setExpandedIds] = useState<Array<string | number>>([]);

  const toggleExpanded = (newId: string | number) => {
    setExpandedIds((prevIds) => (prevIds.includes(newId) ? prevIds.filter((id) => id !== newId) : [...prevIds, newId]));
  };

  const renderCell = useCallback(
    ({ render, field, bold, minWidth, align }: TableColumn<T>, data: T, index: number) => {
      if (render) {
        return (
          <span
            className={clsxm(bold ? 'font-semibold' : undefined)}
            style={{ minWidth: pdf ? undefined : minWidth, textAlign: align }}
          >
            {render(data, index)}
          </span>
        );
      }

      if ((data && isNumber(data[field])) || isString(data[field])) {
        return (
          <span
            className={clsxm(bold ? 'font-semibold' : undefined)}
            style={{ minWidth: pdf ? undefined : minWidth, textAlign: align }}
          >
            {data[field]}
          </span>
        );
      }

      return null;
    },
    [pdf]
  );

  const renderSortIcon = useCallback((sortDirection?: string) => {
    if (!sortDirection) {
      return null;
    }
    return (
      <ExpandMoreIcon
        className={clsx(styles.SortIcon, {
          [styles.SortIconDown]: sortDirection === 'asc',
        })}
      />
    );
  }, []);

  const isExpandableTable = tableData.find((data, index) =>
    cols.find(({ isExpandable }) => isExpandable?.(data, index))
  );

  return (
    <>
      {title ? <Typography className="mb-2 font-semibold">{title}</Typography> : null}

      <TableCard
        total={total}
        wrapperClassName={wrapperClassName}
        cardHeaderClassName={cardHeaderClassName}
        pageSize={pageSize}
        currentPage={currentPage}
        leftHeaderContent={leftHeaderContent}
        rightHeaderContent={rightHeaderContent}
        footerContent={footerContent}
        headerContent={headerContent}
        onPagination={onPagination}
        hasMore={hasMore}
        onHasMoreClick={onHasMoreClick}
        onSizeChange={onSizeChange}
        hidePageSize={hidePageSize}
      >
        <div
          className={clsx(
            styles.TableContent,
            pdf && styles.pdf,
            !headerContent && !leftHeaderContent && !rightHeaderContent && styles.NoHeaderContent,
            loading ? styles.Loading : null
          )}
        >
          <TableContainer className={clsxm(styles.TableContainer, tableContainerClassName)}>
            {!!loading && !hasMore && <TableLoader />}

            <MuiTable style={{ tableLayout }} id={id}>
              {tableChildren}
              <TableHead className={clsxm(styles.TableHead, tableHeaderClassName)}>
                <MuiTableRow>
                  {cols.map((col) => {
                    const [sortField, sortDirection] = currentSort ? currentSort.split(',') : [];

                    const headAlign = col.headAlign || col.align;
                    return (
                      <TableCell
                        key={`${col.headerName}_${col.field}`}
                        align={headAlign}
                        width={col.width}
                        className={clsx(
                          styles.TableCell,
                          styles.TableHeadCell,
                          tableCellClassName,
                          {
                            [styles.TableHeadDisabledSort]: col.disableSort,
                            [styles.YellowBg]: col.headerCellBg === EBodyCellBG.YELLOW,
                            [styles.GrayBg]: col.headerCellBg === EBodyCellBG.GRAY,
                            [styles.WhiteBg]: col.headerCellBg === EBodyCellBG.WHITE,
                            [styles.StickyCell]: col.sticky,
                            [styles.StickyCellRight]: col.sticky === 'right',
                            [styles.StickyCellLeft]: col.sticky === 'left',
                          },
                          col.headCellClassName,
                          tableHeadCellClassName
                        )}
                        onClick={() => {
                          if (onSortChanged && !col.disableSort) {
                            let newDirection = 'asc';

                            if (sortField === col.field) {
                              if (sortDirection === 'asc') {
                                newDirection = 'desc';
                              } else if (!sortDirection) {
                                newDirection = 'asc';
                              } else if (sortDirection === 'desc') {
                                newDirection = '';
                              }
                            }
                            onSortChanged(newDirection ? `${col.field},${newDirection}` : '');
                          }
                        }}
                      >
                        <Typography variant="body2" className={clsx(styles.HeaderName, headerNameClassName)}>
                          {sortField && sortField === col.field && headAlign === 'right'
                            ? renderSortIcon(sortDirection)
                            : null}
                          {col.headerJSX || col.headerName}
                          {sortField && sortField === col.field && headAlign !== 'right'
                            ? renderSortIcon(sortDirection)
                            : null}
                        </Typography>
                        {col?.subHeaderName && (
                          <Typography variant="body2" className={clsx(styles.SubHeaderName, subHeaderNameClassName)}>
                            {col.subHeaderName}
                          </Typography>
                        )}
                      </TableCell>
                    );
                  })}
                  {/* Placeholder for the collapse icon */}
                  {isExpandableTable && (
                    <TableCell
                      align="right"
                      width={renderExpandableIcon ? 44 : 24}
                      className={clsx(
                        styles.TableCell,
                        styles.TableHeadCell,
                        tableCellClassName,
                        tableHeadCellClassName,
                        renderExpandableIcon ? 'w-11' : 'w-6'
                      )}
                    />
                  )}
                </MuiTableRow>
              </TableHead>
              <TableBody>
                {showSelectAll && tableData.length > 0 && (
                  <>
                    <MuiTableRow className={clsx(styles.TableBodyRow)}>
                      {cols.map((col, colIndex) => (
                        <TableCell
                          key={`${col.headerName}_${col.field}`}
                          className={clsx(
                            styles.TableCell,
                            {
                              [styles.StickyCell]: col.sticky,
                              [styles.StickyCellRight]: col.sticky === 'right',
                              [styles.StickyCellLeft]: col.sticky === 'left',
                            },
                            tableCellClassName
                          )}
                        >
                          {colIndex === 0 && handleAllSelect && (
                            <div className={styles.SelectAllWrapper}>
                              <Checkbox
                                checked={!!tableData.length && selectedItems?.length === tableData.length}
                                className={styles.Checkbox}
                                color="primary"
                                disableRipple
                                onClick={() => handleAllSelect(tableData)}
                              />
                              <Typography className={styles.SelectAllText} variant="subtitle2">
                                Select All
                              </Typography>
                            </div>
                          )}
                        </TableCell>
                      ))}
                    </MuiTableRow>
                  </>
                )}

                {tableData.map((data, dataIndex) => {
                  const isExpandableRow = isExpandableTable
                    ? cols.find(({ isExpandable }) => isExpandable?.(data, dataIndex))
                    : false;

                  const isExpandedRow = expandedIds.includes(dataIndex);

                  return (
                    <Fragment key={data[idKey] || dataIndex}>
                      <MuiTableRow
                        className={clsxm(
                          styles.TableBodyRow,
                          tableRowClassName,
                          {
                            [styles.TableBodyRowSelectable]: onItemSelect,
                            [styles.TableBodyRowSelected]: currentItem ? currentItem[idKey] === data[idKey] : false,
                          },
                          isExpandedRow && 'border-b-0',
                          isExpandableRow && 'hover:cursor-pointer hover:bg-gray-50',
                          customRowClassDynamic ? customRowClassDynamic(data) : false
                        )}
                        onClick={() => {
                          if (isExpandableRow) {
                            toggleExpanded(dataIndex);
                          }

                          if (onItemSelect) {
                            onItemSelect(data);
                          }
                        }}
                      >
                        {cols.map((col, colIndex) => {
                          // FOR SELECTIONS ONLY  START

                          const makeCheck = selectableCells && colIndex === 0;
                          let checked = false;

                          if (makeCheck) {
                            const found = selectedItems?.findIndex((v) => v[idKey] === data[idKey]) ?? -1;
                            if (found > -1) {
                              checked = true;
                            }
                          }

                          // FOR SELECTIONS ONLY END

                          return (
                            <TableCell
                              key={`${col.headerName}_${col.field}`}
                              align={col.align}
                              onClick={col.disableClick ? (e) => e.stopPropagation() : undefined}
                              className={clsx(
                                styles.TableCell,
                                styles.TableBodyCell,
                                {
                                  [styles.DisableClick]: col.disableClick,
                                  [styles.YellowBg]: col.bodyCellBg === EBodyCellBG.YELLOW,
                                  [styles.GrayBg]: col.bodyCellBg === EBodyCellBG.GRAY,
                                  [styles.StickyCell]: col.sticky,
                                  [styles.StickyCellRight]: col.sticky === 'right',
                                  [styles.StickyCellLeft]: col.sticky === 'left',
                                },
                                col.bodyCellClassName,
                                tableCellClassName,
                                isExpandedRow && 'pb-0'
                              )}
                            >
                              {selectableCells && colIndex === 0 ? (
                                <div className={styles.SelectAllWrapper}>
                                  <Checkbox
                                    checked={checked}
                                    className={styles.Checkbox}
                                    color="primary"
                                    disableRipple
                                    onClick={() => handleCellSelect && handleCellSelect(data, checked)}
                                  />
                                  {renderCell(col, data, dataIndex)}
                                </div>
                              ) : (
                                renderCell(col, data, dataIndex)
                              )}
                            </TableCell>
                          );
                        })}
                        {isExpandableRow && (
                          <TableCell
                            align="right"
                            className={clsx(
                              styles.TableCell,
                              styles.TableBodyCell,
                              tableCellClassName,
                              'w-6 cursor-pointer pl-0',
                              isExpandedRow && 'pb-0'
                            )}
                          >
                            {renderExpandableIcon ? (
                              renderExpandableIcon(isExpandedRow, data)
                            ) : isExpandedRow ? (
                              <ExpandLessIcon />
                            ) : (
                              <ExpandMoreIcon />
                            )}
                          </TableCell>
                        )}
                      </MuiTableRow>

                      {isExpandedRow && (
                        <MuiTableRow
                          className={clsx(
                            styles.TableBodyRow,
                            tableRowClassName,
                            {
                              [styles.TableBodyRowSelectable]: onItemSelect,
                              [styles.TableBodyRowSelected]: currentItem ? currentItem[idKey] === data[idKey] : false,
                            },
                            customRowClassDynamic ? customRowClassDynamic(data) : false,
                            'border-t-0'
                          )}
                          onClick={() => {
                            if (onItemSelect) {
                              onItemSelect(data);
                            }
                          }}
                        >
                          {cols.map((col) => {
                            return (
                              <TableCell
                                key={`${col.headerName}_${col.field}`}
                                align={col.align}
                                onClick={col.disableClick ? (e) => e.stopPropagation() : undefined}
                                className={clsx(
                                  styles.TableCell,
                                  styles.TableBodyCell,
                                  {
                                    [styles.DisableClick]: col.disableClick,
                                    [styles.YellowBg]: col.bodyCellBg === EBodyCellBG.YELLOW,
                                    [styles.GrayBg]: col.bodyCellBg === EBodyCellBG.GRAY,
                                    [styles.StickyCell]: col.sticky,
                                    [styles.StickyCellRight]: col.sticky === 'right',
                                    [styles.StickyCellLeft]: col.sticky === 'left',
                                  },
                                  col.bodyCellClassName,
                                  tableCellClassName,
                                  'pt-0'
                                )}
                              >
                                {col.renderCollapsedContent?.(data, dataIndex)}
                              </TableCell>
                            );
                          })}
                        </MuiTableRow>
                      )}
                    </Fragment>
                  );
                })}
                {!loading && !tableData.length && (
                  <MuiTableRow className={clsxm(styles.TableBodyRow, 'border-none')}>
                    <TableCell colSpan={cols.length} className="border-none">
                      <NotFound className={notFoundClassName} notFoundMessage={notFoundMessage} />
                    </TableCell>
                  </MuiTableRow>
                )}
              </TableBody>
            </MuiTable>
            <Fade in={loading && hasMore} unmountOnExit>
              <LinearProgress className={styles.LinearLoader} />
            </Fade>
          </TableContainer>
        </div>
      </TableCard>
    </>
  );
}
