import { createContext, FC, ReactNode, useContext, useEffect, useMemo } from 'react';

import { useHandler } from '@app/hooks/useHandler.hook';

import { useClaimFiltersSessionStorage } from '../hooks/useClaimFiltersSessionStorage';
import { usePolicyNumberQueryParam } from '../hooks/usePolicyNumberQueryParam';
import { useSharedClaimFiltersSessionStorage } from '../hooks/useSharedClaimFiltersSessionStorage';
import { DEFAULT_CLAIM_FILTER_VALUES } from '@app/domain/claim/const/claims.filter.const';
import { useFilterIdQueryParam } from '@app/domain/claim/hooks/useFilterIdQueryParam';
import { createFiltersDto } from '@app/domain/claim/utils/claim.filter.utils';
import { ClaimFilter } from '@app/swagger-types';

const ClaimFiltersContext = createContext({
  hasChanges: false,
  countChanges: 0,
  countDrawerChanges: 0,
  filters: DEFAULT_CLAIM_FILTER_VALUES,
  setFilters: (_: ClaimFilter) => {},
  sharedFilters: DEFAULT_CLAIM_FILTER_VALUES,
  setSharedFilters: (_: ClaimFilter) => {},
  filtersDto: {} as ClaimFilter,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChangeFilterParam: (key: keyof ClaimFilter, _: ClaimFilter[typeof key]) => {},
  onResetFilters: () => {},
  onSubmitFilterParams: (_: ClaimFilter) => {},
});

interface Props {
  children: ReactNode;
}

export const ClaimFiltersContextProvider: FC<Props> = ({ children }) => {
  const [filterIdParam, setFilterIdParam] = useFilterIdQueryParam();
  const [policyNumberParam, setPolicyNumberParam] = usePolicyNumberQueryParam();
  const [sharedFilters, setSharedFilters] = useSharedClaimFiltersSessionStorage();
  const [filters, setFilters] = useClaimFiltersSessionStorage();

  const visibleFilters: ClaimFilter = useMemo(
    () => (filterIdParam ? sharedFilters : filters),
    [filterIdParam, sharedFilters, filters]
  );

  const filtersDto: ClaimFilter = useMemo(() => createFiltersDto(visibleFilters), [visibleFilters]);

  const countChanges = useMemo(() => {
    return (
      Object.entries(visibleFilters as Partial<ClaimFilter>)
        // check if any not default value
        .filter(
          ([key, value]) =>
            JSON.stringify(value) !== JSON.stringify(DEFAULT_CLAIM_FILTER_VALUES[key as keyof ClaimFilter])
        ).length
    );
  }, [visibleFilters]);

  const countDrawerChanges = useMemo(() => {
    return (
      Object.entries(visibleFilters as Partial<ClaimFilter>)
        // exclude fields from base filter
        .filter(([key]) => !['policyNumber', 'nameInsured', 'claimNumber', 'claimant'].includes(key))
        // check if any not default value
        .filter(
          ([key, value]) =>
            JSON.stringify(value) !== JSON.stringify(DEFAULT_CLAIM_FILTER_VALUES[key as keyof ClaimFilter])
        ).length
    );
  }, [visibleFilters]);

  const hasChanges = Boolean(countChanges);

  const removeFilterIdParam = useHandler(() => {
    setFilterIdParam(undefined, 'pushIn');
  });

  const onChangeFilterParam = useHandler((key: keyof ClaimFilter, value: ClaimFilter[typeof key]) => {
    setFilters({ ...visibleFilters, [key]: value });
    removeFilterIdParam();
  });

  const onResetFilters = useHandler(() => {
    setFilters(DEFAULT_CLAIM_FILTER_VALUES);
    removeFilterIdParam();
  });

  const onSubmitFilterParams = useHandler((values: ClaimFilter) => {
    setFilters(values);
    removeFilterIdParam();
  });

  useEffect(() => {
    if (policyNumberParam && !filterIdParam) {
      setFilters({
        ...DEFAULT_CLAIM_FILTER_VALUES,
        policyNumber: [policyNumberParam],
      });
      setPolicyNumberParam(undefined, 'replace');
    }
  }, [policyNumberParam, filterIdParam, setFilters, setPolicyNumberParam]);

  const value = useMemo(
    () => ({
      hasChanges,
      countChanges,
      countDrawerChanges,
      filters: visibleFilters,
      sharedFilters,
      setFilters,
      filtersDto,
      onChangeFilterParam,
      onResetFilters,
      onSubmitFilterParams,
      setSharedFilters,
    }),
    [
      setFilters,
      filtersDto,
      onChangeFilterParam,
      onResetFilters,
      onSubmitFilterParams,
      setSharedFilters,
      visibleFilters,
      sharedFilters,
      hasChanges,
      countChanges,
      countDrawerChanges,
    ]
  );

  return <ClaimFiltersContext.Provider value={value}>{children}</ClaimFiltersContext.Provider>;
};

export const useClaimFiltersContext = () => useContext(ClaimFiltersContext);
