import { FC } from 'react';

import { FormProvider, UseFormReturn } from 'react-hook-form';
import { Skeleton } from '@mui/material';

import { SubmissionInsightsTab } from './SubmissionInsightsTab';

import { SubmissionRiskFactorsTab } from './SubmissionRiskFactorsTab';
import { HAS_ACCESS_TO_SUBMISSION_TABS } from '@app/environment/typed-env';
import { SubmissionLossSummaryProvider } from '../contexts/SubmissionLossSummaryContext';
import { SubmissionNotesProvider } from '../contexts/SubmissionNotesContext';
import { ESubmissionTab, useSubmissionTabQueryParam } from '../hooks/useSubmissionTabQueryParam';
import { AwsCalcValidationSchema, FullCalcValidationSchemaType } from '@app/domain/sti/forms/calc.form';
import { SubmissionCalculatorLastMileTab } from './SubmissionCalculatorLastMileTab';
import { SubmissionCalculatorStandardTab } from './SubmissionCalculatorStandardTab';
import { SubmissionLoadingOverlay } from '../components/SubmissionLoadingOverlay';
import { SubmissionLossSummaryTab } from './SubmissionLossSummaryTab';
import { LoadingOverlayState } from '../hooks/useSubmissionLoadingOverlay';
import { SubmissionNavigationBlocker } from './SubmissionNavigationBlocker';
import { UseSubmissionStiReturn } from '../hooks/useSubmissionSti';
import { SubmissionOverviewTab } from './SubmissionOverviewTab';
import { useHasAccess } from '@app/auth-roles-access';

export enum ESubmissionSafeTierRatingTab {
  STANDARD = 'Standard',
  LAST_MILE = 'Last Mile',
}

interface Props {
  submissionId: string;
  setInsightsIndicated: (value: boolean) => void;
  fullFormMethods: UseFormReturn<FullCalcValidationSchemaType>;
  awsFormMethods: UseFormReturn<AwsCalcValidationSchema>;
  sti: UseSubmissionStiReturn;
  overlayState: LoadingOverlayState;
}

export const SubmissionTabs: FC<Props> = ({
  setInsightsIndicated,
  fullFormMethods,
  awsFormMethods,
  submissionId,
  overlayState,
  sti,
}) => {
  const [tab] = useSubmissionTabQueryParam();
  const userHasAccessToInsights = useHasAccess('SUBMISSION_INSIGHTS_TAB');

  return (
    <SubmissionNotesProvider>
      <div className="relative size-full">
        {overlayState[tab] && <SubmissionLoadingOverlay tab={tab} />}
        {HAS_ACCESS_TO_SUBMISSION_TABS && (
          <>
            {tab === ESubmissionTab.OVERVIEW && (
              <SubmissionOverviewTab
                fullPrefillValues={sti.fullPrefillValues}
                awsPrefillValues={sti.awsPrefillValues}
                submissionId={submissionId}
              />
            )}
            <SubmissionLossSummaryProvider submissionId={submissionId} onResetForm={sti.handleResetFullCalc}>
              {tab === ESubmissionTab.LOSS_SUMMARY && <SubmissionLossSummaryTab submissionId={submissionId} />}
            </SubmissionLossSummaryProvider>

            {tab === ESubmissionTab.RISK_FACTORS && <SubmissionRiskFactorsTab submissionId={submissionId} />}
            {tab === ESubmissionTab.SAFE_TIER_RATING && (
              <>
                {sti.isFullCalcPrefillFetching || sti.isOutcomeGiDataFetching || sti.isOutcomeFetching ? (
                  <div className="flex flex-col gap-8">
                    <Skeleton variant="rounded" width="100%" height={132} />
                    <Skeleton variant="rounded" width="100%" height={600} />
                  </div>
                ) : (
                  <>
                    {sti.calcTab === ESubmissionSafeTierRatingTab.STANDARD && (
                      <FormProvider {...fullFormMethods}>
                        <SubmissionCalculatorStandardTab submissionId={submissionId} />
                      </FormProvider>
                    )}
                    {sti.calcTab === ESubmissionSafeTierRatingTab.LAST_MILE && (
                      <FormProvider {...awsFormMethods}>
                        <SubmissionCalculatorLastMileTab submissionId={submissionId} />
                      </FormProvider>
                    )}
                  </>
                )}
              </>
            )}
            {userHasAccessToInsights && tab === ESubmissionTab.INSIGHTS && (
              <SubmissionInsightsTab setInsightsIndicated={setInsightsIndicated} submissionId={submissionId} />
            )}
          </>
        )}
        <SubmissionNavigationBlocker
          fullFormValues={sti.getFullFormValues()}
          awsFormValues={sti.getAwsFormValues()}
          isFullFormDirty={sti.isFullFormDirty}
          isAwsFormDirty={sti.isAwsFormDirty}
          submissionId={submissionId}
        />
      </div>
    </SubmissionNotesProvider>
  );
};
