import { capitalize, upperCase } from 'lodash';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReplayIcon from '@mui/icons-material/Replay';
import Inventory2SharpIcon from '@mui/icons-material/Inventory2Sharp';

import { EUserStatus, EUserConfirmationStatus, UserOutCompactDto } from '@app/swagger-types';
import { IconButton } from '@app/components/buttons/icon-button/IconButton';
import { Tooltip } from '@app/components/tooltip/Tooltip';
import { CircularProgress } from '@mui/material';

export const renderFriendlyUserTableConfirmationStatusLabel = ({
  status,
  onReset,
  isResetting,
  disabled,
}: {
  status: EUserConfirmationStatus;
  onReset: () => void;
  isResetting?: boolean;
  disabled?: boolean;
}) => {
  switch (status) {
    case EUserConfirmationStatus.CONFIRMED:
      return (
        <>
          <span>Confirmed</span>
          <CheckCircleIcon className="fill-success" />
        </>
      );
    case EUserConfirmationStatus.FORCE_CHANGE_PASSWORD:
      return (
        <>
          <span className="text-error">Pending</span>
          <Tooltip title="Resend Invitation">
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                onReset();
              }}
              disabled={disabled}
            >
              {isResetting ? <CircularProgress size={20} /> : <ReplayIcon className=" size-5" />}
            </IconButton>
          </Tooltip>
        </>
      );
    case EUserConfirmationStatus.RESET_REQUIRED:
      return (
        <>
          <span className="text-error">Reset Required</span>
          <Tooltip title="Send Reset Email">
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                onReset();
              }}
              disabled={disabled}
            >
              {isResetting ? <CircularProgress size={20} /> : <ReplayIcon className=" size-5" />}
            </IconButton>
          </Tooltip>
        </>
      );
    case EUserConfirmationStatus.ARCHIVED:
      return (
        <>
          <span>Archived</span>
          <Inventory2SharpIcon className="fill-gray-600" />
        </>
      );
    case EUserConfirmationStatus.UNCONFIRMED:
      return 'Unconfirmed';
    case EUserConfirmationStatus.EXTERNAL_PROVIDER:
      return 'External Provider';
    case EUserConfirmationStatus.UNKNOWN:
      return 'Unknown';
    default:
      return status;
  }
};

export const renderFriendlyUserConfirmationStatusLabel = (status: EUserConfirmationStatus) => {
  switch (status) {
    case EUserConfirmationStatus.CONFIRMED:
      return 'Confirmed';
    case EUserConfirmationStatus.FORCE_CHANGE_PASSWORD:
      return 'Force Change Password';
    case EUserConfirmationStatus.RESET_REQUIRED:
      return 'Reset Required';
    case EUserConfirmationStatus.UNCONFIRMED:
      return 'Unconfirmed';
    case EUserConfirmationStatus.ARCHIVED:
      return 'Archived';
    case EUserConfirmationStatus.EXTERNAL_PROVIDER:
      return 'External Provider';
    case EUserConfirmationStatus.UNKNOWN:
      return 'Unknown';
    default:
      return status;
  }
};

export const getUserConfirmationStatusColor = (status: EUserConfirmationStatus) => {
  switch (status) {
    case EUserConfirmationStatus.CONFIRMED:
      return '#2E7D32';
    case EUserConfirmationStatus.RESET_REQUIRED:
    case EUserConfirmationStatus.ARCHIVED:
    case EUserConfirmationStatus.EXTERNAL_PROVIDER:
    case EUserConfirmationStatus.UNKNOWN:
      return '#737373';
    case EUserConfirmationStatus.UNCONFIRMED:
    case EUserConfirmationStatus.FORCE_CHANGE_PASSWORD:
      return '#D32F2F';
    default:
      return '#737373';
  }
};

export const renderFriendlyUserStatusLabel = (status: EUserStatus) => {
  switch (status) {
    case EUserStatus.DISABLED:
      return 'Disabled';
    case EUserStatus.ENABLED:
      return 'Enabled';
    default:
      return status;
  }
};

export const renderUserStatusColor = (status: EUserStatus) => {
  switch (status) {
    case EUserStatus.DISABLED:
      return '#E60000';
    case EUserStatus.ENABLED:
      return '#1F2937';
    default:
      return '#1F2937';
  }
};

export const renderFriendlyUserPoolName = (userPoolName: string) => {
  if (userPoolName === 'usisa') {
    return upperCase(userPoolName);
  }
  return capitalize(userPoolName);
};

export const renderUserCompactName = ({ firstName = '', lastName = '', username }: UserOutCompactDto) =>
  firstName || lastName ? `${firstName} ${lastName}` : username;
