import { Box, CircularProgress, Typography } from '@mui/material';
import { FC } from 'react';

interface Props {
  value: number;
  size?: number;
  thickness?: number;
}

export const StiScore: FC<Props> = ({ value, size = 100, thickness = 5 }) => (
  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
    <CircularProgress
      sx={{ '.MuiCircularProgress-circle': { strokeLinecap: 'round' } }}
      variant="determinate"
      thickness={thickness}
      value={value}
      size={size}
    />
    <Box
      justifyContent="center"
      position="absolute"
      alignItems="center"
      display="flex"
      bottom={0}
      right={0}
      left={0}
      top={0}
    >
      <div className="flex flex-col items-center justify-center">
        <Typography className="mt-3 text-[36px] font-medium leading-8 text-secondary">{Math.round(value)}</Typography>
        <Typography className="text-[10px] text-[#00000099]">STI</Typography>
      </div>
    </Box>
  </Box>
);
