import { FC, useMemo } from 'react';

import { clsxm } from '@app/styles/clsxm';
import { Table, TableColumn } from '@app/components/table';
import { NumericFormat } from 'react-number-format';
import { useTrirDartCalculations } from '../hooks/useTrirDartCalculations';
import { SubmissionLossSummaryOutDto } from '@app/swagger-types';

type DataRowType = {
  period: string;
  trir: number;
  dart: number;
  isTotal?: boolean;
};

interface Props {
  totalHoursWorked: number;
  lossSummaryData: SubmissionLossSummaryOutDto;
}

export const TrirDartTable: FC<Props> = ({ lossSummaryData, totalHoursWorked }) => {
  const { lastYearsData, averageDart, averageTrir } = useTrirDartCalculations(lossSummaryData, totalHoursWorked);

  const cols: TableColumn<DataRowType>[] = useMemo(
    () => [
      {
        headerName: 'Period',
        field: 'period',
        align: 'left',
        render: ({ period, isTotal }) => {
          return <span className={clsxm('text-xs', isTotal && 'font-semibold')}>{period}</span>;
        },
      },
      {
        headerName: 'TRIR',
        field: 'trir',
        align: 'left',
        width: 150,
        render: ({ trir, isTotal }) => (
          <NumericFormat
            value={trir}
            displayType="text"
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
            className={clsxm('text-xs', isTotal && 'font-semibold')}
          />
        ),
      },
      {
        headerName: 'DART',
        field: 'dart',
        align: 'left',
        width: 150,
        render: ({ dart, isTotal }) => (
          <NumericFormat
            value={dart}
            displayType="text"
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
            className={clsxm('text-xs', isTotal && 'font-semibold')}
          />
        ),
      },
    ],
    []
  );

  const tableData: DataRowType[] = useMemo(
    () => [
      {
        period: 'Current',
        trir: lastYearsData[0]?.trir || 0,
        dart: lastYearsData[0]?.dart || 0,
      },
      {
        period: '1st Prior',
        trir: lastYearsData[1]?.trir || 0,
        dart: lastYearsData[1]?.dart || 0,
      },
      {
        period: '2nd Prior',
        trir: lastYearsData[2]?.trir || 0,
        dart: lastYearsData[2]?.dart || 0,
      },
      {
        period: '3 Year Avg',
        trir: averageTrir,
        dart: averageDart,
        isTotal: true,
      },
    ],
    [lastYearsData, averageTrir, averageDart]
  );

  return <Table cols={cols} tableData={tableData} hidePageSize wrapperClassName="w-full border-b border-primary" />;
};
