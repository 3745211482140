import { FormProvider } from 'react-hook-form';
import { Card, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import { IconButton } from '@app/components/buttons/icon-button/IconButton';
import { Tooltip } from '@app/components/tooltip/Tooltip';
import { SubmissionOverviewProfileEditForm } from './SubmissionOverviewProfileEditForm';
import { SubmissionOverviewProfileView } from './SubmissionOverviewProfileView';
import { useSubmissionOverviewProfileForm } from '../hooks/useSubmissionOverviewForm';
import { useHandler } from '@app/hooks/useHandler.hook';
import { SubmissionOverviewInDto, SubmissionOverviewOutDto } from '@app/swagger-types';
import { useUpdateSubmissionOverviewMutation } from '../api/submission.api.hooks';
import { useConfirmSubmissionOverviewChangeDialog } from '../modals/ConfirmSubmissionOverviewChangeDialog';
import { useSubmissionCalculatorFormsContext } from '../contexts/SubmissionCalculatorFormsContext';
import { SubmissionUtils } from '../utils/submission.utils';

interface Props {
  overviewData?: SubmissionOverviewOutDto;
  submissionId: string;
}

export const SubmissionOverviewProfile: FC<Props> = ({ submissionId, overviewData }) => {
  const [isDetailsEditing, setDetailsEditing] = useState(false);

  const { naics, onChangeFullCalcResult, onChangeAwsCalcResult, fullCalcResult } =
    useSubmissionCalculatorFormsContext();

  const confirmDialog = useConfirmSubmissionOverviewChangeDialog();

  const { reset, getValues, ...form } = useSubmissionOverviewProfileForm();

  const { mutateAsync: updateOverview, isLoading: isUpdatingOverview } = useUpdateSubmissionOverviewMutation(
    submissionId,
    {
      onSuccess: () => {
        onChangeFullCalcResult(undefined);
        onChangeAwsCalcResult(undefined);
      },
      onSettled: () => setDetailsEditing(false),
    }
  );

  const handleStartEditing = useHandler(() => setDetailsEditing(true));

  const handleDiscardChanges = useHandler(() => {
    if (overviewData) {
      const naicsItem = naics?.result.find((item) => item.naics === overviewData.naics) || null;

      reset({
        domiciledState: overviewData.domiciledState || '',
        operationStates: SubmissionUtils.normalizeOperationStates(overviewData.operationStates || ''),
        exModFactor: overviewData.exModFactor || 0,
        hazardGrade: overviewData.hazardGrade || '',
        governingClassCodeNcciId: overviewData.governingClassCodeNcciId || null,
        naics: naicsItem || null,
        operationalNotes: overviewData.operationalNotes || '',
      });
    }

    setDetailsEditing(false);
  });

  const handleSaveChanges = useHandler(async () => {
    const {
      naics: naicsValue,
      domiciledState,
      operationStates,
      operationalNotes,
      exModFactor,
      governingClassCodeNcciId,
    } = getValues();

    const naicsItem = naics?.result.find((item) => item.naics === naicsValue?.naics)?.naics || '';

    const updatedOverview: SubmissionOverviewInDto = {
      domiciledState: domiciledState || '',
      operationStates: operationStates || '',
      exModFactor: exModFactor || 0,
      operationalNotes: operationalNotes || '',
      governingClassCodeNcciId: governingClassCodeNcciId || undefined,
      naics: naicsItem,
    };

    const editedExModFactorOrNaics =
      exModFactor !== overviewData?.exModFactor || naicsValue?.naics !== overviewData?.naics;

    if (fullCalcResult && editedExModFactorOrNaics) {
      confirmDialog.open({
        onCancel: handleDiscardChanges,
        onConfirm: () => {
          updateOverview(updatedOverview);
        },
      });
    } else {
      await updateOverview(updatedOverview);
    }
  });

  useEffect(() => {
    if (overviewData) {
      const naicsItem = naics?.result.find((item) => item.naics === overviewData.naics) || null;

      reset({
        domiciledState: overviewData.domiciledState || '',
        operationStates: SubmissionUtils.normalizeOperationStates(overviewData.operationStates || ''),
        exModFactor: overviewData.exModFactor || 0,
        hazardGrade: overviewData.hazardGrade || '',
        governingClassCodeNcciId: overviewData.governingClassCodeNcciId || null,
        naics: naicsItem || null,
        operationalNotes: overviewData.operationalNotes || '',
      });
    }
  }, [reset, overviewData, naics]);

  return (
    <Card className="flex w-full flex-col gap-2.5 rounded-lg bg-white p-6 shadow-card @container">
      <div className="flex items-center justify-between">
        <Typography className="py-4 text-xxxl">Submission Profile</Typography>
        {isDetailsEditing ? (
          <div className="flex items-center gap-2">
            <Tooltip placement="top" title="Discard Changes">
              <IconButton onClick={handleDiscardChanges} className="text-gray-500" size="large">
                <CloseIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={!form.formState.isDirty ? 'You need to make changes to save' : 'Save Changes'}
              placement="top"
            >
              <IconButton
                disabled={!form.formState.isDirty}
                onClick={handleSaveChanges}
                className="text-gray-500"
                size="large"
              >
                <CheckIcon />
              </IconButton>
            </Tooltip>
          </div>
        ) : (
          <IconButton onClick={handleStartEditing} className="text-gray-500" size="large">
            <EditNoteIcon />
          </IconButton>
        )}
      </div>
      <FormProvider reset={reset} getValues={getValues} {...form}>
        {isDetailsEditing ? (
          <SubmissionOverviewProfileEditForm isUpdatingOverview={isUpdatingOverview} overviewData={overviewData} />
        ) : (
          <SubmissionOverviewProfileView naics={naics?.result} overviewData={overviewData} />
        )}
      </FormProvider>
    </Card>
  );
};
