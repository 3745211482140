import { EQueryConfigName } from '@app/constants/query-config.const';
import { useGetSubmissionOverviewQuery } from '@app/domain/submission/api/submission.api.hooks';
import { useSubmissionCalculatorFormsContext } from '@app/domain/submission/contexts/SubmissionCalculatorFormsContext';
import { useHandler } from '@app/hooks/useHandler.hook';
import {
  NaicsOutDto,
  NcciClassificationOutDto,
  SubmissionOverviewOutDto,
  SubmissionStiPayrollEntryOutDto,
} from '@app/swagger-types';
import { reorderArrayItem } from '@app/utils/array.utils';
import { useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

type UpdaterType =
  | ((oldOverview: SubmissionOverviewOutDto | undefined) => SubmissionOverviewOutDto | undefined)
  | Partial<SubmissionOverviewOutDto>;

export const useSyncStiCalcWithOverview = (submissionId: string) => {
  const queryClient = useQueryClient();
  const { fullCalcResult } = useSubmissionCalculatorFormsContext();

  // always fetch data for editing
  useGetSubmissionOverviewQuery(submissionId, { keepPreviousData: true, enabled: !fullCalcResult });

  const handleUpdateSubmissionOverview = useHandler((updater: UpdaterType) => {
    // update data only if no calc result
    if (!fullCalcResult) {
      queryClient.setQueryData<SubmissionOverviewOutDto>(
        [EQueryConfigName.GET_SUBMISSION_OVERVIEW, submissionId],
        typeof updater === 'function'
          ? updater
          : (oldOverview) =>
              oldOverview
                ? {
                    ...oldOverview,
                    ...updater,
                  }
                : undefined
      );
    }
  });

  const syncEmrChange = useHandler((exModFactor: number) => {
    handleUpdateSubmissionOverview({ exModFactor });
  });

  const syncNaicsChange = useHandler(({ naics }: NaicsOutDto) => {
    handleUpdateSubmissionOverview({ naics });
  });

  const syncPayrollChange = useHandler((index: number, payrollStr: string) => {
    handleUpdateSubmissionOverview((oldOverview) =>
      oldOverview
        ? {
            ...oldOverview,
            payrollEntries: oldOverview.payrollEntries?.map((payrollEntry, payrollEntryIndex) =>
              payrollEntryIndex === index
                ? { ...payrollEntry, amount: Number(payrollStr.replace(/\$/g, '').replace(/,/g, '')) }
                : payrollEntry
            ),
          }
        : undefined
    );
  });

  const syncStateChange = useHandler((index: number, state: string) => {
    handleUpdateSubmissionOverview((oldOverview) =>
      oldOverview
        ? {
            ...oldOverview,
            payrollEntries: oldOverview.payrollEntries?.map((payrollEntry, payrollEntryIndex) =>
              payrollEntryIndex === index
                ? {
                    ...payrollEntry,
                    state,
                    // reset 'ncci' data
                    ncci: null,
                    ncciUniqueId: null,
                    phraseology: null,
                  }
                : payrollEntry
            ),
          }
        : undefined
    );
  });

  const syncNcciChange = useHandler(
    (index: number, { ncci, uniqueIdentifier, phraseology }: NcciClassificationOutDto) => {
      handleUpdateSubmissionOverview((oldOverview) =>
        oldOverview
          ? {
              ...oldOverview,
              payrollEntries: oldOverview.payrollEntries?.map((payrollEntry, payrollEntryIndex) =>
                payrollEntryIndex === index
                  ? {
                      ...payrollEntry,
                      ncci,
                      phraseology,
                      ncciUniqueId: uniqueIdentifier,
                    }
                  : payrollEntry
              ),
            }
          : undefined
      );
    }
  );

  const syncStateInputDelete = useHandler((index: number) => {
    handleUpdateSubmissionOverview((oldOverview) =>
      oldOverview
        ? {
            ...oldOverview,
            payrollEntries: oldOverview.payrollEntries?.filter(
              (payrollEntry, payrollEntryIndex) => payrollEntryIndex !== index
            ),
          }
        : undefined
    );
  });

  const syncStateInputAdd = useHandler((index: number, state: string) => {
    const newPayrollEntry: SubmissionStiPayrollEntryOutDto = {
      state: state || null,
      ncci: null,
      amount: null,
      zipCode: null,
      ncciUniqueId: null,
      phraseology: null,
    };

    handleUpdateSubmissionOverview((oldOverview) => {
      const newPayrollEntries = [...(oldOverview?.payrollEntries || [])];

      newPayrollEntries.splice(index, 0, newPayrollEntry);

      return oldOverview
        ? {
            ...oldOverview,
            payrollEntries: newPayrollEntries,
          }
        : undefined;
    });
  });

  const syncStateInputReorder = useHandler((sourceIndex: number, destinationIndex: number) => {
    handleUpdateSubmissionOverview((oldOverview) =>
      oldOverview
        ? {
            ...oldOverview,
            payrollEntries: reorderArrayItem(oldOverview.payrollEntries || [], sourceIndex, destinationIndex),
          }
        : undefined
    );
  });

  return useMemo(
    () => ({
      syncEmrChange,
      syncNaicsChange,
      syncStateChange,
      syncNcciChange,
      syncPayrollChange,
      syncStateInputDelete,
      syncStateInputAdd,
      syncStateInputReorder,
    }),
    [
      syncEmrChange,
      syncNaicsChange,
      syncStateChange,
      syncNcciChange,
      syncPayrollChange,
      syncStateInputDelete,
      syncStateInputAdd,
      syncStateInputReorder,
    ]
  );
};
