import { FC } from 'react';

import { Typography } from '@mui/material';

import { ESubmissionTab } from '../hooks/useSubmissionTabQueryParam';
import { clsxm } from '@app/styles/clsxm';

interface Props {
  tab: ESubmissionTab;
}

export const SubmissionLoadingOverlay: FC<Props> = ({ tab }) => (
  <div className="absolute inset-0 z-40 flex flex-col items-center">
    <div className={clsxm('z-40 flex flex-col gap-4', tab === ESubmissionTab.INSIGHTS ? 'mt-28' : 'mt-36')}>
      <div className="flex w-full flex-col items-center justify-center gap-4">
        <div className="flex size-24 animate-spin items-center justify-center rounded-full border-4 border-transparent border-t-primary text-4xl text-primary">
          <div className="text-2xl flex size-20 animate-spin items-center justify-center rounded-full border-4 border-transparent border-t-secondary text-secondary"></div>
        </div>
      </div>
      <Typography className="font-semibold text-secondary" variant="h6">
        Updating based on new documents...
      </Typography>
    </div>
    <div className="absolute inset-0 rounded-md bg-zinc-200 opacity-85" />
  </div>
);
