import { Card, CircularProgress, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined';
import FileUploadOutlined from '@mui/icons-material/FileUploadOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FC, useMemo, useState } from 'react';
import { orderBy } from 'lodash';

import { useDownloadSubmissionUrlMutation, useUploadSubmissionWorkbookMutation } from '../api/submission.api.hooks';
import { parseSortParam } from '@app/utils/api.util';
import { Button } from '@app/components/buttons/button/Button';
import { formatDollars } from '@app/utils/currency/currency.utils';
import { clsxm } from '@app/styles/clsxm';
import { useHandler } from '@app/hooks/useHandler.hook';
import { FileUploadInput } from '@app/components/file-upload-input/FileUploadInput';
import { Table, TableColumn } from '@app/components/table';
import { SubmissionUtils } from '../utils/submission.utils';
import {
  SubmissionLossReviewTotalCarrierOutDto,
  SubmissionVerificationOutDto,
  SubmissionLossReviewOutDto,
} from '@app/swagger-types';
import { SubmissionLossSummarySwitcher } from './SubmissionLossSummarySwitcher';

type VerifyLossRunDataRowType = SubmissionLossReviewTotalCarrierOutDto & {
  isTotal?: boolean;
};

export type SubmissionLossReviewVerifiedColumns =
  | 'numberOfClaims'
  | 'totalExpense'
  | 'totalIncurred'
  | 'totalMedical'
  | 'totalIndemnity'
  | 'totalReserves';

interface Props {
  submissionId: string;
  lossReviewData: SubmissionLossReviewOutDto | undefined;
  isFetching?: boolean;
  submissionVerifiedData: SubmissionVerificationOutDto | undefined;
}

export const VerifyLossRunDataTable: FC<Props> = ({
  submissionVerifiedData,
  lossReviewData,
  submissionId,
  isFetching,
}) => {
  const [sort, setSort] = useState('');

  // const queryClient = useQueryClient();

  // const GET_SUBMISSION_QUERY_KEY = [EQueryConfigName.GET_SUBMISSION_BY_ID, submissionId];

  // const { data: submissionData, isFetching: isFetchingSubmission } = useGetSubmissionByIdQuery(submissionId);

  const { mutate: uploadSubmissionWorkbook, isLoading: isUploading } = useUploadSubmissionWorkbookMutation();

  const { mutate: downloadSubmissionFile, isLoading: isDownloading } = useDownloadSubmissionUrlMutation();

  // const { mutate, isLoading } = useEditSubmissionMutation<{
  //   previousData?: SubmissionOutDto;
  //   newData?: SubmissionOutDto;
  // }>(submissionId, {
  //   onMutate: async (variables) => {
  //     // Cancel any outgoing refetches
  //     // (so they don't overwrite our optimistic update)
  //     await queryClient.cancelQueries({ queryKey: GET_SUBMISSION_QUERY_KEY });

  //     // Snapshot the previous value
  //     const previousData = queryClient.getQueryData<SubmissionOutDto>(GET_SUBMISSION_QUERY_KEY);

  //     const newData = previousData ? { ...previousData, isOnStiResultsWindow: Boolean(variables.isOnStiResultsWindow) } : undefined;

  //     // Optimistically update to the new value
  //     queryClient.setQueryData(GET_SUBMISSION_QUERY_KEY, newData);

  //     // Return a context with the previous and new data
  //     return { previousData };
  //   },
  //   // If the mutation fails, use the context we returned above
  //   onError: (error, variables, context) => {
  //     queryClient.setQueryData(GET_SUBMISSION_QUERY_KEY, context?.previousData);
  //   },
  //   // Always refetch after error or success:
  //   onSettled: () => {
  //     queryClient.invalidateQueries({ queryKey: GET_SUBMISSION_QUERY_KEY });
  //   },
  // });

  // const toggleVerified = useHandler((_: React.ChangeEvent<HTMLInputElement>, verified: boolean) =>
  //   mutate({ verified })
  // );

  const handleUploadWorkbook = useHandler((file: File) => {
    const formData = new FormData();

    formData.append('workbook', file);

    uploadSubmissionWorkbook({ submissionId, formData });
  });

  const cols: TableColumn<VerifyLossRunDataRowType>[] = useMemo(
    () => [
      {
        headerName: 'Carrier',
        field: 'name',
        align: 'left',
        bold: true,
      },
      {
        headerName: 'Coverage Years',
        field: 'coverageStartYear',
        align: 'center',
        bold: true,
        render: ({ coverageStartYear, coverageEndYear, isTotal }) =>
          !isTotal && coverageStartYear && coverageEndYear ? `${coverageStartYear} - ${coverageEndYear}` : '',
      },
      {
        headerName: 'Total Medical',
        field: 'totalMedical',
        align: 'right',
        render: ({ totalMedical, isTotal }) => (
          <span className={clsxm(isTotal && 'font-semibold')}>{formatDollars(totalMedical, 2)}</span>
        ),
      },
      {
        headerName: 'Total Indemnity',
        field: 'totalIndemnity',
        align: 'right',
        render: ({ totalIndemnity, isTotal }) => (
          <span className={clsxm(isTotal && 'font-semibold')}>{formatDollars(totalIndemnity, 2)}</span>
        ),
      },
      {
        headerName: 'Total Expense',
        field: 'totalExpense',
        align: 'right',
        render: ({ totalExpense, isTotal }) => (
          <span className={clsxm(isTotal && 'font-semibold')}>{formatDollars(totalExpense, 2)}</span>
        ),
      },
      {
        headerName: 'Total Incurred',
        field: 'totalIncurred',
        align: 'right',
        bold: true,
        render: ({ totalIncurred, isTotal }) => (
          <span className={clsxm(isTotal && 'font-semibold')}>{formatDollars(totalIncurred, 2)}</span>
        ),
      },
      {
        headerName: 'Total Reserves',
        field: 'totalReserves',
        align: 'right',
        render: ({ totalReserves, isTotal }) => (
          <span className={clsxm(isTotal && 'font-semibold')}>{formatDollars(totalReserves, 2)}</span>
        ),
      },
      {
        headerName: 'Claims',
        field: 'numberOfClaims',
        align: 'right',
        render: ({ numberOfClaims, isTotal }) => (
          <span className={clsxm(isTotal && 'font-semibold')}>{numberOfClaims}</span>
        ),
      },
    ],
    []
  );

  const tableData: VerifyLossRunDataRowType[] = useMemo(() => {
    let data = lossReviewData?.total.submissionTotalCarriers || [];

    const sortParam = sort.length ? parseSortParam(sort) : null;

    if (sortParam?.field && sortParam.order) {
      data = orderBy(data, [sortParam?.field], [sortParam.order]);
    }

    if (data.length) {
      return [
        ...data,
        // TOTAL Row
        {
          isTotal: true,
          name: 'Totals',
          coverageEndYear: 0,
          coverageStartYear: 0,
          numberOfClaims: lossReviewData?.total.numberOfClaims ?? 0,
          totalExpense: lossReviewData?.total.totalExpense ?? 0,
          totalIncurred: lossReviewData?.total.totalIncurred ?? 0,
          totalIndemnity: lossReviewData?.total.totalIndemnity ?? 0,
          totalMedical: lossReviewData?.total.totalMedical ?? 0,
          totalReserves: lossReviewData?.total.totalReserves ?? 0,
        },
      ];
    }

    return data;
  }, [lossReviewData, sort]);

  // const isDisabled = isFetchingSubmission || isFetchingLossReview || isDownloading || isUploading || isLoading;
  const isDisabled = isFetching || isDownloading || isUploading;

  return (
    <Card className="flex w-full flex-col gap-2.5 rounded-lg bg-white p-6 shadow-card">
      <div className="flex items-center justify-between gap-3">
        <div className="flex w-full items-center gap-16">
          <Typography className="py-4 text-xxxl">Loss Summary</Typography>
          <SubmissionLossSummarySwitcher />
        </div>
        <div className="flex items-center gap-6">
          {/* {!submissionData && isFetchingSubmission ? (
            <Skeleton height={30} width={134} variant="rectangular" className="rounded" />
          ) : (
            <div className="flex items-center gap-2">
              {isLoading && <CircularProgress size={16} />}
              <Typography className="text-l font-medium text-black/60">Verified</Typography>
              <Switch disabled={isDisabled} checked={submissionData?.isOnStiResultsWindow || false} onChange={toggleVerified} />
            </div>
          )} */}
          <Button
            variant="text"
            color="secondary"
            className={clsxm('w-40 font-medium', isDownloading && 'text-secondary')}
            disabled={isDisabled}
            onClick={() => downloadSubmissionFile(submissionId)}
            startIcon={
              isDownloading ? <CircularProgress size={18} /> : <FileDownloadOutlined className="text-black/60" />
            }
          >
            {isDownloading ? 'Downloading...' : 'Download to Edit'}
          </Button>
          <Button
            component="label"
            variant="text"
            color="secondary"
            className="w-44 font-medium"
            disabled={isDisabled}
            startIcon={isUploading ? <CircularProgress size={18} /> : <FileUploadOutlined className="text-black/60" />}
          >
            <FileUploadInput onChange={handleUploadWorkbook} />
            {isUploading ? 'Uploading...' : 'Upload Edited Data'}
          </Button>
        </div>
      </div>
      <Table
        cols={cols}
        tableChildren={
          SubmissionUtils.getIsAtLeastOneVerified(submissionVerifiedData) ? (
            <TableHead>
              <TableRow>
                {cols.map((col, index) => {
                  return (
                    <TableCell
                      key={`${col.headerName}_${col.field}`}
                      align={index > 0 ? 'right' : 'left'}
                      className="p-[10px]"
                      width={col.width}
                    >
                      {SubmissionUtils.getIsAtLeastOneVerified(submissionVerifiedData) && index === 0 && (
                        <span className="text-xs font-semibold text-success">AI Verified</span>
                      )}
                      {SubmissionUtils.getIfCarrierVerified(
                        col.field as SubmissionLossReviewVerifiedColumns,
                        submissionVerifiedData
                      ) ? (
                        <CheckCircleIcon fontSize="small" className="fill-green-600" />
                      ) : null}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          ) : undefined
        }
        tableData={tableData}
        loading={isFetching}
        hidePageSize
        notFoundMessage="No claims extracted"
        wrapperClassName="w-full"
        currentSort={sort}
        onSortChanged={setSort}
      />
    </Card>
  );
};
