import { Dispatch, FC, RefObject, SetStateAction, useCallback, useEffect } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { CircularProgress, Typography } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useQueryClient } from '@tanstack/react-query';
import { NumericFormat } from 'react-number-format';
import { nanoid } from 'nanoid';

import { clsxm } from '@app/styles/clsxm';
import { StateSelect } from '@app/domain/sti/components/StateSelect';
import { PayrollClassCodeAutocomplete } from '@app/domain/sti/components/PayrollClassCodeAutocomplete';
import { ReactHookFormNumberField } from '@app/components/fields/react-hook-form-fields/ReactHookFormNumberField';
import { Tooltip } from '@app/components/tooltip/Tooltip';
import { AddIconButton } from '@app/components/buttons/AddIconButton';
import { DeleteIconButton } from '@app/components/buttons/DeleteIconButton';
import { useSubmissionCalculatorFormsContext } from '../contexts/SubmissionCalculatorFormsContext';
import { useHandler } from '@app/hooks/useHandler.hook';
import { Button } from '@app/components/buttons/button/Button';
import { useConfirmSubmissionOverviewChangeDialog } from '../modals/ConfirmSubmissionOverviewChangeDialog';
import { useUpdateSubmissionStiAwsPrefill, useUpdateSubmissionStiFullPrefill } from '../api/submission.api.hooks';
import { EQueryConfigName } from '@app/constants/query-config.const';
import { Input } from '@app/components/fields/input/Input';
import { useConfirmSubmissionOverviewUploadDialog } from '../modals/ConfirmSubmissionOverviewUploadDialog';
import { PAYROLL_FILE_FIELD_KEYS } from '../const/submission.const';
import { showErrorToast } from '@app/utils/toast.utils';
import {
  SubmissionOverviewOutDto,
  SubmissionStiAwsPrefillInDto,
  SubmissionStiFullPrefillInDto,
} from '@app/swagger-types';
import {
  SubmissionOverviewPayrollEntriesParseSchema,
  SubmissionOverviewPayrollEntriesSchemaType,
  SubmissionOverviewPayrollEntryParsedSchemaType,
} from '../schemas/submission.form';
import { SubmissionUtils } from '../utils/submission.utils';

interface Props {
  fullPrefillValues: Omit<SubmissionStiFullPrefillInDto, 'payrollEntries'>;
  awsPrefillValues: Omit<SubmissionStiAwsPrefillInDto, 'payrollEntries'>;
  lastFieldArrayElementRef: RefObject<HTMLDivElement>;
  overviewData?: SubmissionOverviewOutDto;
  setUnmatchedNcci: Dispatch<SetStateAction<Record<number, string>>>;
  unmatchedNcci: Record<number, string>;
  setEditing: (value: boolean) => void;
  submissionId: string;
  isStandard: boolean;
  isEditing: boolean;
}

export const SubmissionOverviewEstimatedAnnualPayrollForm: FC<Props> = ({
  lastFieldArrayElementRef,
  fullPrefillValues,
  awsPrefillValues,
  setUnmatchedNcci,
  unmatchedNcci,
  overviewData,
  submissionId,
  setEditing,
  isStandard,
  isEditing,
}) => {
  const confirmDialog = useConfirmSubmissionOverviewChangeDialog();
  const queryClient = useQueryClient();

  const { fullCalcResult, awsCalcResult, onChangeFullCalcResult, onChangeAwsCalcResult, ncci } =
    useSubmissionCalculatorFormsContext();

  const { mutateAsync: updateFullPrefill, isLoading: isUpdatingFullPrefill } = useUpdateSubmissionStiFullPrefill(
    submissionId,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [EQueryConfigName.GET_SUBMISSION_OVERVIEW, submissionId] });
        queryClient.invalidateQueries({ queryKey: [EQueryConfigName.GET_SUBMISSION_LOSS_SUMMARY, submissionId] });

        if (fullCalcResult) {
          queryClient.invalidateQueries({ queryKey: [EQueryConfigName.GET_SUBMISSION_STI_OUTCOME, submissionId] });

          onChangeFullCalcResult(undefined);
        }
      },
      onSettled: () => setEditing(false),
    }
  );

  const { mutateAsync: updateAwsPrefill, isLoading: isUpdatingAwsPrefill } = useUpdateSubmissionStiAwsPrefill(
    submissionId,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [EQueryConfigName.GET_SUBMISSION_OVERVIEW, submissionId] });

        if (awsCalcResult) {
          queryClient.invalidateQueries({ queryKey: [EQueryConfigName.GET_SUBMISSION_STI_OUTCOME, submissionId] });

          onChangeAwsCalcResult(undefined);
        }
      },
      onSettled: () => setEditing(false),
    }
  );

  const uploadDialog = useConfirmSubmissionOverviewUploadDialog();

  const { setValue, control, getValues } = useForm<SubmissionOverviewPayrollEntriesSchemaType>();

  const { fields = [], remove, append } = useFieldArray({ control, name: 'stateInputs' });

  const stateInputs = useWatch({
    control,
    name: 'stateInputs',
  });

  const handleDeleteRow = useHandler((index: number) => () => {
    setUnmatchedNcci((prev) => {
      const newUnmatchedNcci: Record<number, string> = {};

      Object.entries(prev).forEach(([key, value]) => {
        const numKey = Number(key);
        if (numKey < index) {
          newUnmatchedNcci[numKey] = value;
        } else if (numKey > index) {
          newUnmatchedNcci[numKey - 1] = value;
        }
      });

      return newUnmatchedNcci;
    });

    remove(index);
  });

  const handleSetInitialValues = useCallback(() => {
    if (overviewData?.payrollEntries?.length) {
      const parsedStateInputs = overviewData.payrollEntries.map((entry, index) => {
        const matchedNcci = ncci?.result.find((item) => item.uniqueIdentifier === entry.ncciUniqueId) || null;

        if (!matchedNcci && entry.ncci !== null) {
          setUnmatchedNcci((prevValue) => ({
            ...prevValue,
            [index]: `${entry.state} ${entry.ncci} - ${entry.phraseology}`,
          }));
        }

        return {
          ncci: matchedNcci,
          uniqueIdentifier: entry.ncciUniqueId || '',
          payroll: String(entry.amount) || '',
          rowLocalId: nanoid(),
          stateCode: entry.state || '',
        };
      });

      setValue('stateInputs', parsedStateInputs);
    } else {
      setValue('stateInputs', [
        {
          rowLocalId: nanoid(),
          stateCode: '',
          payroll: '',
          ncci: null,
          uniqueIdentifier: '',
        },
      ]);
    }
  }, [ncci?.result, overviewData?.payrollEntries, setUnmatchedNcci, setValue]);

  const handleAddRow = (index: number) => () => {
    const previousStateCode = stateInputs?.[index].stateCode || '';

    const row = {
      stateCode: previousStateCode || '',
      ncci: null,
      ncciUniqueId: null,
      rowLocalId: nanoid(),
      payroll: '0',
    };

    append(row);

    // Scroll to the last added payroll
    setTimeout(() => {
      if (lastFieldArrayElementRef.current) {
        lastFieldArrayElementRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 10);
  };

  const handlePayrollBlur = (index: number) => (e?: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e?.target.value) {
      let value = e.target.value.replace(/\$/g, '').replace(/,/g, '');
      if (Number.isInteger(Number(value))) {
        value = `${Number(value)}.00`;

        setValue(`stateInputs.${index}.payroll`, value);
      }
    }
  };

  const handleUpdateFullPrefill = useHandler(() => {
    updateFullPrefill({
      ...fullPrefillValues,
      trir: undefined,
      dart: undefined,
      payrollEntries: SubmissionUtils.formatPayrollEntries(getValues()),
    });
  });

  const handleUpdateAwsPrefill = useHandler(() => {
    updateAwsPrefill({
      ...awsPrefillValues,
      payrollEntries: SubmissionUtils.formatPayrollEntries(getValues()),
    });
  });

  const handleEndAndResetEdit = useCallback(() => {
    setUnmatchedNcci({});

    handleSetInitialValues();

    setEditing(false);
  }, [handleSetInitialValues, setEditing, setUnmatchedNcci]);

  const handleSave = useHandler(async () => {
    if ((fullCalcResult && isStandard) || (awsCalcResult && !isStandard)) {
      confirmDialog.open({
        dialogContent: (
          <Typography className="text-s text-gray-600">
            Editing estimated annual payroll affects the SafeTier results. Existing results will be reset and
            you&apos;ll need to re-calculate.
          </Typography>
        ),
        continueText: 'Reset SafeTier',
        onCancel: handleEndAndResetEdit,
        onConfirm: () => {
          if (isStandard && fullCalcResult) {
            handleUpdateFullPrefill();
          } else {
            handleUpdateAwsPrefill();
          }
        },
      });
    } else {
      if (isStandard) {
        handleUpdateFullPrefill();
      } else {
        handleUpdateAwsPrefill();
      }
    }
  });

  const handleParse = useHandler((result: unknown[]) => {
    const parseResult = SubmissionOverviewPayrollEntriesParseSchema.safeParse(result);

    if (!parseResult.success) {
      return showErrorToast(
        <div className="flex flex-col gap-1">
          <Typography className="font-bold">Error reading the file.</Typography>
          <Typography>Please confirm that the format matches the template file.</Typography>
        </div>
      );
    }

    const parsedData = parseResult.data;

    const getMatchedNcci = (item: SubmissionOverviewPayrollEntryParsedSchemaType) =>
      SubmissionUtils.getIsMatchedNcci(
        String(item[PAYROLL_FILE_FIELD_KEYS.classCode]),
        item[PAYROLL_FILE_FIELD_KEYS.phraseology],
        ncci?.result
      );

    const handleUnmatchedNcci = (index: number, item: SubmissionOverviewPayrollEntryParsedSchemaType) => {
      const classCode = item[PAYROLL_FILE_FIELD_KEYS.classCode].toString();

      // Handle parsing leading zero from classCode
      const formattedClassCode = classCode.length === 3 ? `0${classCode}` : classCode;

      setUnmatchedNcci((prev) => ({
        ...prev,
        [index]: `${item.State} ${formattedClassCode} - ${item[PAYROLL_FILE_FIELD_KEYS.phraseology]}`,
      }));
    };

    if (fields.length === 1) {
      const existingRow = fields[0];
      const isEmptyRow = !existingRow.stateCode && !existingRow.payroll && !existingRow.ncci;

      if (isEmptyRow && parsedData.length > 0) {
        const firstParsedItem = parsedData[0];
        const matchedNcci = getMatchedNcci(firstParsedItem);

        if (!matchedNcci) {
          handleUnmatchedNcci(0, firstParsedItem);
        }

        setValue('stateInputs.0', {
          rowLocalId: nanoid(),
          stateCode: firstParsedItem.State,
          payroll: String(firstParsedItem[PAYROLL_FILE_FIELD_KEYS.payroll]),
          uniqueIdentifier: matchedNcci?.uniqueIdentifier || null,
        });

        setValue('stateInputs.0.ncci', matchedNcci || null);
      }
    }

    // Append all remaining parsed items
    parsedData.forEach((item, index) => {
      const adjustedIndex = fields.length + index;
      const matchedNcci = getMatchedNcci(item);

      if (!matchedNcci) {
        handleUnmatchedNcci(adjustedIndex, item);
      }

      append({
        rowLocalId: nanoid(),
        stateCode: item.State,
        payroll: String(item[PAYROLL_FILE_FIELD_KEYS.payroll]),
        ncci: matchedNcci || null,
        uniqueIdentifier: matchedNcci?.uniqueIdentifier || null,
      });
    });

    uploadDialog.close();
  });

  useEffect(() => {
    handleSetInitialValues();
  }, [handleSetInitialValues]);

  const isUpdating = isUpdatingFullPrefill || isUpdatingAwsPrefill;

  const formTotalValue = stateInputs?.reduce((acc, { payroll = 0 }) => acc + Number(payroll), 0) ?? 0;

  const noData = !overviewData?.payrollEntries || overviewData?.payrollEntries?.length === 0;

  const renderTotal = () => {
    if (noData) {
      return '';
    }
    return (
      <NumericFormat
        sx={{ '& .MuiInputBase-input.Mui-disabled': { backgroundColor: '#F5F5F5', color: '#00000099' } }}
        value={formTotalValue}
        thousandSeparator=","
        label="Total Payroll"
        placeholder="Amount"
        customInput={Input}
        className="w-40"
        decimalScale={2}
        prefix="$"
        disabled
      />
    );
  };

  return (
    <div className={clsxm('flex size-full flex-col gap-2', !isEditing && 'hidden')}>
      <div className="flex h-[54px] items-center justify-between gap-2">
        <Typography className="text-xxxl">Estimated Annual Payroll</Typography>
        <h3 className="pr-4 text-xxl">{renderTotal()}</h3>
      </div>
      <div className="mt-2 flex flex-col gap-4">
        {fields.map((field, index, arr) => (
          <div
            ref={index === arr.length - 1 ? lastFieldArrayElementRef : undefined}
            className="flex items-start gap-2"
            key={field.id}
          >
            <div className="w-full max-w-[105px]">
              <StateSelect
                onChange={() => setValue(`stateInputs.${index}.ncci`, null)}
                name={`stateInputs.${index}.stateCode`}
                disabled={isUpdating}
                placeholder="State"
                control={control}
                index={index}
              />
            </div>
            <div className="flex w-full flex-col gap-2">
              <Tooltip
                title={!Boolean(stateInputs?.[index]?.stateCode) ? 'Select a State' : ''}
                className="grow"
                placement="top"
              >
                <PayrollClassCodeAutocomplete
                  disabled={isUpdating || !Boolean(stateInputs?.[index]?.stateCode)}
                  name={`stateInputs.${index}.ncci`}
                  onChange={() => {
                    setUnmatchedNcci((prevValue) => {
                      return Object.fromEntries(Object.entries(prevValue).filter(([key]) => Number(key) !== index));
                    });
                  }}
                  control={control}
                  index={index}
                />
              </Tooltip>
              {unmatchedNcci?.[index] ? (
                <span className="text-xxs text-red-500">
                  <b>“{unmatchedNcci[index]}”</b> has either an invalid state, class code, or description for the
                  Insurate footprint. Please update or remove this item.
                </span>
              ) : null}
            </div>
            <div className={clsxm('grid gap-4', arr.length > 1 ? 'grid-cols-[170px_75px]' : 'grid-cols-[170px_35px]')}>
              <ReactHookFormNumberField
                label={index === 0 ? 'Payroll Amount' : undefined}
                onFieldBlur={handlePayrollBlur(index)}
                name={`stateInputs.${index}.payroll`}
                thousandSeparator=","
                disabled={isUpdating}
                placeholder="Amount"
                variant="outlined"
                inputSize="large"
                control={control}
                decimalScale={2}
                prefix="$"
                fullWidth
              />
              <div className="flex w-full items-center justify-end">
                <Tooltip
                  text="To add a new payroll entry under a different class code click the plus button."
                  title="Add payroll entry"
                  disabled={isUpdating}
                >
                  <AddIconButton disabled={isUpdating} onClick={handleAddRow(index)} className="mb-2" />
                </Tooltip>
                {arr.length > 1 && (
                  <Tooltip
                    text="Click to delete the selected payroll entry."
                    title="Delete payroll entry"
                    disabled={isUpdating}
                  >
                    <DeleteIconButton disabled={isUpdating} onClick={handleDeleteRow(index)} className="mb-2" />
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div
        className={clsxm('ml-auto flex items-center gap-2', overviewData?.payrollEntries?.length ? 'mt-2' : 'mt-auto')}
      >
        <Tooltip placement="top" title="Upload Payroll via Excel">
          <Button
            onClick={() => {
              uploadDialog.open({
                onCancel: () => uploadDialog.close(),
                onParse: handleParse,
              });
            }}
            className="h-[44px] border-secondary bg-transparent"
            variant="contained"
          >
            <FileUploadIcon fontSize="medium" />
          </Button>
        </Tooltip>
        <Button
          disabled={isUpdatingFullPrefill || isUpdatingAwsPrefill}
          onClick={handleEndAndResetEdit}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          className="h-11 w-20 border-none bg-secondary text-white"
          disabled={isUpdatingFullPrefill || isUpdatingAwsPrefill}
          onClick={handleSave}
        >
          {isUpdatingFullPrefill || isUpdatingAwsPrefill ? <CircularProgress size={22} /> : 'Save'}
        </Button>
      </div>
    </div>
  );
};
