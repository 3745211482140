import { createContext, FC, ReactNode, useContext, useMemo } from 'react';
import { useLocalStorage } from 'react-use';

export enum ESubmissionLossSummaryTab {
  BY_PERIOD = 'BY_PERIOD',
  BY_CARRIER = 'BY_CARRIER',
}

const SubmissionLossSummaryContext = createContext({
  tab: ESubmissionLossSummaryTab.BY_CARRIER,
  setTab: (_: ESubmissionLossSummaryTab) => {},
  onResetForm: () => {},
});

interface Props {
  submissionId: string;
  onResetForm: () => void;
  children: ReactNode;
}

export const SubmissionLossSummaryProvider: FC<Props> = ({ children, submissionId, onResetForm }) => {
  const [tab = ESubmissionLossSummaryTab.BY_CARRIER, setTab] = useLocalStorage(
    `SUBMISSION_LOSS_SUMMARY_TAB_BY_ID_${submissionId}`,
    ESubmissionLossSummaryTab.BY_CARRIER
  );

  const value = useMemo(
    () => ({
      tab,
      setTab,
      onResetForm,
    }),
    [tab, setTab, onResetForm]
  );

  return <SubmissionLossSummaryContext.Provider value={value}>{children}</SubmissionLossSummaryContext.Provider>;
};

export const useSubmissionLossSummaryContext = () => useContext(SubmissionLossSummaryContext);
