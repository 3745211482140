import { FC } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Blocker, BlockerFunction, matchPath } from 'react-router-dom';

import { NavigationBlocker } from '@app/components/navigation-blocker/NavigationBlocker';
import { EQueryConfigName } from '@app/constants/query-config.const';
import { Routes } from '@app/constants/routes';
import { createAwsPrefillDto, createFullPrefillDto } from '@app/domain/sti/api/sti-api.utils';
import { useUpdateSubmissionStiAwsPrefill, useUpdateSubmissionStiFullPrefill } from '../api/submission.api.hooks';
import { useSubmissionCalculatorFormsContext } from '../contexts/SubmissionCalculatorFormsContext';
import { ESubmissionTab, SUBMISSION_TAB_PARAM_QUERY_NAME } from '../hooks/useSubmissionTabQueryParam';
import { AwsCalcValidationSchema, FullCalcValidationSchemaType } from '@app/domain/sti/forms/calc.form';
import { useHandler } from '@app/hooks/useHandler.hook';

interface Props {
  submissionId: string;
  isFullFormDirty: boolean;
  isAwsFormDirty: boolean;
  fullFormValues: FullCalcValidationSchemaType;
  awsFormValues: AwsCalcValidationSchema;
}

export const SubmissionNavigationBlocker: FC<Props> = ({
  submissionId,
  isFullFormDirty,
  isAwsFormDirty,
  fullFormValues,
  awsFormValues,
}) => {
  const queryClient = useQueryClient();

  const { fullCalcResult, awsCalcResult } = useSubmissionCalculatorFormsContext();

  const { mutateAsync: updateFullPrefill } = useUpdateSubmissionStiFullPrefill(submissionId);
  const { mutateAsync: updateAwsPrefill } = useUpdateSubmissionStiAwsPrefill(submissionId);

  const isDirty = isFullFormDirty || isAwsFormDirty;

  const onCancel = useHandler((blocker: Blocker) => {
    blocker.reset?.();
  });

  const blockerFn: BlockerFunction = useHandler((args) => {
    const isStiTab =
      Boolean(
        matchPath(
          {
            path: Routes.submissions.submissionData,
          },
          args.currentLocation.pathname
        )
      ) &&
      args.currentLocation.search.includes(`${SUBMISSION_TAB_PARAM_QUERY_NAME}=${ESubmissionTab.SAFE_TIER_RATING}`);

    // save sti changes on page leave if no sti result
    const shouldSaveFullCalcValues = isFullFormDirty && !fullCalcResult;
    // save aws changes on page leave if no aws result
    const shouldSaveAwsCalcValues = isAwsFormDirty && !awsCalcResult;

    if (isStiTab && (shouldSaveFullCalcValues || shouldSaveAwsCalcValues)) {
      if (shouldSaveFullCalcValues) {
        updateFullPrefill(createFullPrefillDto(fullFormValues), {
          onSuccess: () => {
            queryClient.invalidateQueries([EQueryConfigName.GET_SUBMISSION_STI_FULL_PREFILL, submissionId]);
            queryClient.invalidateQueries([EQueryConfigName.GET_SUBMISSION_OVERVIEW, submissionId]);
          },
        });
      }

      if (shouldSaveAwsCalcValues) {
        updateAwsPrefill(createAwsPrefillDto(awsFormValues), {
          onSuccess: () => {
            queryClient.invalidateQueries([EQueryConfigName.GET_SUBMISSION_AWS_PREFILL, submissionId]);
          },
        });
      }

      return false;
    }

    const isSamePathname = args.currentLocation.pathname === args.nextLocation.pathname;

    return !isSamePathname && isDirty;
  });

  return (
    <NavigationBlocker
      text="You unsaved changes on the SafeTier calculator. If you leave, your changes will be lost. Would you like to leave anyway?"
      title="Unsaved Changes on SafeTier Tab"
      cancelText="Cancel"
      confirmText="Leave and Discard"
      onCancel={onCancel}
      blockerFn={blockerFn}
    />
  );
};
