import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Checkbox, FormControlLabel, Paper } from '@mui/material';
import { FocusEvent, FC, useCallback, useState } from 'react';
import { DragIndicator } from '@mui/icons-material';
import { NumericFormat } from 'react-number-format';
import { nanoid } from 'nanoid';
import { useQueryClient } from '@tanstack/react-query';

import { useSubmissionCalculatorFormsContext } from '@app/domain/submission/contexts/SubmissionCalculatorFormsContext';
import { ESubmissionTab, useSubmissionTabQueryParam } from '@app/domain/submission/hooks/useSubmissionTabQueryParam';
import { ReactHookFormNumberField } from '@app/components/fields/react-hook-form-fields/ReactHookFormNumberField';
import { SubmissionCalculatorHeading } from '@app/domain/submission/components/SubmissionCalculatorHeading';
import { PayrollClassCodeAutocomplete } from '@app/domain/sti/components/PayrollClassCodeAutocomplete';
import { FullCalcValidationSchemaType, StateInputSchemaType } from '@app/domain/sti/forms/calc.form';
import { createFullCalcDto, createFullPrefillDto } from '@app/domain/sti/api/sti-api.utils';
import { FullCalcInDto, FullCalcOutDtoResponse } from '@app/domain/sti/types/calc.types';
import { NaicsAutocomplete } from '@app/domain/sti/components/NaicsAutocomplete';
import { DeleteIconButton } from '@app/components/buttons/DeleteIconButton';
import { AddIconButton } from '@app/components/buttons/AddIconButton';
import { ErrorsTable } from '@app/domain/sti/components/ErrorsTable';
import { StateSelect } from '@app/domain/sti/components/StateSelect';
import { formatStiFullPrefilledValues } from '../utils/form.utils';
import { Button } from '@app/components/buttons/button/Button';
import { WarningButton } from '../components/WarningButton';
import { Input } from '@app/components/fields/input/Input';
import { Tooltip } from '@app/components/tooltip/Tooltip';
import { ErrorButton } from '../components/ErrorButton';
import { clsxm } from '@app/styles/clsxm';
import { EQueryConfigName } from '@app/constants/query-config.const';
import {
  useGetRiskFactorsBySubmissionIdQuery,
  useUpdateSubmissionStiFullPrefill,
} from '@app/domain/submission/api/submission.api.hooks';
import { useSyncStiCalcWithOverview } from '../hooks/useSyncStiCalcWithOverview';

const FORM_ID = 'assessment-builder';
const DROPPABLE_ID = 'stateInput';

interface Props {
  calcSubmissionBySti: (values: FullCalcInDto) => Promise<FullCalcOutDtoResponse>;
  fullCalcResult?: FullCalcOutDtoResponse;
  submissionId: string;
  isLoading: boolean;
}

export const SubmissionFullAssessmentCalculator: FC<Props> = ({
  calcSubmissionBySti,
  fullCalcResult,
  submissionId,
  isLoading,
}) => {
  const [, setTab] = useSubmissionTabQueryParam();
  const [showErrors, setShowErrors] = useState(false);
  const [showWarnings, setShowWarnings] = useState(false);

  const queryClient = useQueryClient();

  const {
    syncEmrChange,
    syncNaicsChange,
    syncStateChange,
    syncNcciChange,
    syncPayrollChange,
    syncStateInputDelete,
    syncStateInputAdd,
    syncStateInputReorder,
  } = useSyncStiCalcWithOverview(submissionId);

  const { mutateAsync: updateStiFullPrefill, isLoading: isUpdatingSubmissionStiFullPrefill } =
    useUpdateSubmissionStiFullPrefill(submissionId, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [EQueryConfigName.GET_SUBMISSION_OVERVIEW, submissionId] });
      },
    });

  const { data } = useGetRiskFactorsBySubmissionIdQuery(submissionId);

  const totalAdjustment = data?.totalRiskFactor;

  const { naics, ncci } = useSubmissionCalculatorFormsContext();

  const [focusedField, setFocusedField] = useState<{
    column: 'no' | 'state' | 'ncci';
    rowLocalId: string;
  }>({
    column: 'no',
    rowLocalId: '',
  });

  const { watch, control, setValue, handleSubmit, formState, reset } = useFormContext<FullCalcValidationSchemaType>();

  const { isValid } = formState;

  const {
    fields = [],
    remove,
    insert,
    move,
  } = useFieldArray<FullCalcValidationSchemaType>({ control, name: 'stateInputs' });

  const resetFocusedField = useCallback(() => {
    setFocusedField({
      column: 'no',
      rowLocalId: '',
    });
  }, []);

  const handleShowErrors = useCallback(() => setShowErrors(true), []);

  const handleHideErrors = useCallback(() => setShowErrors(false), []);

  const handleShowWarnings = useCallback(() => setShowWarnings(true), []);

  const handleHideWarnings = useCallback(() => setShowWarnings(false), []);

  const errors = fullCalcResult?.errors;
  const warnings = fullCalcResult?.warnings;

  const handleDragStart = useCallback(() => resetFocusedField(), [resetFocusedField]);

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      if (result.destination?.droppableId === DROPPABLE_ID) {
        move(result.source.index, result.destination.index);
        syncStateInputReorder(result.source.index, result.destination.index);
      }
    },
    [move, syncStateInputReorder]
  );

  const handleDeleteRow = (index: number) => () => {
    remove(index);
    resetFocusedField();
    syncStateInputDelete(index);
  };

  const handleAddRow = (index: number) => () => {
    const positionToInsert = index + 1;

    const previousStateCode = watch().stateInputs?.[index].stateCode || '';

    const row: StateInputSchemaType = {
      stateCode: previousStateCode,
      ncci: null,
      payroll: '',
      rowLocalId: nanoid(),
    };

    insert(positionToInsert, row);
    syncStateInputAdd(positionToInsert, previousStateCode);
    setFocusedField({
      column: row.stateCode ? 'ncci' : 'state',
      rowLocalId: row.rowLocalId,
    });
  };

  const handleTransformFieldToDecimalValue = useCallback(
    (field: keyof FullCalcValidationSchemaType) => (e?: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (['emr'].includes(field)) {
        syncEmrChange(Number(e?.target.value));
      }
      if (e?.target.value) {
        if (Number.isInteger(Number(e.target.value))) {
          setValue(field, `${Number(e.target.value)}.00`);
        }
      }
    },
    [setValue, syncEmrChange]
  );

  const handlePayrollBlur = (index: number) => (e?: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e?.target.value) {
      let value = e.target.value.replace(/\$/g, '').replace(/,/g, '');

      if (Number.isInteger(Number(value))) {
        value = `${Number(value)}.00`;
        setValue(`stateInputs.${index}.payroll`, value);
      }
    }
    syncPayrollChange(index, e?.target.value || '');
  };

  return (
    <Paper className="flex w-full flex-col gap-2.5 rounded-lg bg-white p-6 shadow-card">
      <SubmissionCalculatorHeading isLoading={isLoading} />
      <form
        id={FORM_ID}
        onSubmit={handleSubmit(async (data) => {
          calcSubmissionBySti(createFullCalcDto(data));

          const result = await updateStiFullPrefill(createFullPrefillDto(data));

          reset(formatStiFullPrefilledValues(result, naics?.result || [], ncci?.result || []));
        })}
      >
        <div className="flex gap-4">
          <div className="flex grow items-center gap-4">
            <NaicsAutocomplete
              disabled={isLoading}
              label="NAICS Code"
              name="naics"
              control={control}
              fullWidth
              onChange={syncNaicsChange}
            />
            <ReactHookFormNumberField
              onFieldBlur={handleTransformFieldToDecimalValue('emr')}
              className="w-full max-w-[105px]"
              thousandSeparator=","
              disabled={isLoading}
              placeholder="N.NN"
              control={control}
              inputSize="large"
              decimalScale={2}
              label="EMR"
              name="emr"
            />
          </div>
          <div className={clsxm('grid gap-4', fields.length > 1 ? 'grid-cols-[170px_75px]' : 'grid-cols-[170px_35px]')}>
            <div className="flex items-center justify-between">
              <ReactHookFormNumberField
                onFieldBlur={handleTransformFieldToDecimalValue('trir')}
                className="w-full max-w-[75px]"
                thousandSeparator=","
                disabled={isLoading}
                placeholder="N.NN"
                inputSize="large"
                control={control}
                decimalScale={2}
                label="TRIR"
                name="trir"
              />
              <ReactHookFormNumberField
                onFieldBlur={handleTransformFieldToDecimalValue('da')}
                className="w-full max-w-[75px]"
                thousandSeparator=","
                disabled={isLoading}
                placeholder="N.NN"
                control={control}
                inputSize="large"
                decimalScale={2}
                label="DART"
                name="da"
              />
            </div>
            <div className={clsxm('w-full', fields.length > 1 ? 'max-w-[75px]' : 'max-w-[35px]')} />
          </div>
        </div>
        <div className="my-10 flex gap-4">
          <div className="ml-5 flex grow items-center gap-2">
            <div className="flex grow gap-4">
              <Tooltip
                text={`Click to ${watch().safety ? 'exclude' : 'include'} Safety programs.`}
                title="Safety programs"
              >
                <Controller
                  control={control}
                  name="safety"
                  render={({ field }) => (
                    <FormControlLabel
                      {...field}
                      control={
                        <Checkbox
                          sx={{ '&.Mui-checked': { color: '#1F2937' } }}
                          checked={Boolean(field.value)}
                          disabled={isLoading}
                          className="order-2"
                        />
                      }
                      label={<span className="text-[#00000099]">Safety Programs</span>}
                    />
                  )}
                />
              </Tooltip>
              <Tooltip
                text={`Click to ${watch().health ? 'exclude' : 'include'} Health coverage.`}
                title="Health coverage"
              >
                <Controller
                  control={control}
                  name="health"
                  render={({ field }) => (
                    <FormControlLabel
                      {...field}
                      control={
                        <Checkbox
                          sx={{ '&.Mui-checked': { color: '#1F2937' } }}
                          checked={Boolean(field.value)}
                          disabled={isLoading}
                          className="order-2"
                        />
                      }
                      label={<span className="text-[#00000099]">Health Coverage</span>}
                    />
                  )}
                />
              </Tooltip>
              <Tooltip
                title="Supplemental Coverage"
                text={`Click to ${watch().supplemental ? 'exclude' : 'include'} Supplemental Coverage.`}
              >
                <Controller
                  control={control}
                  name="supplemental"
                  render={({ field }) => (
                    <FormControlLabel
                      {...field}
                      control={
                        <Checkbox
                          sx={{ '&.Mui-checked': { color: '#1F2937' } }}
                          checked={Boolean(field.value)}
                          disabled={isLoading}
                          className="order-2"
                        />
                      }
                      label={<span className="text-[#00000099]">Supplemental Coverage</span>}
                    />
                  )}
                />
              </Tooltip>
            </div>
            <div className="relative w-full max-w-[105px]">
              <button
                className="absolute inset-0 z-10"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setTab(ESubmissionTab.RISK_FACTORS);
                }}
              />
              <NumericFormat
                sx={{
                  '& .MuiInputBase-input.Mui-disabled': {
                    backgroundColor: '#F5F5F5',
                    color: '#00000099',
                  },
                }}
                value={totalAdjustment ?? 0}
                placeholder="Percentage"
                label="Risk Factors"
                customInput={Input}
                className="w-full"
                decimalScale={2}
                suffix="%"
                disabled
              />
            </div>
          </div>
          <div className={clsxm('grid gap-4', fields.length > 1 ? 'grid-cols-[170px_75px]' : 'grid-cols-[170px_35px]')}>
            <NumericFormat
              sx={{ '& .MuiInputBase-input.Mui-disabled': { backgroundColor: '#F5F5F5', color: '#00000099' } }}
              value={Number(watch().stateInputs.reduce((acc, curr) => acc + Number(curr.payroll), 0))}
              thousandSeparator=","
              label="Total Payroll"
              placeholder="Amount"
              customInput={Input}
              decimalScale={2}
              prefix="$"
              disabled
            />
            <div />
          </div>
        </div>
        <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
          <Droppable droppableId={DROPPABLE_ID} direction="vertical">
            {(droppableProvided) => (
              <div ref={droppableProvided.innerRef} className="flex flex-col gap-3.5">
                {fields.map((stateInput, index, arr) => {
                  const shouldFocusState =
                    focusedField.column === 'state' && stateInput.rowLocalId === focusedField.rowLocalId;
                  const shouldFocusNcci =
                    focusedField.column === 'ncci' && stateInput.rowLocalId === focusedField.rowLocalId;
                  return (
                    <Draggable key={index} index={index} draggableId={`${DROPPABLE_ID}.${index}`}>
                      {(draggableProvided) => (
                        <div
                          {...draggableProvided.draggableProps}
                          className="flex w-full items-center gap-4 bg-white"
                          style={{ ...draggableProvided.draggableProps.style }}
                          ref={draggableProvided.innerRef}
                        >
                          <div className="flex w-full gap-4">
                            <div
                              className={clsxm(
                                'mb-2 flex w-full max-w-10 items-center justify-center rounded-md p-1',
                                isLoading ? 'pointer-events-none' : 'cursor-pointer hover:bg-gray-100'
                              )}
                              {...draggableProvided.dragHandleProps}
                            >
                              <DragIndicator className="text-[#0000008A]" />
                            </div>
                            <div className={clsxm('w-full max-w-[105px]', index === 0 ? '-mt-[10px]' : undefined)}>
                              <StateSelect
                                name={`stateInputs.${index}.stateCode`}
                                key={stateInput.rowLocalId}
                                focus={shouldFocusState}
                                disabled={isLoading}
                                placeholder="State"
                                control={control}
                                index={index}
                                onChange={(e) => {
                                  syncStateChange(index, e.target.value);
                                  // reset 'ncci'
                                  setValue(`stateInputs.${index}.ncci`, null);
                                }}
                              />
                            </div>
                            <div className={clsxm('grow', index === 0 ? '-mt-[10px]' : undefined)}>
                              <PayrollClassCodeAutocomplete
                                name={`stateInputs.${index}.ncci`}
                                index={index}
                                key={stateInput.rowLocalId}
                                focus={shouldFocusNcci}
                                disabled={isLoading}
                                control={control}
                                onChange={(ncci) => {
                                  syncNcciChange(index, ncci);
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className={clsxm(
                              'grid gap-4',
                              arr.length > 1 ? 'grid-cols-[170px_75px]' : 'grid-cols-[170px_35px]'
                            )}
                          >
                            <div className={index === 0 ? '-mt-[10px]' : undefined}>
                              <ReactHookFormNumberField
                                label={index === 0 ? 'Payroll Amount' : undefined}
                                onFieldBlur={handlePayrollBlur(index)}
                                name={`stateInputs.${index}.payroll`}
                                key={stateInput.rowLocalId}
                                thousandSeparator=","
                                disabled={isLoading}
                                placeholder="Amount"
                                variant="outlined"
                                control={control}
                                inputSize="large"
                                decimalScale={2}
                                prefix="$"
                                fullWidth
                              />
                            </div>
                            <div className="flex w-full items-center justify-end">
                              <Tooltip
                                text="To add a new payroll entry under a different class code click the plus button."
                                title="Add payroll entry"
                              >
                                <AddIconButton className="mb-2" disabled={isLoading} onClick={handleAddRow(index)} />
                              </Tooltip>
                              {arr.length > 1 && (
                                <Tooltip
                                  text="Click to delete the selected payroll entry."
                                  title="Delete payroll entry"
                                >
                                  <DeleteIconButton
                                    onClick={handleDeleteRow(index)}
                                    disabled={isLoading}
                                    className="mb-2"
                                  />
                                </Tooltip>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </form>
      <div className="mt-8 flex w-full items-center justify-center gap-3">
        <Button
          disabled={!isValid || isUpdatingSubmissionStiFullPrefill}
          className="w-60 rounded-md"
          variant="contained"
          loading={isLoading}
          color="primary"
          form={FORM_ID}
          type="submit"
        >
          Calculate
        </Button>
        <ErrorButton errors={errors} show={showErrors} handleShow={handleShowErrors} isLoading={isLoading} />
        <WarningButton handleShow={handleShowWarnings} isLoading={isLoading} warnings={warnings} show={showWarnings} />
      </div>
      {showErrors && errors && !isLoading ? (
        <div className="mt-8 flex w-full justify-center">
          <ErrorsTable errors={errors} open={showErrors} onClose={handleHideErrors} />
        </div>
      ) : null}
      {showWarnings && warnings && !isLoading ? (
        <div className="mt-8 flex w-full justify-center">
          <ErrorsTable
            messageColumnLabel="Warning Details"
            onClose={handleHideWarnings}
            codeColumnLabel="Code"
            open={showWarnings}
            errors={warnings}
          />
        </div>
      ) : null}
    </Paper>
  );
};
