import { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { Control, Controller, FieldPath, FieldValues, useWatch } from 'react-hook-form';

import { NcciClassificationOutDto } from '../../../swagger-types';

import { Autocomplete } from '@app/components/fields/autocomplete/Autocomplete';
import { ENcciType } from '../api/sti-api';
import { useGetNccis } from '../api/sti-api.hooks';

interface Props<T extends FieldValues> {
  index: number;
  name: FieldPath<T>;
  control: Control<T>;
  disabled?: boolean;
  focus?: boolean;
  aws?: boolean;
  onChange?: (ncci: NcciClassificationOutDto) => void;
}

export const PayrollClassCodeAutocomplete = <T extends FieldValues>({
  index,
  name,
  control,
  disabled,
  aws,
  focus = false,
  onChange,
}: Props<T>) => {
  const inputRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);

  const { stateInputs } = useWatch({ control });

  const stateCode = stateInputs?.[index].stateCode;

  const handleClose = useCallback(() => setOpen(false), []);

  const handleOpen = useCallback(() => setOpen(true), []);

  useEffect(() => {
    setOpen(focus);

    if (focus) {
      setTimeout(() => {
        // click to set focus, '.focus()' does not work
        inputRef.current?.click();
      }, 1);
    }
  }, [focus]);

  const { data: nccisRes, isLoading } = useGetNccis({
    type: aws ? ENcciType.AWS : ENcciType.FULL,
    state: stateCode,
  });

  const options: NcciClassificationOutDto[] = useMemo(() => nccisRes?.result || [], [nccisRes?.result]);

  const renderOptionLabel = useCallback((option: NcciClassificationOutDto) => {
    if (!option.ncci) {
      return option.phraseology;
    }
    return `${option.ncci}: ${option.phraseology}`;
  }, []);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete<NcciClassificationOutDto>
          {...field}
          ref={(e: HTMLDivElement | null) => {
            field.ref(e);
            inputRef.current = e;
          }}
          InputProps={{
            placeholder: 'Class Code',
            label: index === 0 ? 'Payroll Class Code' : undefined,
            error: Boolean(error),
            inputSize: 'large',
          }}
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          onChange={(event, value) => {
            if (value?.ncci) {
              field.onChange(value);
              onChange?.(value);
            }
          }}
          getOptions={async () => options}
          forceOptions={options}
          loading={isLoading}
          fetchOnOpen
          browserAutocompleteOff
          getOptionLabel={renderOptionLabel}
          noOptionsText="Class code not found"
          autoHighlight
          autoSelect
          fullWidth
          disabled={disabled || !stateCode}
          isOptionEqualToValue={(option, value) => option.uniqueIdentifier === value.uniqueIdentifier}
          filterOptions={(options, { getOptionLabel, inputValue }) =>
            options.filter((option) => getOptionLabel(option).toLowerCase().includes(inputValue.toLowerCase()))
          }
        />
      )}
    />
  );
};
