import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { GetVersionsParams, NcciRequestParams, StiApi, StiParams } from './sti-api';
import {
  CalcErrorResponse,
  NaicsOutDto,
  PageResponseNcciClassificationOutDto,
  StateOutDto,
  SubmissionGiCalcOutDto,
  VersionOutDto,
} from '@app/swagger-types';
import { EQueryConfigName } from '@app/constants/query-config.const';
import { ServerError } from '@app/swagger-override-types';
import { useCalcVersionContext } from '../contexts/VersionContext';
import { PaginatedResponse } from '@app/api/api.type';
import { AwsCalcInDto, AwsCalcOutDtoResponse, FullCalcInDto, FullCalcOutDtoResponse } from '../types/calc.types';
import { useHasAccess } from '@app/auth-roles-access';

export const useGetVersionsList = (
  params: GetVersionsParams,
  options?: UseQueryOptions<
    unknown,
    ServerError,
    VersionOutDto,
    (typeof EQueryConfigName.GET_VERSIONS_LIST | GetVersionsParams)[]
  >
) => {
  return useQuery({
    queryKey: [EQueryConfigName.GET_VERSIONS_LIST, params],
    queryFn: async () => {
      return await StiApi.getVersionsList(params);
    },
    ...options,
  });
};

export const useGetCalcStates = (
  options?: UseQueryOptions<unknown, ServerError, StateOutDto[], (typeof EQueryConfigName.GET_CALC_STATES)[]>
) => {
  return useQuery({
    ...options,
    queryKey: [EQueryConfigName.GET_CALC_STATES],
    queryFn: async () => {
      return await StiApi.getStates();
    },
  });
};

export const useGetNccis = (
  params: NcciRequestParams,
  options?: UseQueryOptions<
    unknown,
    ServerError,
    PageResponseNcciClassificationOutDto,
    (typeof EQueryConfigName.GET_NCCIS | NcciRequestParams)[]
  >
) => {
  return useQuery({
    ...options,
    queryKey: [EQueryConfigName.GET_NCCIS, params],
    queryFn: async () => {
      return await StiApi.getNccis(params);
    },
  });
};

export const useGetNaics = (
  options?: UseQueryOptions<unknown, ServerError, PaginatedResponse<NaicsOutDto>, (typeof EQueryConfigName.GET_NAICS)[]>
) => {
  return useQuery({
    queryKey: [EQueryConfigName.GET_NAICS],
    queryFn: async () => {
      return await StiApi.getNaics();
    },
    ...options,
  });
};

export const useGetCalcByGiResOutcome = (
  responseId: string,
  options: UseQueryOptions<unknown, ServerError, SubmissionGiCalcOutDto> = {}
) => {
  const userHasAccess = useHasAccess('AWS_ALTERNATIVE_RESULTS');

  return useQuery({
    enabled: Boolean(responseId) && userHasAccess,
    ...options,
    queryFn: async () => {
      return await StiApi.getCalcByGiResOutcome(responseId);
    },
  });
};

// MUTATIONS

export const useCalculateBySti = ({
  forceVersion,
  originalCrDb,
  overrideCrDb,
  originalStates,
  ...options
}: UseMutationOptions<FullCalcOutDtoResponse, CalcErrorResponse, FullCalcInDto> &
  Omit<StiParams, 'version'> & {
    forceVersion?: string;
  } = {}) => {
  const hasAccessToStiVersions = useHasAccess('STI_VERSIONS_SWITCHER');
  const { version: calcVersion } = useCalcVersionContext();

  const version = hasAccessToStiVersions ? forceVersion || calcVersion : undefined;

  const params: StiParams = {
    version,
    originalCrDb,
    overrideCrDb,
    originalStates,
  };

  return useMutation({
    ...options,
    mutationFn: async (Dto) => {
      return await StiApi.calculateBySti(Dto, params);
    },
  });
};

export const useCalcByAws = ({
  forceVersion,
  ...options
}: UseMutationOptions<AwsCalcOutDtoResponse, CalcErrorResponse, AwsCalcInDto> & {
  forceVersion?: string;
} = {}) => {
  const hasAccessToStiVersions = useHasAccess('STI_VERSIONS_SWITCHER');
  const { awsVersion: calcVersion } = useCalcVersionContext();

  const version = hasAccessToStiVersions ? forceVersion || calcVersion : undefined;

  return useMutation({
    ...options,
    mutationFn: async (Dto) => {
      return await StiApi.calculateByAws(Dto, version);
    },
  });
};

export const useGetCalcBySTIResponseOutcome = (
  options: UseMutationOptions<FullCalcOutDtoResponse, ServerError, number> = {}
) => {
  return useMutation({
    ...options,
    mutationFn: async (responseId) => {
      return await StiApi.getCalcBySTIResponseOutcome(responseId);
    },
  });
};
export const useGetCalcByAwsResOutcome = (
  options: UseMutationOptions<AwsCalcOutDtoResponse, ServerError, number> = {}
) => {
  return useMutation({
    ...options,
    mutationFn: async (responseId) => {
      return await StiApi.getCalcByAwsResOutcome(responseId);
    },
  });
};
