import { Card, Skeleton } from '@mui/material';
import { FC, useCallback, useRef, useState } from 'react';

import { SubmissionOverviewPayrollTable } from './SubmissionOverviewPayrollTable';
import { SubmissionOverviewEstimatedAnnualPayrollForm } from './SubmissionOverviewEstimatedAnnualPayrollForm';
import {
  SubmissionOverviewOutDto,
  SubmissionStiAwsPrefillInDto,
  SubmissionStiFullPrefillInDto,
} from '@app/swagger-types';
import { useSubmissionCalculatorFormsContext } from '../contexts/SubmissionCalculatorFormsContext';

interface Props {
  fullPrefillValues: Omit<SubmissionStiFullPrefillInDto, 'payrollEntries'>;
  awsPrefillValues: Omit<SubmissionStiAwsPrefillInDto, 'payrollEntries'>;
  overviewData?: SubmissionOverviewOutDto;
  submissionId: string;
  isStandard: boolean;
}

export const SubmissionOverviewEstimatedAnnualPayroll: FC<Props> = ({
  fullPrefillValues,
  awsPrefillValues,
  overviewData,
  submissionId,
  isStandard,
}) => {
  const { isNcciLoading, ncci } = useSubmissionCalculatorFormsContext();

  const [unmatchedNcci, setUnmatchedNcci] = useState<Record<number, string>>({});

  const [isEditing, setEditing] = useState(false);

  const lastFieldArrayElementRef = useRef<HTMLDivElement>(null);

  const handleStartEditing = useCallback(() => {
    setEditing(true);

    // Scroll to the last added payroll
    setTimeout(() => {
      if (lastFieldArrayElementRef.current) {
        lastFieldArrayElementRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 10);
  }, []);

  return isNcciLoading && !ncci ? (
    <Skeleton width="100%" height={250} variant="rounded" />
  ) : (
    <Card className="flex w-full grow flex-col rounded-lg bg-white p-6 shadow-card">
      <SubmissionOverviewEstimatedAnnualPayrollForm
        lastFieldArrayElementRef={lastFieldArrayElementRef}
        fullPrefillValues={fullPrefillValues}
        awsPrefillValues={awsPrefillValues}
        setUnmatchedNcci={setUnmatchedNcci}
        unmatchedNcci={unmatchedNcci}
        submissionId={submissionId}
        overviewData={overviewData}
        isStandard={isStandard}
        setEditing={setEditing}
        isEditing={isEditing}
      />
      <SubmissionOverviewPayrollTable
        onStartEditing={handleStartEditing}
        unmatchedNcci={unmatchedNcci}
        overviewData={overviewData}
        isEditing={isEditing}
      />
    </Card>
  );
};
