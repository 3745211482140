import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { FC } from 'react';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

import { SubmissionOverviewOutDto } from '@app/swagger-types';
import { renderDollarAmount } from '@app/utils/currency/currency.utils';
import { Button } from '@app/components/buttons/button/Button';
import { clsxm } from '@app/styles/clsxm';
import { Tooltip } from '@app/components/tooltip/Tooltip';

interface Props {
  unmatchedNcci: Record<number, string>;
  overviewData?: SubmissionOverviewOutDto;
  onStartEditing: () => void;
  isEditing: boolean;
}

const UnmatchedWarningTooltip: FC<{ children: React.ReactElement<unknown, any> }> = ({ children }) => (
  <Tooltip
    classes={{ tooltip: 'min-w-[384px]' }}
    placement="top"
    title={
      <div className="flex flex-col items-center justify-center gap-2">
        <p>Invalid state, class code, or description for the Insurate footprint.</p>
        <p>Please update or remove this item.</p>
      </div>
    }
  >
    {children}
  </Tooltip>
);

export const SubmissionOverviewPayrollTable: FC<Props> = ({
  onStartEditing,
  unmatchedNcci,
  overviewData,
  isEditing,
}) => {
  const noData = !overviewData?.payrollEntries || overviewData?.payrollEntries?.length === 0;

  const renderedPayrollEntries = overviewData?.payrollEntries?.map(({ state, ncci, phraseology, amount }, index) => {
    const unmatched = unmatchedNcci?.[index];

    return (
      <TableRow key={index}>
        <TableCell className={clsxm(unmatched && 'text-red-500')}>
          {unmatched ? <UnmatchedWarningTooltip>{<span>{state}</span>}</UnmatchedWarningTooltip> : state}
        </TableCell>
        <TableCell className={clsxm(unmatched && 'text-red-500')}>
          {unmatched ? (
            <UnmatchedWarningTooltip>
              <>{unmatched.replace(/^[A-Z]{2} /, '')}</>
            </UnmatchedWarningTooltip>
          ) : ncci && phraseology ? (
            `${ncci} - ${phraseology}`
          ) : (
            ncci || phraseology || '-'
          )}
          {unmatched && (
            <UnmatchedWarningTooltip>
              <WarningAmberOutlinedIcon className="mb-[2px] ml-4 text-m" />
            </UnmatchedWarningTooltip>
          )}
        </TableCell>
        <TableCell className="text-right">{renderDollarAmount(amount)}</TableCell>
      </TableRow>
    );
  });

  const overviewTotalValue =
    overviewData?.payrollEntries?.reduce((acc, { amount = 0 }) => (acc += amount ?? 0), 0) || 0;

  const renderTotal = () => {
    if (noData) {
      return '';
    }

    return renderDollarAmount(overviewTotalValue);
  };

  return (
    <div className={clsxm('flex size-full flex-col', isEditing && 'hidden')}>
      <div className="flex h-[54px] items-center justify-between gap-2">
        <Typography className="text-xxxl">Estimated Annual Payroll</Typography>
        <h3 className="pr-4 text-xxl">{renderTotal()}</h3>
      </div>
      <TableContainer className={clsxm(noData && 'my-auto')}>
        <Table aria-label="Estimated Annual Payroll">
          {!noData && (
            <TableHead>
              <TableRow>
                <TableCell className="text-xl font-medium">
                  <span>State</span>
                </TableCell>
                <TableCell className="text-xl font-medium">
                  <span>Class Code</span>
                </TableCell>
                <TableCell className="text-right text-xl font-medium">
                  <span>Payroll</span>
                </TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {noData ? (
              <TableRow>
                <TableCell className="border-none text-center font-medium italic text-gray-500">
                  Upload an Acord 130 or add payroll
                </TableCell>
              </TableRow>
            ) : (
              renderedPayrollEntries
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Button
        className={clsxm('ml-auto border-none bg-secondary text-white', noData ? 'mt-auto' : 'mt-4')}
        onClick={onStartEditing}
      >
        {noData ? 'Add Payroll' : 'Edit Payroll'}
      </Button>
    </div>
  );
};
