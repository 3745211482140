import { KeyboardEvent, useState } from 'react';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';
import { LinearProgress, Paper } from '@mui/material';

import { Checkbox } from '@app/components/checkbox/Checkbox';
import { Autocomplete } from '@app/components/fields/autocomplete/Autocomplete';
import { OptionItem } from '@app/components/select';
import { useHandler } from '@app/hooks/useHandler.hook';
import { useGetCalcStates } from '@app/domain/sti/api/sti-api.hooks';
import { StateOutDto } from '@app/swagger-types';
import { clsxm } from '@app/styles/clsxm';
import { generateNumericId } from '@app/utils/number.utils';

interface Props<T extends FieldValues> {
  onClearFilters: () => void;
  isDisabled: boolean;
  control: Control<T>;
  name: FieldPath<T>;
  value: string;
}

export const SubmissionOperationStatesAutocomplete = <T extends FieldValues>({
  onClearFilters,
  isDisabled,
  control,
  value,
  name,
}: Props<T>) => {
  const [searchValue, setSearchValue] = useState('');
  const [activeOption, setActiveOption] = useState<StateOutDto | null>(null);

  const { data: statesList = [], isLoading: isStatesLoading } = useGetCalcStates();

  const getAutocompletePlaceholder = useHandler((value: string): string => {
    const isAllValuesChecked = statesList.length === value.split(', ').length;

    if (isAllValuesChecked) {
      return 'All';
    }

    const values = value ? value.split(', ') : [];
    if (values.length > 1) {
      return `${values.length} item(s)`;
    }

    return values[0] || 'States';
  });

  const filteredOptions = useHandler((options: StateOutDto[]) => {
    const checkedOptions = options.filter((option) => value?.includes(option.code));
    const uncheckedOptions = options.filter((option) => !value?.includes(option.code));

    const combinedOptions = [...checkedOptions, ...uncheckedOptions];

    return combinedOptions.filter((option) => option.title.toLowerCase().includes(searchValue.toLowerCase()));
  });

  const renderPaper = useHandler(({ children, ...rest }: React.HTMLAttributes<HTMLElement>) => (
    <Paper {...rest} classes={{ root: 'w-44' }} onMouseDown={(ev) => ev.preventDefault()} elevation={8}>
      <div className="mx-2 flex items-center justify-between gap-2">
        <button
          className={clsxm(
            'ml-auto text-xs text-blue-400 decoration-blue-400',
            Boolean(value?.length) && 'hover:underline',
            !Boolean(value?.length) && 'text-gray-300'
          )}
          onClick={onClearFilters}
          disabled={!Boolean(value?.length)}
        >
          Clear Filters
        </button>
      </div>

      {children}
      {isStatesLoading ? (
        <div className="pt-0.5">
          <LinearProgress className="h-0.5" />
        </div>
      ) : null}
    </Paper>
  ));

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Autocomplete<StateOutDto, true>
          multiple
          InputProps={{
            label: 'States of Operation',
            placeholder: getAutocompletePlaceholder(field.value),
          }}
          disableSearchReset
          browserAutocompleteOff
          search={searchValue}
          disableCloseOnSelect
          onChangeSearch={setSearchValue}
          filterOptions={filteredOptions}
          isOptionEqualToValue={(value, option) => value.title === option.title}
          onChange={(event, selectedValues, reason) => {
            if ((event as KeyboardEvent<HTMLInputElement>).key === 'Backspace' && reason === 'removeOption') {
              return;
            }

            const updatedValue = selectedValues.map((item) => item.code).join(', ');

            field.onChange(updatedValue);
          }}
          disabled={isStatesLoading || isDisabled}
          value={
            field.value
              ?.split(', ')
              ?.filter(Boolean)
              ?.map((code: string) => {
                const state = statesList.find((state) => state.code === code);

                return { code, title: state?.title || '', id: state?.id || generateNumericId(10) };
              }) || []
          }
          getOptionLabel={(option) => option.title || ''}
          renderTags={() => null}
          clearOptionsOnClose
          fetchOnOpen
          showClearIcon
          onHighlightChange={(_, option) => setActiveOption(option)}
          slots={{ paper: renderPaper }}
          renderOption={(props, option, { selected }, ownerState) => {
            const isAllValuesChecked = statesList.length === value.split(', ').length;
            const lastCheckedOption = ownerState.options.filter((option) => value?.includes(option.code)).at(-1);

            return (
              <OptionItem
                {...props}
                className={clsxm(
                  'rounded-none px-0',
                  option.id === lastCheckedOption?.id && !isAllValuesChecked
                    ? 'border-b border-solid border-gray-300'
                    : null
                )}
                title={option?.title?.toString() || ''}
                value={option.code}
                key={option.code}
                transparent
              >
                <div
                  className={clsxm('flex items-center rounded-md py-1', option.id === activeOption?.id && 'bg-gray-50')}
                >
                  <Checkbox checked={selected} disableRipple className="scale-75 py-0" />
                  {option.title}
                </div>
              </OptionItem>
            );
          }}
          getOptions={async () => statesList}
        />
      )}
    />
  );
};
