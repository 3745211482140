import { FC } from 'react';
import { Typography } from '@mui/material';

import { clsxm } from '@app/styles/clsxm';
import { AppLogo } from '@app/assets/app-logo/AppLogo';
import { UIStyledSection } from '../components/UIStyledSection';

const COLORS_GENERAL = /*tw*/ ['bg-primary', 'bg-secondary'];
const COLORS_FEEDBACK = /*tw*/ ['bg-success', 'bg-warning', 'bg-error', 'bg-info'];

export const UISectionBrandingAndColors: FC = () => {
  const renderColor = (colors: string[]) =>
    colors.map((e, i) => (
      <div key={i} className="flex flex-col gap-2">
        <div>{e.replace('bg-', '')}</div>
        <div className={clsxm(e, 'p-4')} />
      </div>
    ));

  return (
    <>
      <UIStyledSection title="Colors">
        <div className="flex flex-col gap-4 pb-6">
          <div>General colors</div>
          <div className="grid grid-cols-4 gap-4">{renderColor(COLORS_GENERAL)}</div>
        </div>
        <div className="flex flex-col gap-4 pb-6">
          <div>Feedback colors</div>
          <div className="grid grid-cols-4 gap-4">{renderColor(COLORS_FEEDBACK)}</div>
        </div>
      </UIStyledSection>
      <UIStyledSection title="Branding & Colors">
        <div className="flex flex-col gap-4 pb-6 ">
          <div className="grid max-w-96 grid-cols-2 items-center gap-3 ">
            <Typography>Small Logo:</Typography>
            <AppLogo noText />
          </div>
          <div className="grid max-w-96 grid-cols-2  items-center gap-3">
            <Typography>Full White Logo:</Typography>
            <div className="bg-black">
              <AppLogo />
            </div>
          </div>
          <div className="grid max-w-96 grid-cols-2  items-center gap-3">
            <Typography>Full Dark Logo:</Typography>
            <AppLogo dark />
          </div>
        </div>
      </UIStyledSection>
    </>
  );
};
