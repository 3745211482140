import { ClaimFilterConfig, EClaimFilterType } from '@app/domain/claim/types/claim.filter.types';
import { EFieldType } from '@app/swagger-types';

export const GENERAL_FILTER_FIELDS: Array<ClaimFilterConfig> = [
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'nameInsured',
    fieldType: EFieldType.NAME_INSURED,
    outDtoField: 'nameInsured',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'policyNumber',
    fieldType: EFieldType.POLICY_NUMBER,
    outDtoField: 'policyNumber',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'claimNumber',
    fieldType: EFieldType.CLAIM_NUMBER,
    outDtoField: 'claimNumber',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'claimant',
    fieldType: EFieldType.CLAIMANT,
    outDtoField: 'claimant',
  },
];

export const CLAIM_SUMMARY_FILTER_CONFIGS: Array<ClaimFilterConfig> = [
  {
    type: EClaimFilterType.DATE,
    label: 'Date of Loss',
    fromField: 'dateOfLossFrom',
    toField: 'dateOfLossTo',
    outDtoField: 'dateOfLoss',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'claimType',
    fieldType: EFieldType.CLAIM_TYPE,
    outDtoField: 'claimType',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'claimStatus',
    fieldType: EFieldType.CLAIM_STATUS,
    outDtoField: 'claimStatus',
  },
  {
    type: EClaimFilterType.DATE,
    label: 'Date Closed',
    fromField: 'dateClosedFrom',
    toField: 'dateClosedTo',
    outDtoField: 'dateClosed',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'lostTimeIndicator',
    fieldType: EFieldType.LOST_TIME_INDICATOR,
    outDtoField: 'lostTimeIndicator',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'returnedToWork',
    fieldType: EFieldType.RETURNED_TO_WORK,
    outDtoField: 'returnedToWork',
  },
  {
    type: EClaimFilterType.NUMBER,
    fromField: 'totalDaysLostFrom',
    toField: 'totalDaysLostTo',
    outDtoField: 'totalDaysLost',
    label: 'Total Days Lost',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'litigation',
    fieldType: EFieldType.LITIGATION,
    outDtoField: 'litigation',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'suitFiled',
    fieldType: EFieldType.SUIT_FILED,
    outDtoField: 'suitFiled',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'claimDenied',
    fieldType: EFieldType.CLAIM_DENIED,
    outDtoField: 'claimDenied',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'subrogationStatus',
    fieldType: EFieldType.SUBROGATION_STATUS,
    outDtoField: 'subrogationStatus',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'accidentState',
    fieldType: EFieldType.ACCIDENT_STATE,
    outDtoField: 'accidentState',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'accidentDescription',
    fieldType: EFieldType.ACCIDENT_DESCRIPTION,
    outDtoField: 'accidentDescription',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'handlingOffice',
    fieldType: EFieldType.HANDLING_OFFICE,
    outDtoField: 'handlingOffice',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'adjusterEmail',
    fieldType: EFieldType.ADJUSTER_EMAIL,
    outDtoField: 'adjusterEmail',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'adjuster',
    fieldType: EFieldType.ADJUSTER,
    outDtoField: 'adjuster',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'adjusterPhone',
    fieldType: EFieldType.ADJUSTER_PHONE,
    outDtoField: 'adjusterPhone',
  },
];

export const CLAIMANT_FILTER_CONFIGS: Array<ClaimFilterConfig> = [
  {
    type: EClaimFilterType.NUMBER,
    fromField: 'claimantAgeFrom',
    toField: 'claimantAgeTo',
    outDtoField: 'claimantAge',
    label: 'Claimant Age',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'claimantEmail',
    fieldType: EFieldType.CLAIMANT_EMAIL,
    outDtoField: 'claimantEmail',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'claimantWorkEmail',
    fieldType: EFieldType.CLAIMANT_WORK_EMAIL,
    outDtoField: 'claimantWorkEmail',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'claimantPhone',
    fieldType: EFieldType.CLAIMANT_PHONE,
    outDtoField: 'claimantPhone',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'claimantWorkPhone',
    fieldType: EFieldType.CLAIMANT_WORK_PHONE,
    outDtoField: 'claimantWorkPhone',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'mobilePhone',
    fieldType: EFieldType.MOBILE_PHONE,
    outDtoField: 'mobilePhone',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'claimantCity',
    fieldType: EFieldType.CLAIMANT_CITY,
    outDtoField: 'claimantCity',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'claimantState',
    fieldType: EFieldType.CLAIMANT_STATE,
    outDtoField: 'claimantState',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'claimantZip',
    fieldType: EFieldType.CLAIMANT_ZIP,
    outDtoField: 'claimantZip',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'sex',
    fieldType: EFieldType.SEX,
    outDtoField: 'sex',
  },
  {
    type: EClaimFilterType.DATE,
    label: 'Date of Birth',
    fromField: 'dateOfBirthFrom',
    toField: 'dateOfBirthTo',
    outDtoField: 'dateOfBirth',
  },
  {
    type: EClaimFilterType.DATE,
    label: 'Date of Hire',
    fromField: 'dateOfHireFrom',
    toField: 'dateOfHireTo',
    outDtoField: 'dateOfHire',
  },
  {
    type: EClaimFilterType.DATE,
    label: 'Termination Date',
    fromField: 'terminationDateFrom',
    toField: 'terminationDateTo',
    outDtoField: 'terminationDate',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'socialSecurityNumber',
    fieldType: EFieldType.SOCIAL_SECURITY_NUMBER,
    outDtoField: 'socialSecurityNumber',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'jobTitleCarrier',
    fieldType: EFieldType.JOB_TITLE_CARRIER,
    outDtoField: 'jobTitleCarrier',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'employmentType',
    fieldType: EFieldType.EMPLOYMENT_TYPE,
    outDtoField: 'employmentType',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'supervisor',
    fieldType: EFieldType.SUPERVISOR,
    outDtoField: 'supervisor',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'claimantAttorneyRepresented',
    fieldType: EFieldType.CLAIMANT_ATTORNEY_REPRESENTED,
    outDtoField: 'claimantAttorneyRepresented',
  },
  {
    type: EClaimFilterType.COIN,
    label: 'Weekly Wage',
    fromField: 'weeklyWageCoinFrom',
    toField: 'weeklyWageCoinTo',
    outDtoField: 'weeklyWageCoin',
  },
  {
    type: EClaimFilterType.DECIMAL_NUMBER,
    fromField: 'ttdRateFrom',
    toField: 'ttdRateTo',
    outDtoField: 'ttdRate',
    label: 'TTD Rate',
  },
  {
    type: EClaimFilterType.DECIMAL_NUMBER,
    fromField: 'ppdRateFrom',
    toField: 'ppdRateTo',
    outDtoField: 'ppdRate',
    label: 'PPD Rate',
  },
];

export const FINANCIAL_FILTER_CONFIGS: Array<ClaimFilterConfig> = [
  {
    type: EClaimFilterType.COIN,
    label: 'Loss Paid',
    fromField: 'lossPaidFrom',
    toField: 'lossPaidTo',
    outDtoField: 'lossPaidCoin',
  },
  {
    type: EClaimFilterType.COIN,
    label: 'Loss Reserves',
    fromField: 'lossReservesFrom',
    toField: 'lossReservesTo',
    outDtoField: 'lossReservesCoin',
  },
  {
    type: EClaimFilterType.COIN,
    label: 'Medical Paid',
    fromField: 'medicalPaidCoinFrom',
    toField: 'medicalPaidCoinTo',
    outDtoField: 'medicalPaidCoin',
  },
  {
    type: EClaimFilterType.COIN,
    label: 'Medical Reserves',
    fromField: 'medicalReservesCoinFrom',
    toField: 'medicalReservesCoinTo',
    outDtoField: 'medicalReservesCoin',
  },
  {
    type: EClaimFilterType.COIN,
    label: 'Medical Total Incurred',
    fromField: 'medicalTotalIncurredCoinFrom',
    toField: 'medicalTotalIncurredCoinTo',
    outDtoField: 'medicalTotalIncurredCoin',
  },
  {
    type: EClaimFilterType.COIN,
    label: 'Indemnity Total Reserves',
    fromField: 'indemnityTotalReservesCoinFrom',
    toField: 'indemnityTotalReservesCoinTo',
    outDtoField: 'indemnityTotalReservesCoin',
  },
  {
    type: EClaimFilterType.COIN,
    label: 'Indemnity Total Incurred',
    fromField: 'indemnityTotalIncurredCoinFrom',
    toField: 'indemnityTotalIncurredCoinTo',
    outDtoField: 'indemnityTotalIncurredCoin',
  },
  {
    type: EClaimFilterType.COIN,
    label: 'Expense Paid',
    fromField: 'expensePaidCoinFrom',
    toField: 'expensePaidCoinTo',
    outDtoField: 'expensePaidCoin',
  },
  {
    type: EClaimFilterType.COIN,
    label: 'Expense Reserves',
    fromField: 'expenseReservesCoinFrom',
    toField: 'expenseReservesCoinTo',
    outDtoField: 'expenseReservesCoin',
  },
  {
    type: EClaimFilterType.COIN,
    label: 'Expense Total Incurred',
    fromField: 'expenseTotalIncurredCoinFrom',
    toField: 'expenseTotalIncurredCoinTo',
    outDtoField: 'expenseTotalIncurredCoin',
  },
  {
    type: EClaimFilterType.COIN,
    label: 'Total Paid',
    fromField: 'totalPaidCoinFrom',
    toField: 'totalPaidCoinTo',
    outDtoField: 'totalPaidCoin',
  },
  {
    type: EClaimFilterType.COIN,
    label: 'Outstanding Reserves',
    fromField: 'outstandingReservesCoinFrom',
    toField: 'outstandingReservesCoinTo',
    outDtoField: 'outstandingReservesCoin',
  },
  {
    type: EClaimFilterType.COIN,
    label: 'Total Incurred',
    fromField: 'totalIncurredFrom',
    toField: 'totalIncurredTo',
    outDtoField: 'totalIncurredCoin',
  },
  {
    type: EClaimFilterType.COIN,
    label: 'Total Reimbursement',
    fromField: 'totalReimbursementCoinFrom',
    toField: 'totalReimbursementCoinTo',
    outDtoField: 'totalReimbursementCoin',
  },
  {
    type: EClaimFilterType.COIN,
    label: 'Net Incurred',
    fromField: 'netIncurredCoinFrom',
    toField: 'netIncurredCoinTo',
    outDtoField: 'netIncurredCoin',
  },
  {
    type: EClaimFilterType.COIN,
    label: 'Outstanding Reserves Last Closure',
    fromField: 'outstandingReservesLastClosureCoinFrom',
    toField: 'outstandingReservesLastClosureCoinTo',
    outDtoField: 'outstandingReservesLastClosureCoin',
  },
  {
    type: EClaimFilterType.COIN,
    label: 'Sir Deductible',
    fromField: 'sirDeductibleCoinFrom',
    toField: 'sirDeductibleCoinTo',
    outDtoField: 'sirDeductibleCoin',
  },
  {
    type: EClaimFilterType.COIN,
    label: 'Settlement Amount',
    fromField: 'settlementAmountCoinFrom',
    toField: 'settlementAmountCoinTo',
    outDtoField: 'settlementAmountCoin',
  },
  {
    type: EClaimFilterType.COIN,
    label: 'Subrogation Estimated Amount',
    fromField: 'subrogationEstimatedAmountCoinFrom',
    toField: 'subrogationEstimatedAmountCoinTo',
    outDtoField: 'subrogationEstimatedAmountCoin',
  },
];

export const CLAIM_DETAILS_FILTER_CONFIGS: Array<ClaimFilterConfig> = [
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'bodyPartGroup',
    fieldType: EFieldType.BODY_PART_GROUP,
    outDtoField: 'bodyPartGroup',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'causeCode',
    fieldType: EFieldType.CAUSE_CODE,
    outDtoField: 'causeCode',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'causeCodeGroup',
    fieldType: EFieldType.CAUSE_CODE_GROUP,
    outDtoField: 'causeCodeGroup',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'groupCode1',
    fieldType: EFieldType.GROUPCODE1,
    outDtoField: 'groupCode1',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'industryCode',
    fieldType: EFieldType.INDUSTRY_CODE,
    outDtoField: 'industryCode',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'jobClass',
    fieldType: EFieldType.JOB_CLASS,
    outDtoField: 'jobClass',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'jobCode',
    fieldType: EFieldType.JOB_CODE,
    outDtoField: 'jobCode',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'lossType',
    fieldType: EFieldType.LOSS_TYPE,
    outDtoField: 'lossType',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'lossTypeGroup',
    fieldType: EFieldType.LOSS_TYPE_GROUP,
    outDtoField: 'lossTypeGroup',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'ncciInjuryType',
    fieldType: EFieldType.NCCI_INJURY_TYPE,
    outDtoField: 'ncciInjuryType',
  },
  {
    type: EClaimFilterType.DATE,
    label: 'Claimant Report Date',
    fromField: 'claimantReportDateFrom',
    toField: 'claimantReportDateTo',
    outDtoField: 'claimantReportDate',
  },
  {
    type: EClaimFilterType.DATE,
    label: 'Claimant Created Date',
    fromField: 'claimCreatedDateFrom',
    toField: 'claimCreatedDateTo',
    outDtoField: 'claimCreatedDate',
  },
  {
    type: EClaimFilterType.DATE,
    label: 'Claimant Denied Date',
    fromField: 'claimDeniedDateFrom',
    toField: 'claimDeniedDateTo',
    outDtoField: 'claimDeniedDate',
  },
  {
    type: EClaimFilterType.DATE,
    label: 'Claimant Submitted Date',
    fromField: 'claimSubmittedDateFrom',
    toField: 'claimSubmittedDateTo',
    outDtoField: 'claimSubmittedDate',
  },
  {
    type: EClaimFilterType.NUMBER,
    fromField: 'claimSubmittedLagFrom',
    toField: 'claimSubmittedLagTo',
    outDtoField: 'claimSubmittedLag',
    label: 'Claim Submitted Lag',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'claimSubmittedName',
    fieldType: EFieldType.CLAIM_SUBMITTED_NAME,
    outDtoField: 'claimSubmittedName',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'claimSubmittedUserId',
    fieldType: EFieldType.CLAIM_SUBMITTED_USERID,
    outDtoField: 'claimSubmittedUserId',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'claimantDied',
    fieldType: EFieldType.CLAIMANT_DIED,
    outDtoField: 'claimantDied',
  },
  {
    type: EClaimFilterType.DATE,
    label: 'Date Last Worked',
    fromField: 'dateLastWorkedFrom',
    toField: 'dateLastWorkedTo',
    outDtoField: 'dateLastWorked',
  },
  {
    type: EClaimFilterType.DATE,
    label: 'Date of Death',
    fromField: 'dateOfDeathFrom',
    toField: 'dateOfDeathTo',
    outDtoField: 'dateOfDeath',
  },
  {
    type: EClaimFilterType.DATE,
    label: 'Date of Max Improvement',
    fromField: 'dateOfMaxImprovementFrom',
    toField: 'dateOfMaxImprovementTo',
    outDtoField: 'dateOfMaxImprovement',
  },
  {
    type: EClaimFilterType.DATE,
    label: 'Date Suit Filed',
    fromField: 'dateSuitFiledFrom',
    toField: 'dateSuitFiledTo',
    outDtoField: 'dateSuitFiled',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'dayOfWeek',
    fieldType: EFieldType.DAY_OF_WEEK,
    outDtoField: 'dayOfWeek',
  },
  {
    type: EClaimFilterType.DATE,
    label: 'First Day of Disability Date',
    fromField: 'firstDayOfDisabilityFrom',
    toField: 'firstDayOfDisabilityTo',
    outDtoField: 'firstDayOfDisability',
  },
  {
    type: EClaimFilterType.DATE,
    label: 'Return to Work Date',
    fromField: 'returnToWorkDateFrom',
    toField: 'returnToWorkDateTo',
    outDtoField: 'returnToWorkDate',
  },
  {
    type: EClaimFilterType.DATE,
    label: 'Full Duty Date',
    fromField: 'fullDutyDateFrom',
    toField: 'fullDutyDateTo',
    outDtoField: 'fullDutyDate',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'initialTreatment',
    fieldType: EFieldType.INITIAL_TREATMENT,
    outDtoField: 'initialTreatment',
  },
  {
    type: EClaimFilterType.DATE,
    label: 'Last Payment Date',
    fromField: 'lastPaymentDateFrom',
    toField: 'lastPaymentDateTo',
    outDtoField: 'lastPaymentDate',
  },
  {
    type: EClaimFilterType.NUMBER,
    fromField: 'percentImpairmentFrom',
    toField: 'percentImpairmentTo',
    outDtoField: 'percentImpairment',
    label: 'Percent Impairment',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'physicianHospitalName',
    fieldType: EFieldType.PHYSICIAN_HOSPITAL_NAME,
    outDtoField: 'physicianHospitalName',
  },
  {
    type: EClaimFilterType.DATE,
    label: 'Reopened Date',
    fromField: 'reopenedDateFrom',
    toField: 'reopenedDateTo',
    outDtoField: 'reopenedDate',
  },
  {
    type: EClaimFilterType.DATE,
    label: 'Settlement Date',
    fromField: 'settlementDateFrom',
    toField: 'settlementDateTo',
    outDtoField: 'settlementDate',
  },
];

export const POLICY_INFO_FILTER_CONFIGS: Array<ClaimFilterConfig> = [
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'agency',
    fieldType: EFieldType.AGENCY,
    outDtoField: 'agency',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'carrier',
    fieldType: EFieldType.CARRIER,
    outDtoField: 'carrier',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'location',
    fieldType: EFieldType.LOCATION,
    outDtoField: 'location',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'locationNumber',
    fieldType: EFieldType.LOCATION_NUMBER,
    outDtoField: 'locationNumber',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'locationAddressLine1',
    fieldType: EFieldType.LOCATIONADDRESSLINE1,
    outDtoField: 'locationAddressLine1',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'locationCity',
    fieldType: EFieldType.LOCATION_CITY,
    outDtoField: 'locationCity',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'locationCounty',
    fieldType: EFieldType.LOCATION_COUNTY,
    outDtoField: 'locationCounty',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'locationState',
    fieldType: EFieldType.LOCATION_STATE,
    outDtoField: 'locationState',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'locationZip',
    fieldType: EFieldType.LOCATION_ZIP,
    outDtoField: 'locationZip',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'location2',
    fieldType: EFieldType.LOCATION2,
    outDtoField: 'location2',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'location2Number',
    fieldType: EFieldType.LOCATION2NUMBER,
    outDtoField: 'location2Number',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'location2AddressLine1',
    fieldType: EFieldType.LOCATION2ADDRESSLINE1,
    outDtoField: 'location2AddressLine1',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'location2City',
    fieldType: EFieldType.LOCATION2CITY,
    outDtoField: 'location2City',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'location2State',
    fieldType: EFieldType.LOCATION2STATE,
    outDtoField: 'location2State',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'location2Zip',
    fieldType: EFieldType.LOCATION2ZIP,
    outDtoField: 'location2Zip',
  },
  {
    type: EClaimFilterType.DATE,
    label: 'Policy Period End Date',
    fromField: 'policyPeriodEndDateFrom',
    toField: 'policyPeriodEndDateTo',
    outDtoField: ['policyPeriodEndDate', 'policyPeriod'],
  },
  {
    type: EClaimFilterType.DATE,
    label: 'Policy Period Start Date',
    fromField: 'policyPeriodStartDateFrom',
    toField: 'policyPeriodStartDateTo',
    outDtoField: ['policyPeriodStartDate', 'policyPeriod'],
  },
];

export const PREDICTIVE_ANALYTICS_FILTER_CONFIGS: Array<ClaimFilterConfig> = [
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'claimRiskLevel',
    fieldType: EFieldType.CLAIM_RISK_LEVEL,
    outDtoField: 'claimRiskLevel',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'copdFlag',
    fieldType: EFieldType.COPD_FLAG,
    outDtoField: 'copdFlag',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'coronaryArteryDiseaseFlag',
    fieldType: EFieldType.CORONARY_ARTERY_DISEASE_FLAG,
    outDtoField: 'coronaryArteryDiseaseFlag',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'diabetesFlag',
    fieldType: EFieldType.DIABETES_FLAG,
    outDtoField: 'diabetesFlag',
  },
  {
    type: EClaimFilterType.DECIMAL_NUMBER,
    fromField: 'electroDiagnosticPredictionFrom',
    toField: 'electroDiagnosticPredictionTo',
    outDtoField: 'electroDiagnosticPrediction',
    label: 'Electro Diagnostic Prediction',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'highCostPrediction',
    fieldType: EFieldType.HIGH_COST_PREDICTION,
    outDtoField: 'highCostPrediction',
  },
  {
    type: EClaimFilterType.DECIMAL_NUMBER,
    fromField: 'imagingPredictionFrom',
    toField: 'imagingPredictionTo',
    outDtoField: 'imagingPrediction',
    label: 'Imaging Prediction',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'obesityFlag',
    fieldType: EFieldType.OBESITY_FLAG,
    outDtoField: 'obesityFlag',
  },
  {
    type: EClaimFilterType.DECIMAL_NUMBER,
    fromField: 'opioidPredictionFrom',
    toField: 'opioidPredictionTo',
    outDtoField: 'opioidPrediction',
    label: 'Opioid Prediction',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'osteoarthritisFlag',
    fieldType: EFieldType.OSTEOARTHRITIS_FLAG,
    outDtoField: 'osteoarthritisFlag',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'otherCardiacDisordersFlag',
    fieldType: EFieldType.OTHER_CARDIAC_DISORDERS_FLAG,
    outDtoField: 'otherCardiacDisordersFlag',
  },
  {
    type: EClaimFilterType.DECIMAL_NUMBER,
    fromField: 'painManagementPredictionFrom',
    toField: 'painManagementPredictionTo',
    outDtoField: 'painManagementPrediction',
    label: 'Pain Management Prediction',
  },
  {
    type: EClaimFilterType.DECIMAL_NUMBER,
    fromField: 'physTherapyChiroPredictionFrom',
    toField: 'physTherapyChiroPredictionTo',
    outDtoField: 'physTherapyChiroPrediction',
    label: 'Phys Therapy Chiro Prediction Prediction',
  },
  //
  {
    type: EClaimFilterType.DATE,
    label: 'Prediction Date',
    fromField: 'predictionDateFrom',
    toField: 'predictionDateTo',
    outDtoField: 'predictionDate',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'riskLoweringFactor1',
    fieldType: EFieldType.RISKLOWERINGFACTOR1,
    outDtoField: 'riskLoweringFactor1',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'riskLoweringFactor2',
    fieldType: EFieldType.RISKLOWERINGFACTOR2,
    outDtoField: 'riskLoweringFactor2',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'riskLoweringFactor3',
    fieldType: EFieldType.RISKLOWERINGFACTOR3,
    outDtoField: 'riskLoweringFactor3',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'riskLoweringFactor4',
    fieldType: EFieldType.RISKLOWERINGFACTOR4,
    outDtoField: 'riskLoweringFactor4',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'riskLoweringFactor5',
    fieldType: EFieldType.RISKLOWERINGFACTOR5,
    outDtoField: 'riskLoweringFactor5',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'riskRaisingFactor1',
    fieldType: EFieldType.RISKRAISINGFACTOR1,
    outDtoField: 'riskRaisingFactor1',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'riskRaisingFactor2',
    fieldType: EFieldType.RISKRAISINGFACTOR2,
    outDtoField: 'riskRaisingFactor2',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'riskRaisingFactor3',
    fieldType: EFieldType.RISKRAISINGFACTOR3,
    outDtoField: 'riskRaisingFactor3',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'riskRaisingFactor4',
    fieldType: EFieldType.RISKRAISINGFACTOR4,
    outDtoField: 'riskRaisingFactor4',
  },
  {
    type: EClaimFilterType.STRING_ARR,
    field: 'substanceDependenceFlag',
    fieldType: EFieldType.SUBSTANCE_DEPENDENCE_FLAG,
    outDtoField: 'substanceDependenceFlag',
  },
  {
    type: EClaimFilterType.DECIMAL_NUMBER,
    fromField: 'surgeryPredictionFrom',
    toField: 'surgeryPredictionTo',
    outDtoField: 'surgeryPrediction',
    label: 'Surgery Prediction',
  },
  {
    type: EClaimFilterType.COIN,
    label: 'Total Incurred Prediction',
    fromField: 'totalIncurredPredictionCoinFrom',
    toField: 'totalIncurredPredictionCoinTo',
    outDtoField: 'totalIncurredPredictionCoin',
  },
];

export const ALL_CLAIM_FILTER_CONFIGS: Array<ClaimFilterConfig> = [
  ...GENERAL_FILTER_FIELDS,
  ...CLAIM_SUMMARY_FILTER_CONFIGS,
  ...CLAIMANT_FILTER_CONFIGS,
  ...FINANCIAL_FILTER_CONFIGS,
  ...CLAIM_DETAILS_FILTER_CONFIGS,
  ...POLICY_INFO_FILTER_CONFIGS,
  ...PREDICTIVE_ANALYTICS_FILTER_CONFIGS,
];
