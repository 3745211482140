import { createEnumParam, useQueryParam, withDefault } from 'use-query-params';

export enum ESubmissionTab {
  OVERVIEW = 'OVERVIEW',
  LOSS_SUMMARY = 'LOSS_SUMMARY',
  RISK_FACTORS = 'RISK_FACTORS',
  SAFE_TIER_RATING = 'SAFE_TIER_RATING',
  INSIGHTS = 'INSIGHTS',
}

export const SUBMISSION_TAB_PARAM_QUERY_NAME = 'tab';

const SubmissionTabParam = createEnumParam([
  ESubmissionTab.OVERVIEW,
  ESubmissionTab.LOSS_SUMMARY,
  ESubmissionTab.RISK_FACTORS,
  ESubmissionTab.SAFE_TIER_RATING,
  ESubmissionTab.INSIGHTS,
]);

export const useSubmissionTabQueryParam = () => {
  return useQueryParam(SUBMISSION_TAB_PARAM_QUERY_NAME, withDefault(SubmissionTabParam, ESubmissionTab.OVERVIEW));
};
