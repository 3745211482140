import { Typography } from '@mui/material';
import { ReactNode } from 'react';

import { Button } from '@app/components/buttons/button/Button';
import { Dialog } from '@app/hoc';
import { useHandler } from '@app/hooks/useHandler.hook';
import { ReactDVFC, useDialog } from '@app/services/dialog-manager';

interface Props {
  onCancel: () => void;
  onConfirm: () => void;
  dialogContent?: ReactNode;
  continueText?: string;
}

export const ConfirmSubmissionOverviewChangeDialog: ReactDVFC<Props> = ({
  isOpen,
  onClose,
  onCancel,
  onConfirm,
  continueText = 'Continue',
  dialogContent = (
    <Typography className="text-s text-gray-600">
      Changing the EMR or the NAICS code affects the SafeTier rating. If you change those values, the SafeTier Results
      will be reset and you&apos;ll need to calculate again.
    </Typography>
  ),
}) => {
  const handleCancel = useHandler(() => {
    onCancel();
    onClose();
  });

  const handleSubmit = useHandler(() => {
    onConfirm();
    onClose();
  });

  return (
    <Dialog open={isOpen} hideHeader paperClassName="w-[450px]">
      <div className="flex flex-col gap-10 p-10">
        <div className="flex flex-col gap-5 text-center">
          <Typography className="font-semibold">Are you sure?</Typography>
          {dialogContent}
        </div>
        <div className="flex justify-between">
          <Button variant="outlined" color="primary" className="w-44" onClick={handleCancel}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleSubmit} className="w-44">
            {continueText}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export const useConfirmSubmissionOverviewChangeDialog = () => useDialog(ConfirmSubmissionOverviewChangeDialog);
