import { useIsMutating, useQueryClient } from '@tanstack/react-query';
import { FC, useMemo, useState } from 'react';
import RemoveIcon from '@mui/icons-material/Remove';
import { Card, Skeleton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AutoModeSharpIcon from '@mui/icons-material/AutoModeSharp';

import { SubmissionInsightAccordion } from '../components/SubmissionInsightAccordion';
import { SubmissionGenerateInsights } from '../components/SubmissionGenerateInsights';
import { LOADING_ESubmissionFileStatus_VALUES } from '../const/submission.const';
import { EMutationConfigName } from '@app/constants/mutation-config.const';
import { EQueryConfigName } from '@app/constants/query-config.const';
import { Button } from '@app/components/buttons/button/Button';
import { SubmissionInsightApiDto } from '@app/swagger-types';
import { useHandler } from '@app/hooks/useHandler.hook';
import {
  useGenerateSubmissionInsights,
  useGetSubmissionByIdQuery,
  useGetSubmissionInsights,
} from '../api/submission.api.hooks';
import { clsxm } from '@app/styles/clsxm';

export enum ESubmissionSafeTierRatingTab {
  STANDARD = 'Standard',
  LAST_MILE = 'Last Mile',
}

interface Props {
  submissionId: string;
  setInsightsIndicated: (value: boolean) => void;
}

export const SubmissionInsightsTab: FC<Props> = ({ submissionId, setInsightsIndicated }) => {
  const { data: submissionData, isLoading: isSubmissionLoading } = useGetSubmissionByIdQuery(submissionId, {
    refetchInterval: (query) => {
      const accordFile = query?.fileOutAccordApplicationDtos?.[0];
      const emrFile = query?.fileOutEmrDtos?.[0];
      const lossRunsFiles = query?.fileOutLossRunsDtos;

      if (
        (accordFile?.status && LOADING_ESubmissionFileStatus_VALUES.includes(accordFile.status)) ||
        (emrFile?.status && LOADING_ESubmissionFileStatus_VALUES.includes(emrFile.status)) ||
        (lossRunsFiles && lossRunsFiles.find((file) => LOADING_ESubmissionFileStatus_VALUES.includes(file.status)))
      ) {
        return 1000 * 7;
      }

      return false;
    },
  });

  const [expanded, setExpanded] = useState<string[]>([]);

  const { data: insightsData, isFetching: isFetchingInsights } = useGetSubmissionInsights(submissionId, {
    enabled: Boolean(submissionData?.hasGeneratedInsights),
    keepPreviousData: true,
  });

  const queryClient = useQueryClient();

  const { mutate: generateInsights } = useGenerateSubmissionInsights(submissionId, {
    onSuccess: async (response) => {
      await queryClient.invalidateQueries([EQueryConfigName.GET_SUBMISSION_BY_ID, submissionId]);
      await queryClient.invalidateQueries([EQueryConfigName.GET_SUBMISSION_INSIGHTS, submissionId]);

      if (response.isSuccessful) {
        setInsightsIndicated(true);
      }
    },
  });

  const insights: SubmissionInsightApiDto[] = useMemo(
    () => (submissionData?.hasGeneratedInsights ? insightsData?.insights || [] : []),
    [insightsData, submissionData?.hasGeneratedInsights]
  );

  const handleChange = (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded((prev) => (isExpanded ? [...prev, panel] : prev.filter((p) => p !== panel)));
    setInsightsIndicated(false);
  };

  const onExpandAll = useHandler(() => {
    if (expanded.length === insights.length) {
      setExpanded([]);
    } else {
      setExpanded(insights.map((_, index) => `panel-${index}`));
      setInsightsIndicated(false);
    }
  });

  const isPanelExpanded = (panel: string) => expanded.includes(panel);

  const isMutating = useIsMutating({
    mutationKey: [EMutationConfigName.GENERATE_SUBMISSION_INSIGHTS, submissionId],
  });

  const isGenerating = Boolean(isMutating);

  const isDocumentsReady = useMemo(() => {
    const isAccordFilesUploaded = Boolean(submissionData?.fileOutAccordApplicationDtos?.length);
    const isEmrFilesUploaded = Boolean(submissionData?.fileOutEmrDtos?.length);
    const isLossRunsFilesUploaded = Boolean(submissionData?.fileOutLossRunsDtos?.length);

    if (!isAccordFilesUploaded && !isEmrFilesUploaded && !isLossRunsFilesUploaded) {
      return false;
    }

    const hasPendingAccordFiles = submissionData?.fileOutAccordApplicationDtos?.some((file) =>
      LOADING_ESubmissionFileStatus_VALUES.includes(file.status)
    );

    const hasPendingEmrFiles = submissionData?.fileOutEmrDtos?.some((file) =>
      LOADING_ESubmissionFileStatus_VALUES.includes(file.status)
    );

    const hasPendingLossRunsFiles = submissionData?.fileOutLossRunsDtos?.some((file) =>
      LOADING_ESubmissionFileStatus_VALUES.includes(file.status)
    );

    if (hasPendingAccordFiles || hasPendingEmrFiles || hasPendingLossRunsFiles) {
      return false;
    }

    return true;
  }, [
    submissionData?.fileOutAccordApplicationDtos,
    submissionData?.fileOutEmrDtos,
    submissionData?.fileOutLossRunsDtos,
  ]);

  const isGenerateDisabled = !isDocumentsReady || isFetchingInsights || isGenerating;

  const hasGeneratedInsights = Boolean(submissionData?.hasGeneratedInsights && insights.length);

  return (
    <Card className="flex min-h-[440px] w-full flex-col gap-2.5 rounded-lg bg-white p-6 shadow-card">
      <div className="flex items-center justify-between">
        <Typography className="py-4 text-xxxl">Underwriting Insights</Typography>
        {hasGeneratedInsights && (
          <Button
            disabled={isGenerateDisabled}
            onClick={() => generateInsights()}
            className={clsxm(
              'ml-auto flex h-full max-h-[40px] items-center gap-2 rounded-md border-[1.5px] border-secondary bg-transparent px-4 py-2 font-medium text-[#000000DE]'
            )}
          >
            <AutoModeSharpIcon />
            Regenerate Insights
          </Button>
        )}
      </div>
      {insights.length && !isGenerating && !isFetchingInsights ? (
        <Button
          endIcon={expanded.length === insights.length ? <RemoveIcon /> : <AddIcon />}
          className="ml-auto mt-2 text-[12px] text-[#000000DE]"
          onClick={onExpandAll}
          variant="text"
        >
          {expanded.length === insights.length ? 'Collapse All' : 'Expand All'}
        </Button>
      ) : null}
      {!insights.length && (isFetchingInsights || isSubmissionLoading) ? (
        <Skeleton variant="rounded" width="100%" height={300} />
      ) : (
        <>
          {!insights.length || isGenerating ? (
            <SubmissionGenerateInsights
              hasGeneratedInsights={hasGeneratedInsights}
              handleGenerateInsights={generateInsights}
              isGenerateDisabled={isGenerateDisabled}
              isDocumentsReady={isDocumentsReady}
              isGenerating={isGenerating}
            />
          ) : (
            <section className="flex flex-col gap-5">
              {insights.map((insight, index) => (
                <SubmissionInsightAccordion
                  isPanelExpanded={isPanelExpanded}
                  handleChange={handleChange}
                  key={insight.question}
                  insight={insight}
                  index={index}
                />
              ))}
            </section>
          )}
        </>
      )}
    </Card>
  );
};
