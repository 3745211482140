import { Typography } from '@mui/material';
import * as XLSX from 'xlsx';
import { useState } from 'react';

import { FileDropZoneWrapper } from '@app/components/file-drop-zone-wrapper/FileDropZoneWrapper';
import { ReactDVFC, useDialog } from '@app/services/dialog-manager';
import { Button } from '@app/components/buttons/button/Button';
import { useHandler } from '@app/hooks/useHandler.hook';
import { showErrorToast } from '@app/utils/toast.utils';
import { Dialog } from '@app/hoc';

interface Props {
  onCancel: () => void;
  onParse: (result: unknown[]) => void;
}

export const ConfirmSubmissionOverviewUploadDialog: ReactDVFC<Props> = ({ isOpen, onClose, onCancel, onParse }) => {
  const [loading, setLoading] = useState(false);

  const handleCancel = useHandler(() => {
    onCancel();
    onClose();
  });

  const handleChange = useHandler((file: File | null) => parseFile(file));

  const handleNotAllowed = useHandler(() => {
    showErrorToast(
      <div className="flex flex-col gap-1">
        <Typography className="font-bold">File Not Supported</Typography>
        <Typography>Payroll documents must be in Excel or CSV format.</Typography>
      </div>
    );
  });

  const parseFile = (file: File | null) => {
    if (!file) {
      return;
    }

    setLoading(true);

    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryStr = e.target?.result;
      if (!binaryStr) {
        setLoading(false);
        return;
      }

      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);

      setTimeout(() => {
        onParse(jsonData);
        setLoading(false);
      }, 300);
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <Dialog onClose={handleCancel} open={isOpen} hideHeader paperClassName="w-[550px]">
      <div className="flex flex-col gap-6 p-6">
        <Typography variant="h5" className="text-center font-semibold">
          Upload Estimated Annual Payroll
        </Typography>
        <div className="flex flex-col gap-4">
          <Typography className="text-sm text-center text-gray-600">
            Please upload the estimated annual payroll details using our specified format. You can
            <a
              className="mx-auto ml-1 w-fit text-blue-600 underline"
              download="EstimatedAnnualPayrollTemplate.xlsx"
              href="/EstimatedAnnualPayrollTemplate.xlsx"
            >
              download the template here
            </a>
            .
          </Typography>
          <p className="text-center">
            <span className="ml-1">
              <b>State</b>, <b>Class Code</b>, <b>Phraseology</b>, and <b>Amount</b> are required fields.{' '}
              <b>Zip Code</b> is required for Last Mile Accounts. <b>Full Time</b> and <b>Part Time</b> Employee Counts
              are optional.
            </span>
          </p>
        </div>
        <FileDropZoneWrapper
          accept="application/vnd.ms-excel,text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          text="Drag and drop an Excel sheet that follows the required format."
          onNotAllowed={handleNotAllowed}
          onChange={handleChange}
          className="col-span-1"
          loading={loading}
          allowedTypes={[
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel',
            'text/csv',
          ]}
        />
      </div>
      <div className="flex justify-end gap-6 pb-6 pr-6">
        <Button variant="outlined" color="primary" className="w-44" onClick={handleCancel}>
          Cancel
        </Button>
      </div>
    </Dialog>
  );
};

export const useConfirmSubmissionOverviewUploadDialog = () => useDialog(ConfirmSubmissionOverviewUploadDialog);
