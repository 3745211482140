import { SubmissionOverviewDetailsFormSchema, SubmissionOverviewProfileFormSchema } from '../schemas/submission.form';
import { useZodForm } from '@app/hooks/useZodForm';
import {
  submissionOverviewDetailsFormDefaultValues,
  submissionOverviewProfileFormDefaultValues,
} from '../form/submission-default-values';

export const useSubmissionOverviewProfileForm = () => {
  const form = useZodForm(SubmissionOverviewProfileFormSchema, {
    defaultValues: submissionOverviewProfileFormDefaultValues,
    mode: 'onChange',
    onSubmit: () => {},
  });

  return form;
};

export const useSubmissionOverviewDetailsForm = () => {
  const form = useZodForm(SubmissionOverviewDetailsFormSchema, {
    defaultValues: submissionOverviewDetailsFormDefaultValues,
    mode: 'onChange',
    onSubmit: () => {},
  });

  return form;
};
