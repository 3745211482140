import { FC } from 'react';

import { Button } from '@app/components/buttons/button/Button';
import { clsxm } from '@app/styles/clsxm';
import { ESubmissionLossSummaryTab, useSubmissionLossSummaryContext } from '../contexts/SubmissionLossSummaryContext';

interface Props {}

export const SubmissionLossSummarySwitcher: FC<Props> = () => {
  const { tab, setTab } = useSubmissionLossSummaryContext();

  return (
    <div className="grid w-[300px] grid-cols-2 items-center gap-4 rounded-md bg-gray-100 p-1">
      <Button
        disabled={tab === ESubmissionLossSummaryTab.BY_CARRIER}
        variant="contained"
        onClick={() => setTab(ESubmissionLossSummaryTab.BY_CARRIER)}
        className={clsxm(
          'rounded-md border-none p-1 text-center text-xs font-normal hover:shadow-none',
          tab === ESubmissionLossSummaryTab.BY_CARRIER
            ? 'cursor-auto bg-white shadow-tabs'
            : 'cursor-pointer bg-transparent text-[#00000061] hover:text-[#5b5b5b61]'
        )}
      >
        By Carrier
      </Button>
      <Button
        variant="contained"
        disabled={tab === ESubmissionLossSummaryTab.BY_PERIOD}
        onClick={() => setTab(ESubmissionLossSummaryTab.BY_PERIOD)}
        className={clsxm(
          'rounded-md border-none p-1 text-center text-xs font-normal hover:shadow-none',
          tab === ESubmissionLossSummaryTab.BY_PERIOD
            ? 'cursor-auto bg-white shadow-tabs'
            : 'cursor-pointer bg-transparent text-[#00000061] hover:text-[#5b5b5b61]'
        )}
      >
        By Period
      </Button>
    </div>
  );
};
