import { Button } from '@app/components/buttons/button/Button';
import { Dialog } from '@app/hoc';
import { useHandler } from '@app/hooks/useHandler.hook';
import { ReactDVFC, useDialog } from '@app/services/dialog-manager';
import { Typography } from '@mui/material';

interface Props {
  onConfirm: () => void;
  onCancel?: () => void;
  isLoading?: boolean;
}

export const ConfirmSubmissionAvgHourlyWageChangeDialog: ReactDVFC<Props> = ({
  isOpen,
  onClose,
  onCancel,
  onConfirm,
  isLoading,
}) => {
  const handleCancel = useHandler(() => {
    onCancel?.();
    onClose();
  });

  const handleSubmit = useHandler(() => {
    onConfirm();
    onClose();
  });

  return (
    <Dialog open={isOpen} hideHeader paperClassName="w-[450px]">
      <div className="flex flex-col gap-10 p-10">
        <div className="flex flex-col gap-5 text-center">
          <Typography className="font-semibold">Are you sure?</Typography>
          <Typography className="text-s text-gray-600">
            Changing the TRIR and DART values affects the SafeTier Rating. If you save this change to the hourly wage,
            your SafeTier results will be reset.
          </Typography>
        </div>
        <div className="flex justify-between">
          <Button variant="outlined" color="primary" className="w-44" onClick={handleCancel} disabled={isLoading}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleSubmit} className="w-44" loading={isLoading}>
            Save
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export const useConfirmSubmissionAvgHourlyWageChangeDialog = () => {
  return useDialog(ConfirmSubmissionAvgHourlyWageChangeDialog);
};
