import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FC } from 'react';

import { ReactHookFormNumberField } from '@app/components/fields/react-hook-form-fields/ReactHookFormNumberField';
import { SubmissionOverviewProfileFormSchemaType } from '../schemas/submission.form';
import { NaicsAutocomplete } from '@app/domain/sti/components/NaicsAutocomplete';
import { OptionItem, Select } from '@app/components/select';
import { Input } from '@app/components/fields/input/Input';
import { US_STATES } from '../const/submission.const';
import { SubmissionOverviewOutDto } from '@app/swagger-types';
import { clsxm } from '@app/styles/clsxm';
import { SubmissionOperationStatesAutocomplete } from './SubmissionOperationStatesAutocomplete';
import { useHandler } from '@app/hooks/useHandler.hook';
import { SubmissionUtils } from '../utils/submission.utils';
import { renderDollarAmount } from '@app/utils/currency/currency.utils';

interface Props {
  isUpdatingOverview: boolean;
  overviewData?: SubmissionOverviewOutDto;
}

export const SubmissionOverviewProfileEditForm: FC<Props> = ({ isUpdatingOverview, overviewData }) => {
  const { control, setValue } = useFormContext<SubmissionOverviewProfileFormSchemaType>();

  const operationStates = useWatch({ control, name: 'operationStates' });

  const uniqueFormattedGovClassOptions = overviewData?.payrollEntries?.filter(
    (item, index, arr) =>
      item.ncciUniqueId && index === arr.findIndex(({ ncciUniqueId }) => ncciUniqueId === item.ncciUniqueId)
  );

  const handleClearFilters = useHandler(() => {
    setValue('operationStates', '', { shouldDirty: true, shouldValidate: true, shouldTouch: true });
  });

  return (
    <div
      className={clsxm(
        'grid grid-cols-1 items-start gap-4',
        '@overview-profile:grid-cols-[65fr_35fr] @overview-profile:gap-8'
      )}
    >
      <div className="grid w-full grid-cols-1 gap-8">
        <div className={clsxm('grid w-full items-start gap-2', 'md:grid-cols-2 lg:grid-cols-4')}>
          <Controller
            control={control}
            name="domiciledState"
            render={({ field, fieldState: { error } }) => (
              <Select
                {...field}
                classes={{ root: 'h-full', select: 'px-4' }}
                onChange={(e) => field.onChange(e)}
                disabled={isUpdatingOverview}
                error={Boolean(error)}
                variant="outlined"
                fullWidth
                inputProps={{
                  label: 'Dom State',
                  error: Boolean(error),
                  disabled: isUpdatingOverview,
                }}
              >
                {Object.entries(US_STATES).map(([value]) => (
                  <OptionItem key={value} value={value}>
                    {value}
                  </OptionItem>
                ))}
              </Select>
            )}
          />
          <SubmissionOperationStatesAutocomplete
            onClearFilters={handleClearFilters}
            isDisabled={isUpdatingOverview}
            value={operationStates}
            name="operationStates"
            control={control}
          />
          <ReactHookFormNumberField
            control={control}
            thousandSeparator=","
            placeholder="N.NN"
            name="exModFactor"
            decimalScale={2}
            label="EMR"
          />
          <Controller
            render={({ field }) => <Input {...field} label="Haz Grade" disabled />}
            control={control}
            name="hazardGrade"
          />
        </div>
        <Controller
          control={control}
          name="governingClassCodeNcciId"
          render={({ field, fieldState: { error } }) => (
            <Select
              {...field}
              inputProps={{ label: 'Gov Class', error: Boolean(error), disabled: isUpdatingOverview }}
              MenuProps={{ classes: { paper: 'max-w-1 w-full' } }}
              onChange={(e) => field.onChange(e)}
              classes={{ select: 'p-[15.5px]' }}
              error={Boolean(error)}
              variant="outlined"
              displayEmpty
              fullWidth
              renderValue={(value) => {
                const governingClassCode = value
                  ? overviewData?.payrollEntries?.find((entry) => entry.ncciUniqueId === value)
                  : null;

                if (!governingClassCode) {
                  return <span className="text-[#a9a9a9]">Select Gov Class</span>;
                }

                return <>{SubmissionUtils.renderPayrollEntryLabel(governingClassCode)}</>;
              }}
            >
              {!uniqueFormattedGovClassOptions?.length && (
                <OptionItem value="" disabled selected={false}>
                  No Options
                </OptionItem>
              )}
              {uniqueFormattedGovClassOptions?.map((value) => (
                <OptionItem value={value.ncciUniqueId} key={value.ncciUniqueId}>
                  <span className="flex w-full justify-between gap-4">
                    <span className="truncate">{SubmissionUtils.renderPayrollEntryLabel(value)}</span>
                    <div className="min-w-[110px] text-right">{renderDollarAmount(value.amount || 0)}</div>
                  </span>
                </OptionItem>
              ))}
            </Select>
          )}
        />
        <NaicsAutocomplete name="naics" control={control} fullWidth disabled={isUpdatingOverview} />
      </div>
      <Controller
        control={control}
        name="operationalNotes"
        render={({ field }) => (
          <Input
            {...field}
            classes={{ root: 'size-full flex items-start justify-start' }}
            className="size-full min-h-[220px] @overview-profile:min-h-full"
            disabled={isUpdatingOverview}
            label="Description of Operations"
            variant="outlined"
            multiline
          />
        )}
      />
    </div>
  );
};
