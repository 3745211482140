import { createEnumParam, useQueryParam, withDefault } from 'use-query-params';

export enum ELossRatioTab {
  CHART = 'CHART',
  TABLE = 'TABLE',
}

const LossRationTabParam = createEnumParam([ELossRatioTab.CHART, ELossRatioTab.TABLE]);

export const useLossRationTabQueryParam = () =>
  useQueryParam('tab', withDefault(LossRationTabParam, ELossRatioTab.TABLE));
