export const FILE_SIZE = 1024 * 1024 * 100; // 100mb
export const FILE_LIMIT_LABEL = '100MB';

export const AVATAR_SIZE = 1024 * 1024 * 5; // 5mb
export const AVATAR_SIZE_LABEL = '5MB';

export const DOCUMENTS_FILE_FORMAT = [
  'application/pdf',
  'image/png',
  'image/jpeg',
  'image/jpg',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
];

export const OPENABLE_EXTENSION_TO_MIME: Record<string, string> = {
  png: 'image/png',
  jpeg: 'image/jpeg',
  jpg: 'image/jpg',
  pdf: 'application/pdf',
};

export const DOWNLOADABLE_EXTENSION_TO_MIME: Record<string, string> = {
  ...OPENABLE_EXTENSION_TO_MIME,
  csv: 'text/csv',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xls: 'application/vnd.ms-excel',
};
