import { divide } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { FormControlLabel, Popover, Switch, Typography } from '@mui/material';
import { NumericFormat } from 'react-number-format';

import { Button } from '@app/components/buttons/button/Button';
import { Input } from '@app/components/fields/input/Input';
import { TrirDartTable } from './TrirDartTable';
import { SubmissionLossSummaryOutDto } from '@app/swagger-types';
import { useEditSubmissionLossSummaryMutation } from '../api/submission.api.hooks';
import { useHandler } from '@app/hooks/useHandler.hook';
import { useSubmissionLossSummaryContext } from '../contexts/SubmissionLossSummaryContext';
import { parseAndFormatNumberString } from '@app/utils/number.utils';
import { useConfirmSubmissionAvgHourlyWageChangeDialog } from '../modals/ConfirmSubmissionAvgHourlyWageChangeDialog';
import { useSubmissionCalculatorFormsContext } from '../contexts/SubmissionCalculatorFormsContext';
import { clsxm } from '@app/styles/clsxm';

interface Props {
  open: boolean;
  submissionId: string;
  lossSummaryData: SubmissionLossSummaryOutDto | undefined;
  anchorEl: HTMLDivElement | null;
  onClose: () => void;
}

export const LossSummaryPopover: FC<Props> = ({ lossSummaryData, anchorEl, onClose, submissionId, open }) => {
  const { onResetForm } = useSubmissionLossSummaryContext();

  const originalUseEmployeeCount = Boolean(
    lossSummaryData?.fullTimeEmployeeCount || lossSummaryData?.partTimeEmployeeCount
  );
  const parsedAvgHourlyWage = parseAndFormatNumberString(lossSummaryData?.averageHourlyWage.toString() || '0', 2);
  const parsedFullTimeEmployeeCount = parseAndFormatNumberString(
    lossSummaryData?.fullTimeEmployeeCount?.toString() || '0'
  );
  const parsedPartTimeEmployeeCount = parseAndFormatNumberString(
    lossSummaryData?.partTimeEmployeeCount?.toString() || '0'
  );

  const [averageHourlyWage, setAverageHourlyWage] = useState(parsedAvgHourlyWage);
  const [fullTimeEmployeeCount, setFullTimeEmployeeCount] = useState(parsedFullTimeEmployeeCount);
  const [partTimeEmployeeCount, setPartTimeEmployeeCount] = useState(parsedPartTimeEmployeeCount);
  const [useEmployeeCount, setUseEmployeeCount] = useState(originalUseEmployeeCount);

  const confirmDialog = useConfirmSubmissionAvgHourlyWageChangeDialog();

  const { fullCalcResult } = useSubmissionCalculatorFormsContext();

  const { mutate: updateSubmission, isLoading: isUpdating } = useEditSubmissionLossSummaryMutation(submissionId, {
    onSuccess: async () => {
      onClose();

      // TODO: Remove setTimeout. It is currently used to reset the form with actual data. Without setTimeout, the form resets to outdated values.
      setTimeout(onResetForm, 200);
    },
  });

  const id = open ? 'loss-summary-popover' : undefined;

  const totalPayroll = lossSummaryData?.totalPayroll || 0;

  const fullTimeHours = Number(fullTimeEmployeeCount) * 40 * 52;
  const partTimeHours = Number(partTimeEmployeeCount) * 20 * 52;

  const employeeCountTotalHours = fullTimeHours + partTimeHours;
  const usualTotalHours = averageHourlyWage ? Number(divide(totalPayroll, Number(averageHourlyWage)).toFixed(2)) : 0;

  const totalHoursWorked = useEmployeeCount ? employeeCountTotalHours : usualTotalHours;

  useEffect(() => {
    setAverageHourlyWage(parsedAvgHourlyWage);
  }, [parsedAvgHourlyWage]);

  useEffect(() => {
    setUseEmployeeCount(originalUseEmployeeCount);
    setFullTimeEmployeeCount(parsedFullTimeEmployeeCount);
    setPartTimeEmployeeCount(parsedPartTimeEmployeeCount);
  }, [originalUseEmployeeCount, parsedFullTimeEmployeeCount, parsedPartTimeEmployeeCount]);

  const handleClose = useHandler(() => {
    onClose();
    setAverageHourlyWage(parsedAvgHourlyWage);
    setUseEmployeeCount(originalUseEmployeeCount);
    setFullTimeEmployeeCount(parsedFullTimeEmployeeCount);
    setPartTimeEmployeeCount(parsedPartTimeEmployeeCount);
  });

  const handleSave = useHandler(() => {
    const updateAvgHourlyWage = () =>
      updateSubmission(
        useEmployeeCount
          ? {
              averageHourlyWage: lossSummaryData?.averageHourlyWage || 0,
              fullTimeEmployeeCount: Number(fullTimeEmployeeCount),
              partTimeEmployeeCount: Number(partTimeEmployeeCount),
            }
          : {
              averageHourlyWage: Number(averageHourlyWage),
              fullTimeEmployeeCount: 0,
              partTimeEmployeeCount: 0,
            }
      );

    if (fullCalcResult) {
      confirmDialog.open({ onConfirm: updateAvgHourlyWage, onCancel: handleClose });
    } else {
      updateAvgHourlyWage();
    }
  });

  const isDisabled =
    originalUseEmployeeCount === useEmployeeCount &&
    (useEmployeeCount
      ? parsedFullTimeEmployeeCount === fullTimeEmployeeCount && parsedPartTimeEmployeeCount === partTimeEmployeeCount
      : parsedAvgHourlyWage === averageHourlyWage);

  return (
    <Popover
      id={id}
      open={open || isUpdating}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      className="z-50 mt-3"
    >
      <div className="flex w-[540px] flex-col gap-1.5 p-6">
        <div className="flex items-center justify-end gap-2">
          {!totalPayroll && (
            <Typography className="flex-1 justify-self-start text-xxs text-red-500">
              Add payroll to calculate TRIR and DART.
            </Typography>
          )}
          <FormControlLabel
            disabled={isUpdating}
            checked={useEmployeeCount}
            onChange={(_, checked) => setUseEmployeeCount(checked)}
            control={<Switch checked={useEmployeeCount} disabled={isUpdating} />}
            label={<Typography className="text-xs font-medium">Use Employee Count</Typography>}
            labelPlacement="start"
          />
        </div>
        <div className="border-b border-primary p-2">
          <div className="flex items-center gap-2">
            <NumericFormat
              customInput={Input}
              value={totalPayroll}
              label="Total Payroll"
              thousandSeparator
              prefix="$"
              readOnly
              classes={{ root: clsxm('border-0', !totalPayroll && 'text-red-500') }}
            />
            {useEmployeeCount ? (
              <>
                <NumericFormat
                  customInput={Input}
                  value={fullTimeEmployeeCount}
                  onValueChange={({ value }) => {
                    setFullTimeEmployeeCount(value);
                  }}
                  onBlur={(e) => {
                    setFullTimeEmployeeCount(parseAndFormatNumberString(e.target.value));
                  }}
                  label="Full Time"
                  thousandSeparator
                  decimalScale={0}
                  disabled={isUpdating}
                  allowNegative={false}
                  className="w-[120px]"
                />
                <NumericFormat
                  customInput={Input}
                  value={partTimeEmployeeCount}
                  onValueChange={({ value }) => {
                    setPartTimeEmployeeCount(value);
                  }}
                  onBlur={(e) => {
                    setPartTimeEmployeeCount(parseAndFormatNumberString(e.target.value));
                  }}
                  label="Part Time"
                  thousandSeparator
                  decimalScale={0}
                  disabled={isUpdating}
                  allowNegative={false}
                  className="w-[120px]"
                />
              </>
            ) : (
              <NumericFormat
                customInput={Input}
                value={averageHourlyWage}
                onValueChange={({ value }) => {
                  setAverageHourlyWage(value);
                }}
                onBlur={(e) => {
                  setAverageHourlyWage(parseAndFormatNumberString(e.target.value, 2));
                }}
                label="Avg. Hourly Wage"
                thousandSeparator
                prefix="$"
                decimalScale={2}
                disabled={isUpdating}
                allowNegative={false}
                className="w-40"
              />
            )}
            <NumericFormat
              customInput={Input}
              value={totalHoursWorked}
              label="Total Hours Worked"
              thousandSeparator
              readOnly
              classes={{ root: 'border-0' }}
            />
          </div>
        </div>

        {lossSummaryData ? (
          <TrirDartTable totalHoursWorked={totalHoursWorked} lossSummaryData={lossSummaryData} />
        ) : null}
        <div className="flex items-center justify-end gap-2 p-4">
          <Button variant="outlined" size="small" onClick={handleClose} disabled={isUpdating} className="rounded-md">
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            variant="contained"
            size="small"
            disabled={isUpdating || isDisabled}
            className="rounded-md border-secondary bg-secondary text-white disabled:opacity-70"
          >
            Save
          </Button>
        </div>
      </div>
    </Popover>
  );
};
