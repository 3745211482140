import { z } from 'zod';

import { StateInputSchema } from '@app/domain/sti/forms/calc.form';

export const SubmissionFormSchema = z.object({
  namedInsuredId: z.string().min(1, 'Required'),
  effectiveDate: z.string().min(1, 'Required'),
  assignee: z.string(),
});

export type SubmissionFormSchemaType = z.infer<typeof SubmissionFormSchema>;

export const NamedInsuredFormSchema = z.object({
  name: z.string().min(1, 'Required'),
  fein: z
    .string()
    .refine((value) => value === '' || /^[0-9]{2}-[0-9]{7}$/.test(value), 'Invalid format. Use NN-NNNNNNN instead.'),
});

export type NamedInsuredFormSchemaType = z.infer<typeof NamedInsuredFormSchema>;

export const SubmissionOverviewProfileFormSchema = z.object({
  domiciledState: z.string(),
  operationStates: z.string(),
  exModFactor: z.number(),
  hazardGrade: z.string(),
  governingClassCodeNcciId: z.string().nullable(),
  // TODO: maybe reuse schema
  naics: z.object({ id: z.number(), naics: z.string(), description: z.string() }).nullable(),
  operationalNotes: z.string(),
});

export type SubmissionOverviewProfileFormSchemaType = z.infer<typeof SubmissionOverviewProfileFormSchema>;

export const SubmissionOverviewDetailsFormSchema = z.object({
  agencyName: z.string(),
  producerName: z.string(),
  csRepresentativeName: z.string(),
  agencyPhone: z.string(),
  agencyEmail: z.string().email('Should be valid email'),
});

export type SubmissionOverviewDetailsFormSchemaType = z.infer<typeof SubmissionOverviewDetailsFormSchema>;

export const SubmissionOverviewPayrollEntriesSchema = z.object({
  stateInputs: z.array(StateInputSchema).min(1, 'Required'),
});

export type SubmissionOverviewPayrollEntriesSchemaType = z.infer<typeof SubmissionOverviewPayrollEntriesSchema>;

export const SubmissionOverviewPayrollEntryParsedSchema = z.object({
  'Class Code': z.number(),
  ' Estimated Payroll ': z.number(),
  Phraseology: z.string(),
  State: z.string(),
});

export type SubmissionOverviewPayrollEntryParsedSchemaType = z.infer<typeof SubmissionOverviewPayrollEntryParsedSchema>;

export const SubmissionOverviewPayrollEntriesParseSchema = z.array(SubmissionOverviewPayrollEntryParsedSchema);
