import { ERole } from './auth-types';
import { useStore } from './store/useStore.hook';

type Page =
  | 'CLAIMS_ASSISTANT'
  | 'DATA_EXPLORATION_MODULE'
  | 'ADMIN_MODULE'
  | 'SUBMISSION_NOTES'
  | 'STI_MODULE'
  | 'STI_VERSIONS_SWITCHER'
  | 'SUBMISSIONS_MODULE'
  | 'AWS_ALTERNATIVE_RESULTS'
  | 'SUBMISSION_INSIGHTS_TAB';

type RolesPermission = Record<Page, ERole[]>;

export const ROLE_ACCESS: RolesPermission = {
  // modules
  ADMIN_MODULE: [ERole.ALL_ADMINISTRATOR, ERole.INSURATE_USER_ADMIN, ERole.USISA_USER_ADMIN],
  STI_MODULE: [ERole.ALL_ADMINISTRATOR, ERole.STIAPP_FULL_ACCESS, ERole.STI_VERSIONS_MENU],
  SUBMISSIONS_MODULE: [ERole.ALL_ADMINISTRATOR, ERole.SUBMISSIONS],
  DATA_EXPLORATION_MODULE: [ERole.ALL_ADMINISTRATOR, ERole.NL_DB_QUERIES],
  // pages
  SUBMISSION_INSIGHTS_TAB: [ERole.ALL_ADMINISTRATOR, ERole.SUBMISSION_INSIGHTS],
  // other
  CLAIMS_ASSISTANT: [ERole.ALL_ADMINISTRATOR, ERole.SUBMISSION_INSIGHTS],
  SUBMISSION_NOTES: [ERole.ALL_ADMINISTRATOR, ERole.UNDERWRITER_NOTES],
  STI_VERSIONS_SWITCHER: [ERole.ALL_ADMINISTRATOR, ERole.STI_VERSIONS_MENU],
  AWS_ALTERNATIVE_RESULTS: [ERole.ALL_ADMINISTRATOR, ERole.ALTERNATIVE_RESULTS],
};

export const useHasAccess = (page: Page) => {
  const { currentUser } = useStore(({ currentUser }) => ({
    currentUser,
  }));

  return Boolean(currentUser?.groups.some((role) => ROLE_ACCESS[page].includes(role)));
};
