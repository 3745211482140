import { customAlphabet } from 'nanoid';

export const convertNumberToString = (num: number) => {
  return num.toString().padStart(2, '0');
};

export const parseAndFormatNumberString = (num: string, decimal = 0): string => {
  if (num) {
    const value = num.replace(/\$/g, '').replace(/,/g, ''); // Remove $ and , characters
    const parsedValue = parseFloat(value);

    if (!isNaN(parsedValue)) {
      return parsedValue.toFixed(decimal);
    }
  }

  // Return the original string if it's not a valid number
  return num;
};

export const generateNumericId = (length: number): number => {
  const customNumericAlphabet = customAlphabet('0123456789', length);

  return parseInt(customNumericAlphabet(), 10);
};
