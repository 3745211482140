import { lazy } from 'react';
import { Routes } from '@app/constants/routes';

import { SubmissionModule } from './SubmissionModule';
import { MyRouteObject } from '@app/models/router';
import { inferIdentity } from '@app/utils/type.utils';
import { TypedNavigate } from '@app/router';
import { HAS_ACCESS_TO_UNDERWRITING } from '@app/environment/typed-env';
import { ROLE_ACCESS } from '@app/auth-roles-access';

const SubmissionsPage = lazy(() => import('./pages/SubmissionsPage'));
const CreateSubmissionPage = lazy(() => import('./pages/CreateSubmissionPage'));
const CreateNamedInsuredPage = lazy(() => import('./pages/CreateNamedInsuredPage'));
const SubmissionDataPage = lazy(() => import('./pages/SubmissionDataPage'));

export const SubmissionRoutes = inferIdentity<MyRouteObject>()({
  path: Routes.submissions.index,
  element: <SubmissionModule />,
  disabled: !HAS_ACCESS_TO_UNDERWRITING,
  role: ROLE_ACCESS.SUBMISSIONS_MODULE,
  children: [
    { index: true, element: <SubmissionsPage /> },
    { path: Routes.submissions.createSubmission, element: <CreateSubmissionPage /> },
    { path: Routes.submissions.submissionData, element: <SubmissionDataPage /> },
    { path: Routes.submissions.createNamedInsured, element: <CreateNamedInsuredPage /> },
    { index: true, path: '*', element: <TypedNavigate to={Routes.submissions.index} /> },
  ],
});
