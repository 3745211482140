import { FC } from 'react';
import { UIStyledSection } from '../components/UIStyledSection';
import { Typography } from '@mui/material';
import { InputExamples } from '../components/InputExamples';
import { AutocompleteExamples } from '../components/AutocompleteExamples';
import { MonthPickerRange } from '@app/components/month-picker-range/MonthPickerRange';
import { DatePickerRange } from '@app/components/date-picker-range/DatePickerRange';
import { DatePicker } from '@app/components/date-picker/DatePicker';

export const UISectionFields: FC = () => {
  return (
    <>
      <UIStyledSection title="Inputs">
        <div className="w-96">
          <InputExamples />
        </div>
      </UIStyledSection>
      <UIStyledSection title="Autocompletes">
        <div className="w-96">
          <AutocompleteExamples />
        </div>
      </UIStyledSection>
      <UIStyledSection title="Date Pickers">
        <div className="grid grid-cols-[320px,320px,280px] gap-2">
          <div className="flex flex-col gap-2">
            <Typography>Month Picker Range:</Typography>
            <MonthPickerRange />
          </div>
          <div className="flex flex-col gap-2">
            <Typography>Date Picker Range:</Typography>
            <DatePickerRange />
          </div>
          <div className="flex flex-col gap-2">
            <Typography>Date Picker:</Typography>
            <DatePicker />
          </div>
        </div>
      </UIStyledSection>
    </>
  );
};
