import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { FC, MouseEvent, useEffect, useState } from 'react';
import { format, parseISO, subDays } from 'date-fns';
import { Popover } from '@mui/material';

import { DatePicker } from '@app/components/date-picker/DatePicker';
import { convertISOToLocalTimeZone } from '@app/utils/date.utils';
import { useHandler } from '@app/hooks/useHandler.hook';
import { FORMAT } from '@app/constants/formats';
import { Button } from '@app/components/buttons/button/Button';

interface Props {
  effectiveDate?: string;
  onChange(date: Date): void;
  disabled: boolean;
  value?: string;
}

export const SubmissionOverviewDatePicker: FC<Props> = ({ effectiveDate, onChange, disabled, value }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [selectedDate, setSelectedDate] = useState<Date | null>(null); // Default to 30 days before the proposed effective date;

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDateChange = (date: Date) => {
    onChange(date);
    setSelectedDate(date);
    handleClose();
  };

  const handleClose = useHandler(() => setAnchorEl(null));

  const open = Boolean(anchorEl);
  const id = open ? 'date-needed-by-popover' : undefined;

  useEffect(() => {
    if (value) {
      setSelectedDate(parseISO(value));
    } else if (effectiveDate) {
      setSelectedDate(subDays(parseISO(effectiveDate), 30));
    }
  }, [effectiveDate, value]);

  return (
    <div className="group flex items-center gap-2">
      <Button
        endIcon={<CalendarMonthIcon className="invisible text-gray-600 group-hover:visible" fontSize="small" />}
        className="break-words text-m font-normal text-zinc-900"
        onClick={handleClick}
        variant="text"
      >
        {selectedDate ? format(convertISOToLocalTimeZone(selectedDate.toISOString()), FORMAT.DATE_YEAR) : '-'}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <DatePicker disabled={disabled} selected={selectedDate} onChange={handleDateChange} />
      </Popover>
    </div>
  );
};
